import * as React from "react"

export const ContentLoading: React.FC = () => {
  return(
    <div className="content-loading">
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}
