export interface User {
  id: number
  email: string
  role: UserRole
  createdAt: string
  updatedAt: string
}

export enum UserRole {
  Staff = "staff",
  Admin = "admin"
}

export interface UserInput {
  email: string,
  role: UserRole,
  password?: string
  passwordConfirmation?: string
}

export interface UserErrors {
  email?: string[],
  role?: string[],
  password?: string[],
  passwordConfirmation?: string[]
}
