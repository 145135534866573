import * as React from "react";
import { useEffect, useState } from "react";

interface ModalProps {
  isOpen: boolean
  label: string
  acceptText?: string
  declineText?: string
  onDeclined?: () => void
  onAccepted?: () => void
  onOpen?: () => void
  onClose?: () => void
  onVisibilityChanged?: () => void,
  setIsOpen: (isOpen: boolean) => void
}

export const Modal: React.FC<ModalProps> = ({ setIsOpen, isOpen, label, acceptText, declineText, onDeclined, onAccepted, onClose, onOpen, onVisibilityChanged}: ModalProps) => {

  useEffect(() => {
    onVisibilityChanged && onVisibilityChanged()

  }, [isOpen])


  return(
    <div className={`${isOpen ? "flex" : "hidden"} fixed top-0 bottom-0 left-0 right-0 justify-center items-center z-50 transition-all`}>
      <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-10 bg-indigo-300 opacity-40"
        onClick={() => setIsOpen(false)}
      />
      <div className="px-10 py-5 bg-white rounded-md shadow z-20 backdrop-blur-md max-w-sm">
        <p className="text-lg text-gray-600 text-center">{label}</p>
        <div className="justify-around flex mt-10">
          <button
            className="bg-indigo-500 text-white py-1 px-3 rounded hover:shadow-lg focus:shadow-lg"
            onClick={() =>{
              onAccepted && onAccepted()
              setIsOpen(false)
            }}
          >
            { acceptText || "Continue" }
          </button>
          <button className="bg-gray-500 text-white py-1 px-3 rounded hover:shadow-lg focus:shadow-lg"
            onClick={() => {
              onDeclined && onDeclined()
              setIsOpen(false)
            }}
          >
            { declineText || "Cancel" }
          </button>
        </div>
      </div>
    </div>
  )
}

export const Info: React.FC<ModalProps> = ({ setIsOpen, isOpen, label, acceptText, onAccepted, onClose, onOpen, onVisibilityChanged }: ModalProps) => {

  useEffect(() => {
    onVisibilityChanged && onVisibilityChanged()

  }, [isOpen])


  return (
    <div className={`${isOpen ? "flex" : "hidden"} fixed top-0 bottom-0 left-0 right-0 justify-center items-center z-50 transition-all`}>
      <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-10 bg-indigo-300 opacity-40"
        onClick={() => setIsOpen(false)}
      />
      <div className="px-10 py-5 bg-white rounded-md shadow z-20 backdrop-blur-md max-w-sm">
        <p className="text-lg text-gray-600 text-center">{label}</p>
        <div className="justify-center flex mt-10">
          <button
            className="bg-indigo-500 text-white py-1 px-3 rounded hover:shadow-lg focus:shadow-lg"
            onClick={() => {
              onAccepted && onAccepted()
              setIsOpen(false)
            }}
          >
            {acceptText || "Continue"}
          </button>
        </div>
      </div>
    </div>
  )
}

