import * as React from "react";
import { createContext, useEffect } from "react";
import { User } from "../domain_data/user";
import API from "../utils/api";
import { DefaultProp } from "../utils/DefaultProp";
import { useLocation } from "react-router-dom"
import { Setting } from "../domain_data/setting";
import { IDgenerator } from "../utils";


interface mainContextProps {
  user: User;
  activeMenu: Menu;
  setting: Setting
  onSettingChanged: () => void
}
export const MainContext = createContext<mainContextProps>({ user: null, activeMenu: null, setting: null, onSettingChanged: () => {/**/ } });

export const MainContextProvider: React.FC<DefaultProp> = ({ children }: DefaultProp) => {
  const [user, setUser] = React.useState<User>(null);
  const [activeMenu, setActiveMenu] = React.useState<Menu>(null);
  const [setting, setSetting] = React.useState<Setting>(null)
  const { pathname } = useLocation();
  const [randomState, setRanfomState] = React.useState<string>("")

  useEffect(() => {
    setActiveMenu(pathMatcher(pathname))
  }, [pathname])


  useEffect(() => {
    API.get("/api/me").then(({ data }) => {
      setUser(data)
    }).catch(() => {
      location.href = "/users/sign_in"
    })
  }, [])

  useEffect(() => {
    API.get("/api/settings").then(({ data }) => {
      setSetting(data)
    })
  }, [randomState])

  return (
    <MainContext.Provider value={{ user, activeMenu, setting, onSettingChanged: () => setRanfomState(IDgenerator()) }}>
      {children}
    </MainContext.Provider>
  )
}

export enum Menu {
  Home,
  Supplier,
  RawMaterial,
  MaterialCategories,
  Product,
  ProductCategory,
  Currencies,
  Taxes,
  SupplierPO,
  BaseMaterial,
  JobOrder,
  User,
  Office,
  ProductionReport,
  MaterialBalanceReport,
  SupplierReport,
  SupplierJobOrderReport,
  JobOrderReport,
  PurchaseOrderReport
}


const pathMatcher = (path: string): Menu => {
  if (path == "/") return Menu.Home
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, root] = path.split("/")

  switch (root) {
    case "suppliers":
      return Menu.Supplier
    case "materials":
      return Menu.RawMaterial
    case "material_categories":
      return Menu.MaterialCategories
    case "products":
      return Menu.Product
    case "product_categories":
      return Menu.ProductCategory
    case "currencies":
      return Menu.Currencies
    case "taxes":
      return Menu.Taxes
    case "supplier_purchase_orders":
      return Menu.SupplierPO
    case "base_materials":
      return Menu.BaseMaterial
    case "job_orders":
      return Menu.JobOrder
    case "job_order_groups":
      return Menu.JobOrder
    case "users":
      return Menu.User
    case "offices":
      return Menu.Office;
    case "production_reports":
      return Menu.ProductionReport;
    case "material_balance":
      return Menu.MaterialBalanceReport
    case "supplier_reports":
      return Menu.SupplierReport
    case "job_order_reports":
      return Menu.JobOrderReport
    case "purchase_order_reports":
      return Menu.PurchaseOrderReport
    case "supplier_job_order_reports":
      return Menu.SupplierJobOrderReport;
    default:
      return Menu.Home
  }
}
