import * as React from "react";
import { MainLayout } from "../../layouts/Main";
import { SupplierMonthlyReport, SupplierRangeReportByQuantity } from "../../domain_data/supplier";
import { ChartData } from "chart.js";
import { RandomColors } from "../../domain_data/random_color";
import { Pagination } from "../../domain_data/pagination";
import * as dayjs from "dayjs";
import { AxiosResponse } from "axios";
import { API } from "../../utils";
import iziToast from "izitoast";
import { PaginationView } from "../../components/Pagination";
import { ContentLoading } from "../../components/ContentLoading";
import { RouteComponentProps } from "react-router-dom"
import DatePicker from "react-datepicker"
import { Line } from "react-chartjs-2";

type SupplierReportDetailParams = RouteComponentProps<{ string, id: string }>


export const SupplierJobOrderReportDetail: React.FC = ({ match: { params: { id } } }: SupplierReportDetailParams) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [startDate, setStartDate] = React.useState<Date>(new Date(dayjs().startOf('year').toISOString()))
  const [endDate, setEndDate] = React.useState<Date>(new Date())
  const [isInitialLoad, setIsInitialLoad] = React.useState<boolean>(true);


  const fetchReports = (callback?: { (): void }) =>
    API.get(`/api/reports/${id}/supplier_job_order_count_by_monthly?start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format("YYYY-MM-DD")}&page=${page}&paginate=true`)
      .then(({ data: { entries } }: AxiosResponse) => {
        updateChartData(entries)
      }).catch(() => {
        iziToast.error({
          title: "Server Error",
          message: "There is a problem processing your request"
        })
      }).finally(() => {
        setIsLoading(false)
        callback && callback()
      })

  React.useEffect(() => {
    setIsLoading(true)
    fetchReports(() => {
      setIsInitialLoad(false);
    })
  }, [])

  React.useEffect(() => {
    if (isInitialLoad) return;
    fetchReports()
  }, [page])

  React.useEffect(() => {
    if (isInitialLoad) return;
    setPage(1)
    fetchReports()
  }, [startDate, endDate])

  const [chartData, setChartData] = React.useState<ChartData>({ labels: [], datasets: [] });

  const updateChartData = (entries: SupplierMonthlyReport[]) => {
    setChartData({
      labels: entries.map(i => dayjs(i.startDate).format("MMM YYYY")),
      datasets: [
        {
          label: 'Job Orders',
          data: entries.map(i => i.total),
          backgroundColor: RandomColors,
          borderColor: "black",
          borderWidth: 2
        }
      ]
    })
  }

  return (
    <MainLayout>
      <div className="mt-5 grid grid-cols-12 gap-6 mb-10">
        <div className="grid grid-cols-12 gap-6 col-span-12">
          <div className="col-span-6 grid grid-cols-2 gap-6">
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 ">
                Start Date
              </label>
              <DatePicker showMonthYearPicker
                dateFormat="MMM yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
                className="px-3 py-3 border focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              />
            </div>

            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 ">
                End Date
              </label>
              <DatePicker
                showMonthYearPicker
                dateFormat="MMM yyyy"
                selected={endDate}
                onChange={date => setEndDate(date)}
                className="px-3 py-3 border focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              />
            </div>
          </div>
        </div>

        {isLoading ? <ContentLoading /> : (
          <div className="col-span-12">
            <Line data={chartData} options={{
              plugins: {
                title: {
                  display: true,
                  text: `Job Order between ${dayjs(startDate).format("MMM YYYY")} and ${dayjs(endDate).format("MMM YYYY")}`
                }
              }
            }} />
          </div>
        )}
      </div>
    </MainLayout>
  )
}