import { Material } from "./material"

export interface SupplierPurchaseOrderItem {
  id: number;
  supplierPurchaseOrderId: number
  quantity: number
  materialId: number
  pricePerUnit: number
  unit: string
  createdAt: number
  updatedAt: number
}


export interface SupplierPurchaseOrderItemInput {
  id?: number
  quantity: string
  materialId?: number
  _destroy: boolean
}

export const SupplierPurchaseOrderInputFromItem = ({id, quantity, materialId}: SupplierPurchaseOrderItem): SupplierPurchaseOrderItemInput => {
  return {
    id,
    quantity: quantity?.toString(),
    materialId,
    _destroy: false
  }
}

export interface RemainingPurchaseOrder {
  material: Material,
  totalReceived: number,
  balance: number,
}
