import * as React from "react"
import { useState } from "react";
import { SupplierPOArrivalErrors, SupplierPOArrivalInput } from "../../domain_data/supplier_po_arrival";
import { MainLayout } from "../../layouts/Main"
import { IDgenerator } from "../../utils";
import { useHistory, RouteComponentProps } from "react-router-dom"
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { POArrivalForm } from "./Form";
import API from "../../utils/api";
import { AxiosError } from "axios";

type NewArrivalProps = RouteComponentProps<{ purchaseOrderId: string}>;


export const NewPOArrival: React.FC<NewArrivalProps> = ({ match: { params: { purchaseOrderId } } }: NewArrivalProps) => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<SupplierPOArrivalErrors>({});
  const history = useHistory();

  const onSubmit = (data: SupplierPOArrivalInput): void => {
    setLoading(true)
    API.post(`/api/supplier_purchase_orders/${purchaseOrderId}/supplier_po_arrivals`, { supplier_po_arrival: data }).then(() => {
      history.push(`/supplier_purchase_orders/${purchaseOrderId}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path={`/supplier_purchase_orders/${purchaseOrderId}`} />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <POArrivalForm data={{
            dataIn: new Date(),
            note: "",
            quantity: "",
            weight: "0",
            supplierPoItemId: null
          }}
          onSubmit={onSubmit}
          errors={errors}
          id={formID}
          purchaseOrderId={purchaseOrderId}
        />
      </div>
    </MainLayout>
  )
}
