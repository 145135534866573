import { AxiosError, AxiosResponse } from "axios"
import * as React from "react"
import { useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { MainLayout } from "../../layouts/Main"
import { IDgenerator } from "../../utils"
import API from "../../utils/api"
import { useHistory } from "react-router-dom"
import { ProductCategoryErrors, ProductCategoryInput } from "../../domain_data/product_category"
import { ProductCategoryForm } from "./Form"

export const NewProductCategory: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductCategoryErrors>({});
  const history = useHistory();

  const onSubmit = (data: ProductCategoryInput) => {
    setLoading(true);
    API.post("/api/product_categories", { product_category: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/product_categories/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>

      <div className="min-h-screen">
        <ProductCategoryForm data={{ name: "" }} id={formID} errors={errors} onSubmit={onSubmit} />
      </div>
    </MainLayout>
  )
}
