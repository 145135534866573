import { BaseCalculation } from "./base";

class CastingCalculation extends BaseCalculation {
  get finalPrice() : number {
    return Number(((1 + this.taxAmount / 100) * this.realPrice).toFixed(2))
  }

  get realPrice() : number {
    const pricePerWeight = this.weight * this.baseMaterialPrice
    const laborCost = this.weight * this.labor

    return Number((pricePerWeight + laborCost).toFixed(2))
  }
}


export default CastingCalculation;
