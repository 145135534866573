import { BaseCalculation } from "./base";

class StoneCalculation extends BaseCalculation {
  get finalPrice() : number {
    return Number(((1 + this.taxAmount / 100) * this.realPrice).toFixed(2))
  }

  get realPrice() : number {
    return Number((this.priceUnit).toFixed(2))
  }
}

export default StoneCalculation
