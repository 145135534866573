import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory, Link } from "react-router-dom"
import { BaseMaterial } from "../../domain_data/base_material"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { FormBlock } from "../../components/forms/FormBlock"
import { TextInputShow } from "../../components/inputs/TextInputShow"
import { API, moneyFormatter } from "../../utils"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { Modal } from "../../components/Modal"
import iziToast from "izitoast"
import { useContext, useEffect } from "react"
import { ItemNotFound } from "../ItemNotFound"
import { MainContext } from "../../context/MainContext"

export const BaseMaterialShow: React.FC<DefaultParamProps> = ({ match: { params: { id } } }: DefaultParamProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDeleting, setIsdeleting] = React.useState<boolean>(false);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [material, setMaterial] = React.useState<BaseMaterial>()

  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/base_materials/${id}`).then(({ data }) => {
      setMaterial(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const handleDelete = () => {
    setIsdeleting(true)
    API.delete(`/api/base_materials/${id}`).then(() => {
      history.push("/base_materials")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this base material" })
    }).finally(() => {
      setIsdeleting(false)
    })
  }

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])


  if (notFound) return <ItemNotFound />

  return (
    <MainLayout>
      {loading || !material || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/base_materials" />
            <Link className="dark-button-with-text" to={`/base_materials/${id}/edit`}>Edit</Link>
          </div>
          <div>
            <FormBlock heading="Material" description="This is the general information of the material.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Material Name" value={material.name} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Price" value={moneyFormatter(material.price, setting?.currency?.code)} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Unit" value={material.unit} />
                </div>
              </div>
            </FormBlock>
            <FormUnderline />
            <Modal
              onDeclined={() => setModalOpen(false)}
              onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this material?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />
            <div className="inline-block mr-3">
              <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
                <div className="w-5 inline-block">
                  <i className="uil uil-trash text-white"></i>
                </div>
                <p className="text-white font-bold ml-2">Delete</p>
              </button>
            </div>
            <Link className="dark-button-with-text" to={`/materials/${id}/adjustments`}>See all adjustments</Link>
          </div>
        </div>
      }
    </MainLayout>
  )
}
