import * as React from "react";
import { API, IDgenerator } from "../../utils";
import { DirectUpload } from "@rails/activestorage"
import iziToast from "izitoast";
import { FileBlob } from "../../domain_data/image_attachment";

interface ImageInputProps {
  onUploaded? : (file: FileBlob) => void
  onDelete?: (file: FileBlob) => void
  file?: FileBlob
}

export const ImageInput: React.FC<ImageInputProps> = ({ onUploaded, onDelete, file }: ImageInputProps ) => {
  const [fileUrl, setFileUrl] = React.useState<string>(file?.url)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [loaded, setLoaded] = React.useState<number>(0)
  const [total, setTotal] = React.useState<number>(100)
  const [deleting, setDeleting] = React.useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = React.useState<FileBlob>(file)
  const input = React.useRef(null);


  const id = IDgenerator();
  const onFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setLoading(true)
      setFileUrl(URL.createObjectURL(file))
      const uploader = new DirectUpload(file, "/rails/active_storage/direct_uploads", {
        directUploadWillStoreFileWithXHR(request) {
          request.upload.addEventListener("progress", event => {
            setTotal(event.total)
            setLoaded(event.loaded)
          })
        }
      })

      uploader.create((error, blob) => {
        if (error) {
          iziToast.error({ title: "Error", message: "Failed to upload image" })
          setLoading(false)
        } else {
          setLoading(false)
          setUploadedFile(blob)
          onUploaded && onUploaded(blob)
        }
      })
    }
  }

  const deleteImage = (event) => {
    setDeleting(true)
    event.preventDefault()
    event.stopPropagation()

    API.delete(`/api/blobs/${uploadedFile.signed_id}`).then(() => {
      onDelete && onDelete(uploadedFile)
      input.current.value = null
      setFileUrl(null)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to delete image" })
    }).finally(() => {
      setDeleting(false)
    })
  }

  return(
    <div>
      <label htmlFor={id} className="cursor-pointer relative">
        { fileUrl ?
          <div className="rounded-md overflow-hidden relative">
            <div className=" h-28">
              <img src={fileUrl} className="w-full h-auto"/>
            </div>
            <div className="absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center rounded-md cursor-pointer">
              {loading &&
                <div className="flex bg-white w-full h-3 items-center rounded-md px-1 transition-all delay-150 duration-300">
                  <div className="bg-indigo-500 h-2 rounded-md" style={{ width: `${(loaded / total) * 100}%` }} />
                </div>
              }
              {!deleting && !loading && <button className="absolute transition-all duration-300 right-0 top-0" onClick={deleteImage}>
                <i className="uil uil-times text-white text-3xl bg-red-500" />
              </button>}
            </div>
          </div>
          :
          <>
            <i className="uil uil-image-upload text-3xl text-gray-700"></i>
            <p className="inline ml-3">Choose Image</p>
          </>
        }


      </label>

      <input ref={input} type="file" accept="image/*" id={id} className="hidden" onChange={onFileChange}/>
    </div>
  )
}
