import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { Checkbox } from "../../components/inputs/Checkbox"
import { NumberInput } from "../../components/inputs/NumberInput"
import { SingleSelect } from "../../components/inputs/SingleSelect"
import { TextInput } from "../../components/inputs/TextInput"
import { CurrencyErrors, CurrencyInput } from "../../domain_data/currency"
import { DefaultFormProps, normalizeNumber } from "../../utils"

export const CurrencyForm: React.FC<DefaultFormProps<CurrencyInput, CurrencyErrors>> = ({ data, errors, id, onSubmit }: DefaultFormProps<CurrencyInput, CurrencyErrors>) => {
  const [code, setCode] = useState<string>(data.code)
  const [symbol, setSymbol] = useState<string>(data.symbol)
  const [rate, setRate] = useState<string>(data.rate)
  const [decimalPlace, setDecimalPlace] = useState<string>(data.decimalPlace)
  const [isActive, setActive] = useState<boolean>(data.isActive)
  const [isDefault, setIsDefault] = useState<boolean>(data.isDefault)
  const [isSecondary, setIsSecondary] = useState<boolean>(data.isSecondary)

  const defaultdecimalPlace = () => {
    const data = DECIMAL_PLACE.find(i => i.value == decimalPlace)

    if (!data) {
      return DECIMAL_PLACE[1]
    }

    return data
  }

  return(
    <form id={id} onSubmit={(e) => {
      e.preventDefault()
      onSubmit && onSubmit({
        rate: normalizeNumber(rate).toString(),
        isActive,
        isDefault,
        code,
        decimalPlace,
        symbol,
        isSecondary
      })
    }}>
      <FormBlock heading="Currency Information" description="This is the general currency information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Code"
              value={code}
              setValue={setCode}
              error={errors?.code}
              name="code"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Symbol"
              value={symbol}
              setValue={setSymbol}
              error={errors?.symbol}
              name="symbol"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <NumberInput
              label="Rate"
              value={rate}
              setValue={setRate}
              error={errors?.rate}
              name="rate"
            />
          </div>

          <div className="col-span-6 sm:col-span-3 hidden">
            <SingleSelect
              label="Decimal Place"
              defaultItem={defaultdecimalPlace()}
              setValue={(item) => setDecimalPlace(item)}
              options={DECIMAL_PLACE}
              error={errors?.decimalPlace}
              name="decimalPlace"
            />
          </div>

          <div className="col-span-6">
            <Checkbox
              value={isActive}
              setValue={setActive}
              label="Active"
              description="if set to active, this currency will be shown in the form dropdown" />
          </div>

          <div className="col-span-6">
            <Checkbox
              value={isDefault}
              setValue={setIsDefault}
              label="Set as default"
              description="if set as default, the price of the material will be recorded as this currency" />
          </div>

          <div className="col-span-6">
            <Checkbox
              value={isSecondary}
              setValue={setIsSecondary}
              label="Set as secondary"
              description="if set as secondary, this will be shown as alternative currency in the pdf and excel exports" />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}


const DECIMAL_PLACE = [
  { label: "1 e.g. 123,0", value: "1", selected: false },
  { label: "2 e.g. 123,01", value: "2", selected: false },
  { label: "3 e.g. 123,012", value: "3", selected: false },
  { label: "4 e.g. 123,0123", value: "4", selected: false },
  { label: "5 e.g. 123,01234", value: "5", selected: false },
  { label: "6 e.g. 123,012345", value: "6", selected: false },
  { label: "7 e.g. 123,0123456", value: "7", selected: false },
]
