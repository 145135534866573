import { AxiosError, AxiosResponse } from "axios"
import * as React from "react"
import { useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { MaterialCategoryErrors, MaterialCategoryInput } from "../../domain_data/material_category"
import { MainLayout } from "../../layouts/Main"
import { IDgenerator } from "../../utils"
import API from "../../utils/api"
import { MaterialCategoryForm } from "./Form"
import { useHistory } from "react-router-dom"

export const NewMaterialCategory: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<MaterialCategoryErrors>({});
  const history = useHistory();

  const onSubmit = (data: MaterialCategoryInput) => {
    setLoading(true);
    API.post("/api/material_categories", { material_category: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/material_categories/${id}`)
    }).catch(({response: {data}}: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>

      <div className="min-h-screen">
        <MaterialCategoryForm data={{ name: "" }} id={formID} errors={errors} onSubmit={onSubmit}/>
      </div>
    </MainLayout>
  )
}
