import iziToast from "izitoast"
import * as React from "react"
import { useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { ExcelInput } from "../../components/inputs/ExcelInput"
import { Info } from "../../components/Modal"
import { MainLayout } from "../../layouts/Main"
import { API } from "../../utils"
import { useHistory } from "react-router-dom"


export const ImportMaterial: React.FC = () => {
  const [downloadModal, setDownloadModal] = useState<boolean>(false)
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const [file, setFile] = useState<File>(null)
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = () => {
    setLoading(true)

    const form = new FormData();
    form.append("material[file]", file);

    API.post("/utilities/materials/import", form).then(() => {
      setSuccessModal(true)
    }).catch(({ response: { data: { message, record }} }) => {
      iziToast.error({ title: "Error", message: "Failed to process the request please make sure the file is valid" })
      iziToast.error({ title: "Error", message})
      iziToast.error({ title: "Error", message: `name: ${record.name}, code: ${record.code}`})
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <MainLayout>
      <div className="flex justify-between py-5 items-center">
        <LoadingRoller loading={loading} />
        <BackButton />
        <a
          onClick={() => setDownloadModal(true)}
          target="_blank"
          rel="noreferrer"
          style={{ padding: "10px 20px" }}
          className="mr-2 bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
          href={`/utilities/materials/example`}>
          <i className="uil uil-download-alt text-lg"></i>
          <span className="ml-2">Download Example</span>
        </a>
      </div>

      <div className="">
        <form className="mt-5 p-5 bg-white" onSubmit={e => {
          e.preventDefault();
          onSubmit()
        }}>
          <ExcelInput onChange={setFile} />
          <br />
          <button disabled={!file} type="submit" className="dark-button-with-text mt-2">Submit</button>
        </form>
      </div>

      <Info
        onDeclined={() => setDownloadModal(false)}
        onAccepted={() => setDownloadModal(false)}
        label="Please use the excel file, that you just downloaded as the format of your data upload to make the process works properly."
        isOpen={downloadModal}
        setIsOpen={setDownloadModal}
        acceptText="OK"
      />

      <Info
        onDeclined={() => setSuccessModal(false)}
        onAccepted={() => {
          setSuccessModal(false)
          history.go(0)
        }}
        label="Operation Success, The file was successfully imported!"
        isOpen={successModal}
        setIsOpen={setSuccessModal}
        acceptText="OK"
      />
    </MainLayout>
  )
}
