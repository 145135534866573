import * as React from "react"
import { useContext, useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { SuplierPurchaseOrderStatus, SupplierPurchaseOrderErrors } from "../../domain_data/supplier_purchase_order"
import { MainLayout } from "../../layouts/Main"
import { API, IDgenerator, normalizeNumber } from "../../utils"
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios"
import iziToast from "izitoast"
import { SupplierPOForm, SupplierPOInputProps } from "./Form"
import { MainContext } from "../../context/MainContext"


export const NewSupplierPO: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<SupplierPurchaseOrderErrors>({});
  const history = useHistory();

  const onSubmit = (data: SupplierPOInputProps) => {
    const formData = {
      supplierPoItemsAttributes: data.orderItems.map(item => ({ ...item, quantity: normalizeNumber(item.quantity)})),
      ...data.po,
      status: SuplierPurchaseOrderStatus.Created
    }
    API.post("/api/supplier_purchase_orders", { supplierPurchaseOrder: formData }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/supplier_purchase_orders/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setLoading(false)
    })
  }

  const { setting } = useContext(MainContext)

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/supplier_purchase_orders" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <SupplierPOForm data={{
          po: {
            eta: new Date(),
            supplierId: null,
            currencyId: setting?.currency?.id,
            orderNumber: ""
          },
          orderItems: []
        }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        />
      </div>
    </MainLayout>
  )
}
