import axios, {AxiosRequestConfig } from 'axios'

const API = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Key-Inflection": "camel"
    }
});

API.interceptors.response.use((response) => {
    return response;
})

API.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
})

export default API;
