import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { NumberInput } from "../../components/inputs/NumberInput"
import { SingleSelect } from "../../components/inputs/SingleSelect"
import { Textarea } from "../../components/inputs/Textarea"
import { TextInput } from "../../components/inputs/TextInput"
import { AdjustmentOperation, MaterialAdjustmentErrors, MaterialAdjustmentInput } from "../../domain_data/material_adjustment"
import { DefaultFormProps, normalizeNumber } from "../../utils"

export const MaterialAdjustmentForm:
  React.FC<DefaultFormProps<MaterialAdjustmentInput, MaterialAdjustmentErrors>> =
  ({data, errors, onSubmit, id }: DefaultFormProps<MaterialAdjustmentInput, MaterialAdjustmentErrors>) => {
    const [description, setDescription] = useState<string>(data.description)
    const [operation, setOperation] = useState<AdjustmentOperation>(data.operation)
    const [quantity, setQuantity] = useState<string>(data.quantity)
    const [title, setTitle] = useState<string>(data.title)

    const serializeItem = ({
      description,
      operation,
      quantity,
      title
    }: MaterialAdjustmentInput): MaterialAdjustmentInput => {
      return {
        description,
        operation,
        quantity: normalizeNumber(quantity).toString(),
        title
      }
    }
  return(
    <form id={id} onSubmit={(e) => {
      e.preventDefault()
      onSubmit && onSubmit(serializeItem({
        description,
        operation,
        quantity,
        title}))
    }}>
      <FormBlock heading="Adsjustemt" description="This is the general material adjustment information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Title"
              value={title}
              setValue={setTitle}
              error={errors?.title}
              name="title"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <SingleSelect
              label="Operation"
              defaultItem={{ label: operation.toString(), value: operation, selected: false }}
              setValue={(item) => {
                if (item == "addition") {
                  setOperation(AdjustmentOperation.addition)
                  return
                }
                setOperation(AdjustmentOperation.subtraction)
              }}
              options={Object.values(AdjustmentOperation).map((i,k) => ({label: i.toString(), value: i.toString(), selected: false}))}
              error={errors?.title}
              name="title"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <NumberInput
              label="Quantity Unit(s)"
              value={quantity}
              setValue={setQuantity}
              error={errors?.quantity}
              name="quantity"
            />
          </div>
          <div className="col-span-6">
            <Textarea
              label="Description"
              value={description}
              setValue={setDescription}
              error={errors?.description}
              description="Brief description about this adjustment."
            />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}
