import { SettingLayout } from "../../layouts/Setting"
import * as React from "react"
import {  Link } from "react-router-dom"
import { useEffect, useState } from "react";
import { Office } from "../../domain_data/office";
import { Pagination } from "../../domain_data/pagination";
import { API } from "../../utils";
import { AxiosResponse } from "axios";
import iziToast from "izitoast";
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import { ContentLoading } from "../../components/ContentLoading";
import { truncate } from "lodash";

export const OfficeList: React.FC =() => {
  const [loading, setLoading] = useState<boolean>(false);
  const [offices, setOffices] = useState<Office[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  useEffect(() => {
    setLoading(true)
    API.get(`/api/offices?page=${page}&items=${itemPerPage}`).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setOffices(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processiong your request"
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [page, itemPerPage])

  return(
    <SettingLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <Link to="/offices/new" className="dark-button-with-text max-w-max mb-5 sm:mb-0">Add New Office</Link>
      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">
        <div className="mt-5" />
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">Name</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Address</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Email</th>
              <th className="text-center py-3 px-6 md:table-cell hidden">Phone Number</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                :
                offices?.map(office => (
                  <tr key={office.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/offices/${office.id}`}>{truncate(office.name)}</Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {truncate(office.address)}
                    </td>
                    <td className="py-3 px-6 text-center md:table-cell hidden">{truncate(office.email)}</td>
                    <td className="py-3 px-6 text-center md:table-cell hidden">{truncate(office.phoneNumber)}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </SettingLayout>
  )
}
