import { IDgenerator } from "../utils"

export interface ServiceFee {
  id: number
  cost: number
  quantity: number
  name: string
  productId: number
}

export interface ServiceFeeInput {
  id?: number
  name: string
  cost: string
  quantity: string,
  _destroy: boolean,
}

export const serviceFeeToInput = (service: ServiceFee): ServiceFeeInput => {
  return {
    id: service.id,
    name: service.name,
    cost: service.cost.toString(),
    quantity: service.quantity.toString(),
    _destroy: false,
  }
}
