import * as React from "react"
import { useState } from "react";
import { UserErrors, UserInput, UserRole } from "../../domain_data/user";
import { API, IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios";
import { SettingLayout } from "../../layouts/Setting";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { UserForm } from "./Form";
import iziToast from "izitoast";

export const NewUser: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<UserErrors>({});
  const history = useHistory();

  const onSubmit = (data: UserInput) => {
    setLoading(true);
    API.post("/api/profile", { user: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/users/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <SettingLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/users" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <UserForm data={{
          email: "",
          password: "",
          passwordConfirmation: "",
          role: UserRole.Staff
        }}
          id={formID}
          errors={errors}
          onSubmit={onSubmit}
        />
      </div>
    </SettingLayout>
  )
}
