import { AxiosError, AxiosResponse } from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { SupplierErrors, SupplierInput } from "../../domain_data/supplier";
import { MainLayout } from "../../layouts/Main";
import { API, IDgenerator } from "../../utils";
import { ItemNotFound } from "../ItemNotFound";
import { SupplierForm } from "./Form";

type SupplierEditProps = RouteComponentProps<{id: string}>


export const SupplierEdit: React.FC<SupplierEditProps> = ({ match: {params: {id}} }: SupplierEditProps) => {
  const [notFound, setNotFound] = useState<boolean>(false)
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<SupplierErrors>({});
  const history = useHistory();
  const [supplier, setSupplier] = useState<SupplierInput>(null)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/suppliers/${id}`).then(({data}: AxiosResponse) => {
      setSupplier(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [id])


  const submitSupplier = (data: SupplierInput) => {
    setLoading(true)
    API.patch(`/api/suppliers/${id}`, {supplier: data}).then(({ data: { id }}: AxiosResponse) => {
      history.push(`/suppliers/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
        <div className="flex justify-between py-5 items-center">
        <BackButton />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div>
        {
          loading ? <LoadingRoller loading={loading} />
          :
          <SupplierForm id={formID}
          supplier={supplier}
          errors={errors}
          onSubmit={(data: SupplierInput) => submitSupplier(data)}
          />
        }
      </div>
    </MainLayout>
  )
}
