import { AxiosError } from "axios";
import * as React from "react"
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom"
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { SupplierPOArrival, SupplierPOArrivalErrors, SupplierPOArrivalInput } from "../../domain_data/supplier_po_arrival";
import { MainLayout } from "../../layouts/Main";
import { API, IDgenerator } from "../../utils";
import { ItemNotFound } from "../ItemNotFound";
import { POArrivalForm } from "./Form";

type NewArrivalProps = RouteComponentProps<{ purchaseOrderId: string, id: string }>;


export const EditPOArrival: React.FC<NewArrivalProps> = ({ match: { params: { id, purchaseOrderId } }}: NewArrivalProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<SupplierPOArrivalErrors>({})
  const formID = IDgenerator();
  const history = useHistory();
  const [item, setItem] = useState<SupplierPOArrival>(null)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadItem = () => {
    setLoading(true)
    API.get(`/api/supplier_purchase_orders/${purchaseOrderId}/supplier_po_arrivals/${id}`).then(({ data }) => {
      setItem(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {

    loadItem()
  }, [])

  const onSubmit = (data: SupplierPOArrivalInput): void => {

    setSubmitting(true)
    API.patch(`/api/supplier_purchase_orders/${purchaseOrderId}/supplier_po_arrivals/${id}`, { supplier_po_arrival: data }).then(() => {
      history.push(`/supplier_purchase_orders/${purchaseOrderId}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      {loading || !item ? <LoadingRoller loading />
        :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path={`/supplier_purchase_orders/${purchaseOrderId}`} />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>
          <div className="min-h-screen">
            <POArrivalForm data={{
              dataIn: new Date(item.dataIn),
              note: item.note,
              quantity: (item.quantity || 0).toString(),
              weight: (item.weight || 0).toString(),
              supplierPoItemId: item.supplierPoItemId
            }}
              onSubmit={onSubmit}
              errors={errors}
              id={formID}
              purchaseOrderId={purchaseOrderId}
            />
          </div>
        </>
      }
    </MainLayout>
  )
}
