import * as React from "react"
import { SearchComponent } from "../../components/SeachComponent"
import { MainLayout } from "../../layouts/Main"
import { Link, useHistory } from "react-router-dom"
import { ContentLoading } from "../../components/ContentLoading"
import { useEffect, useState } from "react"
import { Pagination } from "../../domain_data/pagination"
import { API } from "../../utils"
import iziToast from "izitoast"
import { AxiosResponse } from "axios"
import { ProductCategory } from "../../domain_data/product_category"
import { ItemPerPage } from "../../components/ItemPerPage"
import { PaginationView } from "../../components/Pagination"

export const ProductCategoryList: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  useEffect(() => {
    setLoading(true)
    API.get(`/api/product_categories?page=${page}&items=${itemPerPage}`).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setProductCategories(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processiong your request"
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [page, itemPerPage])

  return(
    <MainLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <Link to="/product_categories/new" className="dark-button-with-text max-w-max mb-5 sm:mb-0">Add New Category</Link>
        <div className="inline-block sm:w-2/5 w-full align-middle sm:ml-10">
          <SearchComponent
            placeholder="Type to search by name...."
            url="/api/product_categories"
            interceptRequest={(entries: ProductCategory[]) => entries.map(item => ({ label: item.name, ...item }))}
            listComponent={(data) => (
              <>
                <div className="text-lg">{data.label}</div>
              </>
            )}
            onListItemClicked={(data) => {
              history.push(`/product_categories/${data.id}`)
            }}
          />
        </div>

      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">
        <div className="mt-5" />
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">Name</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center"><ContentLoading /></td></tr>
                :
                productCategories?.map(category => (
                  <tr key={category.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/product_categories/${category.id}`}>{category.name}</Link>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </MainLayout>
  )
}
