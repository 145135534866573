import * as React from "react"
import { useState } from "react";
import { OfficeErrors, OfficeInput } from "../../domain_data/office";
import { SettingLayout } from "../../layouts/Setting"
import { API, IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { OfficeForm } from "./Form";

export const NewOffice: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<OfficeErrors>({});
  const history = useHistory();

  const onSubmit = (data: OfficeInput) => {
    setLoading(true);
    API.post("/api/offices", { office: data }).then(({ data: { id } }: AxiosResponse) => {

      history.push(`/offices/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <SettingLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/offices" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>

      <div className="min-h-screen">
        <OfficeForm
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
          data={{
            name: "",
            address: "",
            additionalAddress: "",
            phoneNumber: "",
            email: "",
          }}
        />

      </div>
    </SettingLayout>
  )
}
