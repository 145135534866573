import * as React from "react";

interface ItemPerPageProps {
  items: number[]
  current: number
  onClick: (n: number) => void
}

export const ItemPerPage: React.FC<ItemPerPageProps> = ({ items, onClick, current }: ItemPerPageProps) => {
  return(
    <div>
      {items.map((item, index) => (
        <button disabled={item == current}
          className={`text-sm px-2
            hover:text-white hover:bg-indigo-500
              border ${item == current ? "bg-indigo-500 text-white border-indigo-500" : "border-gray-500"}
              ${index == 0 ? "rounded-l" : ""}
              ${index == (items.length - 1) ? "rounded-r" : ""}
            `}
          onClick={() => onClick(item)} key={index}>{item}
        </button>
      ))}
    </div>
  )
}
