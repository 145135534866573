import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { RouteComponentProps, Link } from "react-router-dom"
import { BackButton } from "../../components/BackButton";
import { FormBlock } from "../../components/forms/FormBlock";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { AdjustmentOperation, MaterialAdjustment } from "../../domain_data/material_adjustment";
import { LoadingRoller } from "../../components";
import { API } from "../../utils";
import { useState } from "react";
import { ItemNotFound } from "../ItemNotFound";

type MaterialAdjustmentShowProps = RouteComponentProps<{ materialId: string, id: string }>;

export const MaterialAdjustmentShow: React.FC<MaterialAdjustmentShowProps> = ({ match: { params: { id, materialId } }}: MaterialAdjustmentShowProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [materialAdjustment, setMaterialAdjustment] = React.useState<MaterialAdjustment>()
  const [notFound, setNotFound] = React.useState<boolean>(false)

  React.useEffect(() => {
    setLoading(true)
    API.get(`/api/materials/${materialId}/material_adjustments/${id}`).then(({ data }) => {
      setMaterialAdjustment(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      { loading || !materialAdjustment ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between py-6 items-center">
            <BackButton path={`/materials/${materialId}/adjustments`}/>
          </div>
          <div>
            <FormBlock heading="Material Adjustment" description="This is the information of the stock changes.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Title" value={materialAdjustment.title} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Stock Changes">
                    <>
                      {materialAdjustment.operation == AdjustmentOperation.addition ?
                        <span className="py-1 px-2 text-white font-bold bg-green-400 rounded">+{materialAdjustment.quantity} Unit(s)</span>
                        :
                        <span className="py-1 px-2 text-white font-bold bg-red-400 rounded">-{materialAdjustment.quantity} Unti(s)</span>
                      }
                    </>
                  </TextInputShow>
                </div>
                {materialAdjustment.user &&
                  <div className="col-span-4">
                    <TextInputShow label="Person in Charge" value={materialAdjustment?.user?.email} />
                  </div>
                }

                {materialAdjustment.description &&
                  <div className="col-span-4">
                    <TextInputShow label="Description" value={materialAdjustment.description} />
                  </div>
                }
                {materialAdjustment.productAdjustmentId &&
                  <ProductAdjustmentLink productAdjustmentId={materialAdjustment.productAdjustmentId} />
                }
              </div>
            </FormBlock>
            <FormUnderline />
          </div>
        </div>
      }
    </MainLayout>
  )
}


interface ProductAdjustmentLinkprops {
  productAdjustmentId: number;
}
const ProductAdjustmentLink: React.FC<ProductAdjustmentLinkprops> = ({ productAdjustmentId }: ProductAdjustmentLinkprops) => {
  const [id, setId] = useState<number>(0)

  API.get(`/api/product_adjustments/${productAdjustmentId}`).then(({ data: {productId} }) => {
    setId(productId)
  })
  return(
    <div className="col-span-4">
      <FormUnderline />
      <p>This adjustemtn is generated while adjusting product
        <Link to={`/products/${id}/adjustments/${productAdjustmentId}`} className="text-indigo-400 underline"> here</Link>
      </p>
    </div>
  )
}
