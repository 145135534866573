import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { TextInput } from "../../components/inputs/TextInput"
import { JobOrderGroupErrors, JobOrderGroupInput } from "../../domain_data/job_order_group"
import { DefaultFormProps } from "../../utils"

export const JobOrderGroupForm: React.FC<DefaultFormProps<JobOrderGroupInput, JobOrderGroupErrors>> = ({ data, errors, id, onSubmit }: DefaultFormProps<JobOrderGroupInput, JobOrderErrors>) => {
  const [name, setName] = useState<string>(data.name)

  return(
    <form id={id} onSubmit={(e) => {
      e.preventDefault()
      onSubmit && onSubmit({
        name
      })
    }}>

      <FormBlock heading="Currency Information" description="This is the general currency information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Group Name"
              value={name}
              setValue={setName}
              error={errors?.name}
              name="name"
            />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}
