import * as React from "react"
import { useState } from "react"
import { DefaultProp } from "../utils/DefaultProp"

interface DropdownProps {
  children?: React.ReactNode
  label?: string
  disabled?: boolean,
  header?: React.ReactNode,
  overridesClass?: string,
  overridesClassChildren?: string
  internal?: boolean
}

export const Dropdown: React.FC<DropdownProps> = ({ label, disabled, children, header, overridesClass, overridesClassChildren, internal }: DropdownProps) => {
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false)

  return(
    <div className="relative inline-block w-full">
      <button
        disabled={!!disabled}
        className={`${overridesClass ? overridesClass : "border-2 p-3 rounded bg-transparent border-indigo-500 text-indigo-500 font-bold"} disabled:opacity-50 w-full focus:outline-none`}
        onFocus={() => setIsOptionOpen(true)}
        onBlur={() => setIsOptionOpen(false)}
      >
        { header ? header : label }
        { !!internal && children && <DropdownModal overridesClassChildren={overridesClassChildren} isOptionOpen={isOptionOpen} >{children}</DropdownModal> }
      </button>
      {!internal && children && <DropdownModal overridesClassChildren={overridesClassChildren} isOptionOpen={isOptionOpen} >{children}</DropdownModal>}
    </div>
  )
}

interface DropdownModalProps extends DefaultProp {
  isOptionOpen: boolean,
  overridesClassChildren?: string
}

const DropdownModal: React.FC<DropdownModalProps> = ({ children, isOptionOpen, overridesClassChildren }: DropdownModalProps) => {
  return(
    <div className={`${isOptionOpen ? "h-auto" : "h-0"} absolute ${overridesClassChildren ? overridesClassChildren : "bg-white w-full"}  shadow overflow-hidden`}>
        { children }
      </div>
  )
}
