import * as React from "react";
import { SupplierErrors, SupplierInput } from "../../domain_data/supplier"
import { TextInputContentLoader } from "../../components/content_loader/TextInputContentLoader";
import { TextInput } from "../../components/inputs/TextInput"
import { Textarea } from "../../components/inputs/Textarea";
import { FormBlock } from "../../components/forms/FormBlock";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { useState } from "react";


interface FormProps {
    id:         string
    onSubmit?:  OnSubmitHandler,
    supplier:   SupplierInput,
    errors:     SupplierErrors
}

interface OnSubmitHandler {
    (data: SupplierInput): void
}


export const SupplierForm: React.FC<FormProps> = ({id, onSubmit, supplier, errors}: FormProps) => {
  const [address, setAddress] = useState<string>(supplier?.address || "")
  const [bankAccountNumber, setBankAccountNumber] = useState<string>(supplier?.bankAccountNumber || "")
  const [bankCountry, setBankCountry] = useState<string>(supplier?.bankCountry || "")
  const [bankHolderName, setBankHolderName] = useState<string>(supplier?.bankHolderName || "")
  const [bankName, setBankName] = useState<string>(supplier?.bankName || "")
  const [companyName, setCompanyName] = useState<string>(supplier?.companyName || "")
  const [contactEmail, setContactEmail] = useState<string>(supplier?.contactEmail || "")
  const [contactFullName, setContactFullName] = useState<string>(supplier?.contactFullName || "")
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string>(supplier?.contactPhoneNumber || "")
  const [country, setCountry] = useState<string>(supplier?.country || "")
  const [description, setDescription] = useState<string>(supplier?.description || "")
  const [shippingAddress, setShippingAddress] = useState<string>(supplier?.shippingAddress || "")
  const [shippingCountry, setShippingCountry] = useState<string>(supplier?.shippingCountry || "")
  const [shippingPostalCode, setShippingPostalCode] = useState<string>(supplier?.shippingPostalCode || "")

    return(
      <form id={id} onSubmit={(e) => {
          e.preventDefault()
          onSubmit && onSubmit({
            address,
            bankAccountNumber,
            bankCountry,
            bankHolderName,
            bankName,
            companyName,
            contactEmail,
            contactFullName,
            contactPhoneNumber,
            country,
            description,
            shippingAddress,
            shippingCountry,
            shippingPostalCode
          })
      }}>
        <div>
          <FormBlock heading="Company Information" description="This is the general company information.">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 md:col-span-3">
                <TextInput
                  label="Company Name"
                  value={companyName}
                  setValue={setCompanyName}
                  error={errors?.companyName}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <TextInput
                  label="Contact Person"
                  value={contactFullName}
                  setValue={setContactFullName}
                  error={errors?.contactFullName}
                />
              </div>

              <div className="col-span-6 md:col-span-3">
                <TextInput
                  label="Email"
                  value={contactEmail}
                  setValue={setContactEmail}
                  error={errors?.contactEmail}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Phone Number"
                  value={contactPhoneNumber}
                  setValue={setContactPhoneNumber}
                  error={errors?.contactPhoneNumber}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Country"
                  value={country}
                  setValue={setCountry}
                  error={errors?.country}
                />
              </div>

              <div className="col-span-6">
                <TextInput
                  label="Address"
                  value={address}
                  setValue={setAddress}
                  error={errors?.address}
                />
              </div>

              <div className="col-span-6">
                <Textarea
                  label="Note"
                  value={description}
                  setValue={setDescription}
                  error={errors?.description}
                  description="Brief description for the company."
                />
              </div>
            </div>
          </FormBlock>
        </div>

        <FormUnderline />

        <div className="mt-10 sm:mt-0">
          <FormBlock heading="Billing Address" description="The information of the billing address of the company.">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Holder Name"
                  value={bankHolderName}
                  setValue={setBankHolderName}
                  error={errors?.bankHolderName}
                  name="bank_holder_name"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Bank Name"
                  value={bankName}
                  setValue={setBankName}
                  error={errors?.bankName}
                  name="bank_name"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Bank Account Number"
                  value={bankAccountNumber}
                  setValue={setBankAccountNumber}
                  error={errors?.bankAccountNumber}
                  name="bank_account_number"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Country"
                  value={bankCountry}
                  setValue={setBankCountry}
                  error={errors?.bankCountry}
                  name="billing_postal_code"
                />
              </div>
            </div>
          </FormBlock>
        </div>

        <FormUnderline />

        <div className="mt-10 sm:mt-0">
          <FormBlock heading="Shipping Address" description="The information of the shipping address of the company.">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Country"
                  value={shippingCountry}
                  setValue={setShippingCountry}
                  error={errors?.shippingCountry}
                  name="shipping_country"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <TextInput
                  label="Postal Code"
                  value={shippingPostalCode}
                  setValue={setShippingPostalCode}
                  error={errors?.shippingPostalCode}
                  name="shipping_postal_code"
                />
              </div>
              <div className="col-span-full">
                <TextInput
                  label="Address"
                  value={shippingAddress}
                  setValue={setShippingAddress}
                  error={errors?.shippingAddress}
                />
              </div>
            </div>
          </FormBlock>
        </div>
      </form>
    )
}


export const FormContentLoader: React.FC = () => {
    return(
      <div className="bg-white p-10 rounded shadow-md">
        <div className="flex w-full">
          <div className="w-1/2">
            <div className="w-9/12">
              <h1 className="text-gray-900 font-bold text-xl">Info</h1>
              <hr className="border-gray-700 mb-2"/>
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
            </div>
          </div>
          <div className="w-1/2">
            <div className="w-9/12">
              <h1 className="text-gray-900 font-bold text-xl">Contact</h1>
              <hr className="border-gray-700 mb-2"/>
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
            </div>
          </div>
        </div>
        <div className="flex w-full mt-10">
          <div className="w-1/2">
            <div className="w-9/12">
              <h1 className="text-gray-900 font-bold text-xl">Billing Address</h1>
              <hr className="border-gray-700 mb-2"/>
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
            </div>
          </div>
          <div className="w-1/2">
            <div className="w-9/12">
              <h1 className="text-gray-900 font-bold text-xl">Shipping Address</h1>
              <hr className="border-gray-700 mb-2"/>
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
              <TextInputContentLoader />
            </div>
          </div>
        </div>
      </div>
    )
}
