import { AxiosError, AxiosResponse } from "axios";
import * as React from "react";
import { useState } from "react";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { MaterialErrors, MaterialInput } from "../../domain_data/material";
import { MainLayout } from "../../layouts/Main";
import { API, IDgenerator } from "../../utils";
import { MaterialForm } from "./Form"
import { useHistory } from "react-router-dom"

export const NewMaterial: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<MaterialErrors>(null);
  const history = useHistory();


  const onSubmit = (data: MaterialInput) => {
    setLoading(true);
    API.post("/api/materials", { material: { ...data, images: data.images.map(i => i.signedId)} }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/materials/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }
  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/materials" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <MaterialForm data={{
            name: "",
            price: "0",
            unit: "",
            minimumQuantity: "0",
            stock: "0",
            code: "",
            labor: "",
            weight: "",
            shape: "",
            pricePerCarat: ""
          }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        >

        </MaterialForm>
      </div>
    </MainLayout>
  )
}
