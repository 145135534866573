import * as React from "react";
import { IDgenerator } from "../../utils";

export interface SelectOptionProps {
  label: string;
  selected: boolean;
  value: string;
}

export interface SingleSelectProps<T extends SelectOptionProps> {
  error?: string[]
  label: string
  name?: string
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  setValue: (value: string) => void,
  options: T[],
  defaultItem?: T,
}

export function SingleSelect<T extends SelectOptionProps>({ defaultItem, error, setValue, label, name, placeholder, disabled, options }: SingleSelectProps<T>): React.ReactElement {

  const id = name ? name : IDgenerator();
  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <select
          defaultValue={defaultItem?.value}
          className="block w-full px-3 py-3 border
            border-gray-300 bg-white rounded-md shadow-sm
            focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
            sm:text-sm"
          name={id}
          id={id}
          placeholder={placeholder}
          disabled={!!disabled}
          onChange={(e) => setValue(e.target.value)}
        >
          {options.map((i, key) => (
            <option key={key} value={i.value}>{i.label}</option>
          ))}
        </select>
      </div>
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
    </>
  )
}
