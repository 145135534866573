import * as React from "react"
import { useEffect, useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { MainLayout } from "../../layouts/Main"
import { API, IDgenerator } from "../../utils"
import { ProductCategoryForm } from "./Form"
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios"
import { ProductCategoryShowProps } from "./Show"
import { ProductCategory, ProductCategoryErrors, ProductCategoryInput } from "../../domain_data/product_category"
import { ItemNotFound } from "../ItemNotFound"

export const ProductCategoryEdit: React.FC<ProductCategoryShowProps> = ({ match: { params: { id } } }: ProductCategoryShowProps) => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductCategoryErrors>({});
  const history = useHistory();
  const [category, setCategory] = useState<ProductCategory>()
  const [notFound, setNotFound] = useState<boolean>(false)


  useEffect(() => {
    setLoading(true)
    API.get(`/api/product_categories/${id}`).then(({ data }) => {
      setCategory(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [id])

  const onSubmit = (data: ProductCategoryInput) => {
    setLoading(true);
    API.patch(`/api/product_categories/${id}`, { product_category: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/product_categories/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (notFound) return <ItemNotFound />

  return (
    <MainLayout>
      { loading || !category ? <LoadingRoller loading /> :
        <>
          <div className="flex justify-between py-5 items-center">
            <BackButton />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <ProductCategoryForm data={category} id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </MainLayout>
  )
}
