import * as React from "react"
import { useEffect, useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { API, IDgenerator } from "../../utils"
import { useHistory } from "react-router-dom"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { AxiosError, AxiosResponse } from "axios"
import { ItemNotFound } from "../ItemNotFound"
import { TaxErrors, TaxInput } from "../../domain_data/tax"
import { TaxForm } from "./Form"
import { SettingLayout } from "../../layouts/Setting"
import iziToast from "izitoast"

export const TaxEdit: React.FC<DefaultParamProps> = ({ match: { params: { id } } }: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<TaxErrors>({});
  const [tax, setTax] = useState<TaxInput>();
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/taxes/${id}`).then(({ data }) => {
      setTax(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const onSubmit = (data: TaxInput) => {
    setLoading(true);
    API.patch(`/api/taxes/${id}`, { tax: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/taxes/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (notFound) return <ItemNotFound />

  const formID = IDgenerator();
  return (
    <SettingLayout>
      { loading || !tax ? <LoadingRoller loading /> :
        <>
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/taxes" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <TaxForm data={tax} id={formID} errors={errors} onSubmit={onSubmit}/>
          </div>
        </>

      }
    </SettingLayout>
  )
}
