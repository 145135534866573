import * as React from "react"
import { useContext, useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { NumberInputWithPrefix } from "../../components/inputs/NumberInputWithPrefix"
import { TextInput } from "../../components/inputs/TextInput"
import { MainContext } from "../../context/MainContext"
import { BaseMaterialErrors, BaseMaterialInput } from "../../domain_data/base_material"
import { DefaultFormProps, normalizeNumber } from "../../utils"

export const BaseMaterialForm: React.FC<DefaultFormProps<BaseMaterialInput, BaseMaterialErrors>> =
  ({ data, onSubmit, id, errors }:
DefaultFormProps<BaseMaterialInput, BaseMaterialErrors>) => {
    const [price, setPrice] = useState<string>(data.price)
    const [name, setName] = useState<string>(data.name)
    const [unit, setUnit] = useState<string>(data.unit)

    const { setting } = useContext(MainContext)

  return (
    <form id={id}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit({
          name,
          unit,
          price: normalizeNumber(price).toString()
        })
      }}
    >
      <FormBlock heading="Material Information" description="This is the general material information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Name"
              value={name}
              setValue={setName}
              error={errors?.name}
              name="name"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <NumberInputWithPrefix
              label="Price"
              value={price}
              setValue={setPrice}
              error={errors?.price}
              name="price"
              prefix={setting?.currency?.code}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Unit"
              value={unit}
              setValue={setUnit}
              error={errors?.unit}
              name="unit"
            />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}
