import * as React from "react"
import { useEffect, useState } from "react"
import { ImageInput } from "../../components/inputs/ImageInput"
import { normalizeFileBlob, serialzeFileBlob } from "../../domain_data/image_attachment"
import { ProductVariantInput } from "../../domain_data/product_variant"

export interface ProductVariantItemPros {
  variant: ProductVariantInput,
  onDelete: (data: ProductVariantInput) => void
}

export const ProductVariantFormItem: React.FC<ProductVariantItemPros> = ({ variant }: ProductVariantItemPros) => {
  const [text, setText] = useState<string>(variant.name)
  const [isDeleted, setIsdeleted] = useState<boolean>(variant.deleted)

  const handleDelete = () => {
    setIsdeleted(true)
    variant.deleted = true
  }

  useEffect(() => {
    variant.name = text
  }, [text])

  if (isDeleted) { return null }
  return (
    <div className="col-span-6">
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-6 sm:col-span-2">
          <ImageInput
            file={variant?.image && serialzeFileBlob(variant?.image)}
            onUploaded={(image) => { variant.image = normalizeFileBlob(image); }}
            onDelete={() => { variant.image = null; }}
          />
        </div>
        <div className="col-span-9 self-center">
          <input required className="px-3 py-3 border focus:outline-none
            focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
            placeholder="Variant Name"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <div className="col-span-1 self-center">
          <button type="button" className="bg-red-500 rounded-md text-white p-3 " onClick={handleDelete}>
            <i className="uil uil-times"></i>
          </button>
        </div>

      </div>
    </div>
  )
}
