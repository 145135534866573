import * as React from "react";
import { IDgenerator } from "../../utils";

export interface CheckboxProps {
  value: boolean
  setValue: (value: boolean) => void,
  error?: string[],
  name?: string,
  description?: string
  label: string
}

export const Checkbox: React.FC<CheckboxProps> = ({ value, name, error, label, setValue, description }: CheckboxProps) => {
  const id = name ? name : IDgenerator();
  return(
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          name={id}
          checked={value}
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          onChange={() => setValue(!value)}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
    </div>
  )
}
