import { AxiosError } from "axios";
import iziToast from "izitoast";
import * as React from "react"
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom"
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { ProductPOReturn, ProductPOReturnErrors, ProductPOReturnInput, ProductPOReturnStage } from "../../domain_data/product_po_return";
import { MainLayout } from "../../layouts/Main";
import { API, IDgenerator, normalizeNumber } from "../../utils";
import { ItemNotFound } from "../ItemNotFound";
import { ProductPORetunForm } from "./Form";
import { isEmpty } from "lodash";
import * as QS from "query-string"


export type ProductPOReturnParams = RouteComponentProps<{ jobOrderGroupId: string, jobOrderId: string, id: string, no_weight: string }>;

export const EditProductPOReturn: React.FC<ProductPOReturnParams> = ({ match: { params: { jobOrderGroupId, jobOrderId, id, no_weight } } }: ProductPOReturnParams) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setError] = useState<ProductPOReturnErrors>({})
  const formID = IDgenerator();
  const history = useHistory();
  const [item, setItem] = useState<ProductPOReturn>(null)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const stage = QS.parse(location.search).no_weight as ProductPOReturnStage;


  const withWeight = [ProductPOReturnStage.Arriving, ProductPOReturnStage.InStock].includes(stage)


  const loadItem = () => {
    setLoading(true)
    API.get(`/api/product_purchase_orders/${jobOrderId}/product_po_returns/${id}`).then(({ data }) => {
      setItem(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {

    loadItem()
  }, [])

  const submit = (data: ProductPOReturnInput) => {
    setSubmitting(true)
    const form = {
      quantity: normalizeNumber(data.quantity),
      weight: normalizeNumber(data.weight),
      dateIn: data.dateIn,
      note: data.note,
      stage: data.stage,
      operation: data.operation,
    }
    API.patch(`/api/product_purchase_orders/${jobOrderId}/product_po_returns/${id}`, { productPoReturn: form }).then(() => {
      history.push(`/job_order_groups/${jobOrderGroupId}/job_orders/${jobOrderId}`)
    }).catch(({ response: { data } }: AxiosError) => {
      iziToast.error({ title: "Error", message: "Failed to save the record, please check the form" })
      setError(data)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  const itemToInput = {
    quantity: item?.quantity?.toString(),
    weight: item?.weight?.toString(),
    dateIn: new Date(item?.dateIn),
    note: item?.note?.toString(),
    stage: item?.stage,
    operation: item?.operation,
  }

  if (notFound) return <ItemNotFound />

  return (
    <MainLayout>
      {loading || !item ? <LoadingRoller loading />
        :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>
          <div className="min-h-screen">
            <ProductPORetunForm
              errors={errors}
              id={formID}
              data={itemToInput}
              onSubmit={submit}
              noWeight={!withWeight}
            />
          </div>
        </>
      }
    </MainLayout>
  )
}
