import * as React from "react";
import { FileBlobNormalized } from "../../domain_data/image_attachment";

interface MultipleFileShowProps {
  files: FileBlobNormalized[]
}


export const MultipleFileShow: React.FC<MultipleFileShowProps> = ({ files }: MultipleFileShowProps) => {
  return (
    <div className="grid grid-cols-12 gap-6">
      {files.map((file, index) => (
        <div key={index} className="lg:col-span-2 sm:col-span-4 col-span-6">
          <div className="rounded-md overflow-hidden relative h-28">
            <img className="w-full h-auto" src={file?.url} />
          </div>
          <a className="text-xs hover:underline" href={file?.url} target="_blank" rel="noopener noreferrer">See full</a>
        </div>
      ))}
    </div>
  )
}
