import iziToast from "izitoast";
import * as React from "react"
import { useEffect, useState } from "react";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { ProductionReport, ProductionReportItem } from "../../domain_data/production_report";
import { MainLayout } from "../../layouts/Main"
import API from "../../utils/api";
import { DefaultParamProps } from "../../utils/DefaultProp"
import { Link, useHistory } from "react-router-dom"
import { FormBlock } from "../../components/forms/FormBlock";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { ProductPurchaseOrder } from "../../domain_data/product_purchase_order";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { OrderProgressSummary } from "./Form";
import { ItemNotFound } from "../ItemNotFound";
import { Modal } from "../../components/Modal";


export const ShowProductionReport: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ProductionReport>(null)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [reportItems, setReportItems] = useState<ProductionReportItem[]>([])
  const history = useHistory();

  const [isDeleting, setIsdeleting] = React.useState<boolean>(false);

  const loadReport = () => {
    setLoading(true)
    API.get(`/api/production_reports/${id}`).then(({ data }) => {
      setReport(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      loadReportItems()
    })
  }

  const loadReportItems = () => {
    API.get(`/api/production_reports/${id}/production_report_items`).then(({ data }) => {
      setReportItems(data)
    }).catch(() => {
      iziToast.error({title: "Error", message: "Failed to retrieve product report items"})
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadReport()
  }, [])

  if (notFound) return <ItemNotFound />

  const handleDelete = () => {
    setIsdeleting(true)
    API.delete(`/api/production_reports/${id}`).then(() => {
      history.push("/production_reports")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this product" })
    }).finally(() => {
      setIsdeleting(false)
    })
  }

  return(
    <MainLayout>
      {loading || !report || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/production_reports" />
            <div className="di">
              <a
                target="_blank"
                rel="noreferrer"
                style={{ padding: "10px 20px" }}
                className="hidden mr-2 bg-green-500 text-white h-auto transition-all hover:bg-green-800 hover:text-white font-bold rounded text-sm"
                href={`/utilities/production_reports/${id}.xlsx`}>
                <i className="uil uil-download-alt text-lg"></i>
                <span className="ml-2">Save Excel</span>
              </a>

              <a target="_blank" rel="noreferrer" className="dark-button-with-text mr-3" href={`/utilities/production_reports/${id}.pdf`}>
                <i className="uil uil-download-alt text-lg"></i>
                <span className="ml-2">Save PDF</span>
              </a>

              <Link className="dark-button-with-text" to={`/production_reports/${id}/edit`}>
                <i className="uil uil-edit-alt text-lg"></i>
                <span className="ml-2">Edit</span>
              </Link>
            </div>
          </div>

          <FormBlock heading="Report Information" description="This is the general report information.">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <TextInputShow label="Report Number" >
                  <p className="font-bold">{report.reportNumber}</p>
                </TextInputShow>
              </div>
            </div>
          </FormBlock>

          <FormUnderline />

          <FormBlock heading="Job Orders List" description="This is the list of job order that will be reported .">
            <div className="grid grid-cols-6 gap-6">
              {(reportItems || []).map((item, key) => <ReportItemDetail key={key} jobOrderId={item.jobOrderGroupId}/>) }
            </div>
          </FormBlock>

          <Modal
            onDeclined={() => setIsModalOpen(false)}
            onAccepted={handleDelete}
            label="We can't undo this process, Are you sure want to delete this product?"
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
          />
          <div className="inline-block mr-3">
            <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setIsModalOpen(true)}>
              <div className="w-5 inline-block">
                <i className="uil uil-trash text-white"></i>
              </div>
              <p className="text-white font-bold ml-2">Delete</p>
            </button>
          </div>
        </div>
      }
    </MainLayout>
  )
}

interface ReportItemDetailProps {
  jobOrderId: number;
}

const ReportItemDetail: React.FC<ReportItemDetailProps> = ({ jobOrderId }: ReportItemDetailProps) => {
  const [jobOrder, setJobOrder] = useState<ProductPurchaseOrder>(null)

  useEffect(() => {
    API.get(`/api/product_purchase_orders/${jobOrderId}`).then(({ data }) => {
      setJobOrder(data)
    }).catch(() => {
      iziToast.error({title: "Error", message: "Failed to retrieve Job Order Details"})
    })
  }, [])

  return (
    <div className="col-span-6">
      { !jobOrder ? <div>Loading...</div>
        :
        <div>
          <p>Job Order
            <Link to={`/job_orders/${jobOrderId}`}>
              {jobOrder.orderNumber}
            </Link>
          </p>
          <hr />
          <OrderProgressSummary jobOrderId={jobOrderId} />
          <hr />
        </div>
      }
    </div>
  )
}
