import * as React from "react"
import { useEffect, useState } from "react";
import { BaseMaterialErrors, BaseMaterialInput } from "../../domain_data/base_material";
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory } from "react-router-dom"
import { API, IDgenerator } from "../../utils";
import { AxiosError, AxiosResponse } from "axios";
import { ItemNotFound } from "../ItemNotFound";
import { MainLayout } from "../../layouts/Main";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { BaseMaterialForm } from "./Form";

export const EditBaseMaterial: React.FC<DefaultParamProps> = ({ match: { params: { id } } }: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<BaseMaterialErrors>({});
  const [material, setMaterial] = useState<BaseMaterialInput>();
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/base_materials/${id}`).then(({ data }) => {
      setMaterial(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const onSubmit = (data: BaseMaterialInput) => {
    setLoading(true);
    delete data["stock"]

    API.patch(`/api/base_materials/${id}`, { base_material: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/base_materials/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (notFound) return <ItemNotFound />

  const formID = IDgenerator();
  return (
    <MainLayout>
      { loading || !material ? <LoadingRoller loading /> :
        <>
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/base_materials" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <BaseMaterialForm data={material} id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </MainLayout>
  )
}
