import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router } from "../src/router"
import '@formatjs/intl-locale/polyfill'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config()

if (process.env.NODE_ENV == "production" && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Router/>, document.querySelector("#app"))
})
