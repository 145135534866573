import { IDgenerator } from "../utils";
import { FileBlobNormalized } from "./image_attachment";

export interface ProductVariant {
  id: number
  name: string
  productId: number
  image?: FileBlobNormalized
}

export interface ProductVariantInput {
  id?: number
  name: string
  image?: FileBlobNormalized
  identifier: string
  deleted?: boolean
}
export const productVariantToInput = ({ id, name, image }: ProductVariant): ProductVariantInput => {
  return {
    id,
    name,
    image,
    identifier: IDgenerator(),
    deleted: false
  }
}

export interface ProductVariantErrors {
  name: string[]
}
