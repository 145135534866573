import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { TextInput } from "../../components/inputs/TextInput"
import { OfficeErrors, OfficeInput } from "../../domain_data/office"
import { DefaultFormProps } from "../../utils"

export const OfficeForm: React.FC<DefaultFormProps<OfficeInput, OfficeErrors>> = ({ data, errors, id, onSubmit }: DefaultFormProps<OfficeInput, OfficeErrors>) => {
  const [name, setName] = useState<string>(data.name || "")
  const [address, setAddress] = useState<string>(data.address || "")
  const [additionalAddress, setAdditionalAddress] = useState<string>(data.additionalAddress || "")
  const [phoneNumber, setPhoneNumber] = useState<string>(data.phoneNumber || "")
  const [email, setEmail] = useState<string>(data.email || "")

  return (
    <form id={id} onSubmit={(e) => {
      e.preventDefault()
      onSubmit && onSubmit({
        name,
        address,
        additionalAddress,
        phoneNumber,
        email
      })
    }}>
      <FormBlock heading="Office Information" description="This is the general office information.">
        <div className="grid grid-cols-6 gap-6">

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Company Name"
              value={name}
              setValue={setName}
              error={errors?.name}
              name="name"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Email"
              value={email}
              setValue={setEmail}
              error={errors?.email}
              name="email"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Phone Number"
              value={phoneNumber}
              setValue={setPhoneNumber}
              error={errors?.phoneNumber}
              name="phone_number"
            />
          </div>
          <div className="col-span-6">
            <TextInput
              label="Address"
              value={address}
              setValue={setAddress}
              error={errors?.address}
              name="address"
            />
          </div>
          <div className="col-span-6">
            <TextInput
              label="Second Line Address"
              value={additionalAddress}
              setValue={setAdditionalAddress}
              error={errors?.additionalAddress}
              name="additional_address"
            />
          </div>
        </div>
      </FormBlock>
    </form>
  )

}
