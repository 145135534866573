import * as React from "react";
import { useEffect, useState, useCallback, useLayoutEffect } from "react";
import { DirectUpload } from "@rails/activestorage"
import { FileBlob } from "../../domain_data/image_attachment";
import iziToast from "izitoast";
import { useDropzone } from 'react-dropzone'
import API from "../../utils/api";
import { IDgenerator } from "../../utils";

interface DraggedFile extends File {
  preview: string
  uploaded: boolean
  id: string
  uploadeBlob?: FileBlob
}

export interface MultipleFilesInputProps {
  onUploaded?: (file: FileBlob) => void
  initialFiles?: FileBlob[]
  onChange?: (files: FileBlob[]) => void
  error?: string[]
}

export const MultipleFilesInput: React.FC<MultipleFilesInputProps> = ({ onUploaded, initialFiles, onChange, error }: MultipleFilesInputProps) => {
  const [files, setFiles] = useState<DraggedFile[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<FileBlob[]>([])
  const [initialBlobs, setInitialBlobs] = useState<FileBlob[]>(initialFiles || [])

  useEffect(() => {
    if (initialBlobs.length == 0) return
    setUploadedFiles(current => current.concat(initialBlobs))
  }, [])

  useLayoutEffect(() => {
    onChange && onChange(uploadedFiles)
  }, [uploadedFiles])

  const isEmpty = files.length == 0 && initialBlobs.length == 0

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
      uploaded: false,
      id: IDgenerator()
    }));
    setFiles(current => current.concat(newFiles));

  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "image/*" })

  return (
    <div {...getRootProps()} className="border-2 border-dotted p-5 xl:p-10">
      <input {...getInputProps()} accept="image/*"/>
      <div className=" grid grid-cols-12 gap-6">
        <>
          {
            initialBlobs.map((file, index) => (
              <div className="xl:col-span-2 sm:col-span-4 col-span-6" key={index} >
                <InitialFileItem file={file}
                  onDelete={file => {
                    setUploadedFiles(current => current.filter(i => i.id != file.id))
                    setInitialBlobs(current => current.filter(i => i.id != file.id))
                  }}
                />
              </div>
            ))
          }
          {
            files.map((file, index) => (
              <div className="xl:col-span-2 sm:col-span-4 col-span-6" key={index} >
                <ImagePreviewItem file={file} onUploaded={(file) => {
                  if (file.uploadeBlob) {
                    setUploadedFiles(current => current.concat(file.uploadeBlob))
                    onUploaded && onUploaded(file.uploadeBlob)
                  }
                }}
                onDelete={file => {
                  if (file.uploadeBlob) {
                    setUploadedFiles(current => current.filter(i => i.id != file.uploadeBlob.id))
                  }
                  setFiles(current => current.filter(i => i.id != file.id))
                }}
                />
              </div>
            ))
          }
        </>
      </div>
      { isEmpty &&
        <div className="text-center cursor-pointer">
        <i className="uil uil-image-upload text-3xl text-gray-700"></i>
        <p className="text-gray-700">Drag n drop some files here, or click to select files</p>
        </div>
      }
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
    </div>
  )
}

interface InitialFileItemProps {
  file: FileBlob
  onDelete?: (file: FileBlob) => void
}

const InitialFileItem: React.FC<InitialFileItemProps> = ({ file, onDelete }: InitialFileItemProps) => {
  const [deleting, setDeleting] = useState<boolean>(false)

  const deleteImage = (event) => {
    setDeleting(true)
    event.preventDefault()
    event.stopPropagation()
    API.delete(`/api/blobs/${file.signed_id}`).then(() => {
      onDelete && onDelete(file)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to delete image"})
    }).finally(() => {
      setDeleting(false)
    })

  }

  return(
    <div className="rounded-md overflow-hidden relative">
      <div className=" h-28">
        <img src={file?.url} className="w-full h-auto" />
      </div>
      <div className="absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center rounded-md cursor-pointer">
        {deleting &&
          <svg className="animate-spin h-5 w-5 mr-3 rounded-full"
            style={{ border: "2px solid white", borderLeft: "2px solid rgba(255, 255, 255, var(--tw-text-opacity))" }}
            viewBox="0 0 24 24" />
        }

        {!deleting && <button className="absolute transition-all duration-300 right-0 top-0" onClick={deleteImage}>
            <i className="uil uil-times text-white text-3xl bg-red-500" />
          </button>
        }
      </div>
    </div>
  )
}

interface ImagePreviewItemProps {
  file: DraggedFile
  onUploaded?: (file: DraggedFile) => void
  onDelete?: (file: DraggedFile) => void
}

const ImagePreviewItem: React.FC<ImagePreviewItemProps> = ({ file, onUploaded, onDelete }: ImagePreviewItemProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(100)
  const [loaded, setLoaded] = useState<number>(0)
  const [deleting, setDeleting] = useState<boolean>(false)

  useEffect(() => {
    if (file.uploaded) return;

    setLoading(true)
    const uploader = new DirectUpload(file, "/rails/active_storage/direct_uploads", {
      directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress", event => {
          setTotal(event.total)
          setLoaded(event.loaded)
        })
      }
    })

    uploader.create((error, blob) => {
      if (error) {
        iziToast.error({ title: "Error", message: "Failed to upload image" })
        setLoading(false)
      } else {
        file.uploaded = true
        file.uploadeBlob = blob
        setLoading(false)
        onUploaded && onUploaded(file)
      }
    })
  }, [])

  const deleteImage = (event) => {
    setDeleting(true)
    event.preventDefault()
    event.stopPropagation()
    if (!file.uploadeBlob) {
      onDelete && onDelete(file)
      return
    }
    API.delete(`/api/blobs/${file.uploadeBlob.signed_id}`).then(() => {
      onDelete && onDelete(file)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to delete image" })
    }).finally(() => {
      setDeleting(false)
    })

  }

  return (
    <div className="rounded-md overflow-hidden relative">
      <div className=" h-28">
        <img src={file.preview} className="w-full h-auto" />
      </div>
      <div className="absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center rounded-md cursor-pointer">
        <div className={`${loading ? "flex" : "hidden"} bg-white w-full h-3 items-center rounded-md px-1 transition-all delay-150 duration-300`}>
          <div className="bg-indigo-500 h-2 rounded-md" style={{ width: `${(loaded/total)*100}%` }}/>
        </div>

        {deleting &&
          <svg className="animate-spin h-5 w-5 mr-3 rounded-full"
            style={{ border: "2px solid white", borderLeft: "2px solid rgba(255, 255, 255, var(--tw-text-opacity))"}}
            viewBox="0 0 24 24" />
        }
        {!deleting && !loading && <button className="absolute transition-all duration-300 right-0 top-0" onClick={deleteImage}>
          <i className="uil uil-times text-white text-3xl bg-red-500" />
        </button> }
      </div>
    </div>
  )
}
