import * as React from "react";
import { useHistory } from "react-router-dom";
import { MainLayout } from "../../layouts/Main";
import { SupplierErrors, SupplierInput } from "../../domain_data/supplier";
import { API, IDgenerator } from "../../utils";
import { SupplierForm } from "./Form"
import { AxiosError, AxiosResponse } from "axios";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { useState } from "react";

export const NewSupplier: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<SupplierErrors>({});
  const history = useHistory();

  const submitSupplier = (data: SupplierInput) => {
    setLoading(true)

    API.post("/api/suppliers", {supplier: data}).then(({ data: {id} }: AxiosResponse) => {
      history.push(`/suppliers/${id}`)
    }).catch(({response: { data }}: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div>
        <SupplierForm id={formID}
          supplier={{
            address: "",
            bankAccountNumber: "",
            bankCountry: "",
            bankHolderName: "",
            bankName: "",
            companyName: "",
            contactEmail: "",
            contactFullName: "",
            contactPhoneNumber: "",
            country: "",
            description: "",
            shippingAddress: "",
            shippingCountry: "",
            shippingPostalCode: "",
          }}
          errors={errors}
          onSubmit={(data: SupplierInput) => submitSupplier(data)}
        />
      </div>
    </MainLayout>
  )
}
