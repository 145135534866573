import * as React from "react"
import { Pagination } from "../domain_data/pagination"
import { debounce, isEmpty } from "lodash"
import { API } from "../utils"
import { SearchDataEntry } from "../utils/DefaultProp"


interface SearchComponentProps<T extends SearchDataEntry> {
  param?: string,
  interceptRequest?: (data: never[]) => T[]
  url: string
  entries?: T[],
  items?: number,
  listComponent?: (data: T) => React.ReactNode,
  onListItemClicked?: (data: T) => void,
  placeholder?: string
}

export function SearchComponent<T extends SearchDataEntry>({
  param,
  url,
  interceptRequest,
  entries,
  items,
  listComponent,
  onListItemClicked,
  placeholder
}: SearchComponentProps<T>): React.ReactElement {
  const [resultOpen, setResultOpen] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string>("")
  const [searchResult, setSearchResult] = React.useState<T[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(1);

  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const loadingHeight = 70;

  const fetchData = (query: string, page: number, callback: (data: T[]) => void) => {
    setLoading(true)
    const key = param || "search"
    API.get(url, { params: { [key]: query, items: items || 10, page } }).then(({ data: { entries, pagination } }) => {
      let results = entries;
      if (interceptRequest) {
        results = interceptRequest(entries)
      }
      if (callback) {
        callback(results)
      }
      setPagination(pagination)

    }).finally(() => {
      setLoading(false)
    })
  }

  const debouncedSearchQuery = React.useCallback(debounce((query, page, callback: (data: T[]) => void) => {
    fetchData(query, page, callback)
  }, 500), [])

  // load data if entries given from the props
  React.useEffect(() => {
    setSearchResult(entries)
  }, [entries])


  // Load data when keyword is changed
  React.useEffect(() => {
    setPage(1)
    if (!searchText || !resultOpen) return
    debouncedSearchQuery(searchText, page, results => setSearchResult(results))
  }, [searchText])

  // Load for pagination
  React.useEffect(() => {
    if (page == pagination.currentPage ) return
    debouncedSearchQuery(searchText, page, results => setSearchResult(current => current.concat(results)))
  }, [page])


  const paginationHandler = ({ scrollTop, scrollHeight, clientHeight }) => {
    if ((scrollTop + clientHeight + loadingHeight) >= scrollHeight && pagination.nextPage) {
      setPage(pagination.nextPage)
    }
  }

  return(
    <div className="inline-block relative w-full" onBlur={() => setResultOpen(false)}>
      <input type="text"
        value={searchText}
        className="border-indigo-400 border-2 p-2 rounded focus:outline-none focus:shadow-md focus:border-indigo-400 w-full"
        placeholder={placeholder || "Type to search..."}
        onFocus={() => setResultOpen(true)}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className={`${resultOpen || loading ? "h-auto" : "h-0"}
          max-h-96 overflow-y-scroll transition-all absolute bg-white shadow-md rounded overflow-hidden w-full z-10`}
          onScroll={(e) => paginationHandler(e.currentTarget)}
        >
        {isEmpty(searchResult) && !loading && searchText ? <div className="p-3 hover:bg-gray-200 cursor-pointe">No result found...</div> : null }
        {isEmpty(searchResult) && !loading && !searchText ? <div className="p-3 hover:bg-gray-200 cursor-pointe">{placeholder || "Type to search..."}</div> : null }

        {
          (searchResult || []).map((item, index) => (
            <div key={index} className="p-3 hover:bg-gray-200 cursor-pointer"
              onMouseDown={() => {
                setSearchText(item.label)
                onListItemClicked && onListItemClicked(item)
              }}
            >
              {listComponent ? listComponent(item) : item.label}
            </div>
          ))
        }

        {loading ? <div className="p-3 hover:bg-gray-200 cursor-pointe">Loading...</div> : null}
      </div>
    </div>
  )
}

