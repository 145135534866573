import * as React from "react"
import { Link  } from "react-router-dom"

interface MenuItemProps {
  link?: string
  icon: React.ReactElement
  label: string
  overridesClassContainer?: string
  linkClassOverride?: string
  active: boolean
}

export const MenuItem: React.FC<MenuItemProps> = ({ link, icon, label, overridesClassContainer, linkClassOverride, active }: MenuItemProps) => {
  return(
    <div className={`grid grid-cols-12 content-center
          my-2 py-3 rounded-md ${overridesClassContainer}
          ${active ? "bg-gray-900" : ""} cursor-pointer`}>
      <div className="w-4 mr-2 flex content-center col-start-2">
        {icon}
      </div>
      {link ? <Link className={`col-start-4 col-span-8 text-lg
          font-semibold text-white ${linkClassOverride}
          ${active ? " heading-6" : ""}`}
          to={link}>{label}</Link>
          :
          <div className={`col-start-4 col-span-8 text-lg font-semibold text-white ${linkClassOverride}`}>{label}</div>}
    </div>
  )
}
