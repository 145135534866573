export interface ProductPurchaseOrderItem {
  id: number
  recipeId: number
  productPurchaseOrderId: number
  depreciation: number
  pulled: number
  realWeight: number
  materialId: number
  createdAt: string
  updatedAt: string
}

export interface ProductPurchaseOrderItemInput {
  id?: number
  recipeId: number
  depreciation: string
  realWeight: string
  pulled: string
  materialId?: number
}

export interface ProductPurchaseOrderSummary {
  total: number,
  arrivingIn: number,
  arrivingOut: number,
  servicingIn: number,
  servicingOut: number,
  platingIn: number,
  platingOut: number,
  qcHouseIn: number,
  qcHouseOut: number,
  inStockIn: number,
  inStockOut: number
}

export const productPOItemToInput = ({id, recipeId, realWeight, pulled, depreciation, materialId}: ProductPurchaseOrderItem): ProductPurchaseOrderItemInput => {
  return {
    id,
    recipeId,
    realWeight: realWeight.toString(),
    pulled: pulled.toString(),
    depreciation: depreciation.toString(),
    materialId
  }
}
