import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ProductPurchaseOrder } from "../domain_data/product_purchase_order";
import { MainLayout } from "../layouts/Main";
import { API, moneyFormatter, numberFormatter } from "../utils";
import { Link } from "react-router-dom"
import * as dayjs from "dayjs";
import ContentLoader from "react-content-loader"
import checklist from "../../images/Checklist.jpg"
import { FormUnderline } from "../components/forms/FormUnderline";
import { Material } from "../domain_data/material";
import { MainContext } from "../context/MainContext";

export const HomePage: React.FC = () => {
  return (
    <MainLayout>
      <h1 className="font-bold text-2xl text-gray-700 mb-3">Home Page Shortcuts</h1>
      <LowStockMaterials />
      <FormUnderline />
      <IncomingPurchaseOrders />

      <FormUnderline />
      <IncominJobOrders />
    </MainLayout>
  )
}


const IncomingPurchaseOrders: React.FC = () => {
  const [purchaseOrders, setPurchaseOrders] = useState<ProductPurchaseOrder[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    API.get("/api/supplier_purchase_orders/incoming", {params: {items: 5, page: 1}}).then(({ data: {entries} }) => {
      setPurchaseOrders(entries)
      if (entries.length == 0) {
        setIsEmpty(true)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div>
      <div className="bg-white px-10 pb-10 pt-5 p-1.5 rounded shadow-md">
        <h1 className="text-xl font-bold text-gray-600 mb-2">Incoming Purchase Orders</h1>
        <MainContent isEmpty={isEmpty} isLoading={loading} text="There is no incoming purchase order">
          <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
                <th className="text-center py-3 px-6">PO Number</th>
                <th className="text-center py-3 px-6">Supplier Name</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Issued Date</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Arival Date</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-lg font-light w-full">
              {
                purchaseOrders?.map(purchaseOrder => (
                  <tr key={purchaseOrder.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/supplier_purchase_orders/${purchaseOrder.id}`}>
                        <b>{`AS-PO-#${purchaseOrder.id}`}</b>
                      </Link>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/supplier_purchase_orders/${purchaseOrder.id}`}>{purchaseOrder?.supplier?.companyName}</Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {dayjs(purchaseOrder.createdAt).format('DD MMM YYYY')}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {dayjs(purchaseOrder.eta).format('DD MMM YYYY')}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </MainContent>
      </div>
    </div>
  )
}

const LowStockMaterials: React.FC = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const { setting } = useContext(MainContext)


  useEffect(() => {
    setIsLoading(true)

    API.get("/api/materials/low_stocks", { params: { items: 5, page: 1 } }).then(({ data: { entries } }) => {
      setMaterials(entries)
      if (entries.length == 0) {
        setIsEmpty(true)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  return(
    <div>
      <div className="bg-white px-10 pb-10 pt-5 p-1.5 rounded shadow-md">
        <h1 className="text-xl font-bold text-gray-600 mb-2">Material With Low Stock</h1>
        <MainContent isEmpty={isEmpty} isLoading={isLoading} text="Everything is on stock">
          <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
                <th className="text-center py-3 px-6">Code</th>
                <th className="text-center py-3 px-6">Name</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Weight</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Shape</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Size</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Stock</th>
                <th className="text-center py-3 px-6 md:table-cell hidden">Total Cost</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-lg font-light w-full">
              {
                materials?.map(material => (
                  <tr key={material.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/materials/${material.id}`}>{material.code}</Link>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/materials/${material.id}`}>{material.name}</Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {numberFormatter(material.weight)}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {material.shape}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {material.size}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {numberFormatter(material.stock)}
                    </td>
                    <td className="py-3 px-6 text-center md:table-cell hidden">{moneyFormatter(material.totalCost, setting?.currency?.code || "IDR")}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </MainContent>
      </div>
    </div>
  )
}

const IncominJobOrders: React.FC = () => {
  const [jobOrders, setJobOrders] = useState<ProductPurchaseOrder[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)

    API.get("/api/product_purchase_orders/incoming", { params: { items: 5, page: 1 } }).then(({ data: { entries } }) => {
      setJobOrders(entries)
      if (entries.length == 0) {
        setIsEmpty(true)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <div>
      <div className="bg-white px-10 pb-10 pt-5 p-1.5 rounded shadow-md">
        <h1 className="text-xl font-bold text-gray-600 mb-2">Incoming Job Orders</h1>
        <MainContent isEmpty={isEmpty} isLoading={isLoading} text="There is no incoming job order">
          <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
                <th className="text-center py-3 px-6">JO Number</th>
                <th className="text-center py-3 px-6">Supplier Name</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Issued Date</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Arival Date</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-lg font-light w-full">
              {
                jobOrders?.map(purchaseOrder => (
                  <tr key={purchaseOrder.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/job_orders/${purchaseOrder.id}`}>
                        <b>{`AS-JO-#${purchaseOrder.id}`}</b>
                      </Link>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/job_orders/${purchaseOrder.id}`}>{purchaseOrder?.supplier?.companyName}</Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {dayjs(purchaseOrder.createdAt).format('DD MMM YYYY')}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {dayjs(purchaseOrder.eta).format('DD MMM YYYY')}
                    </td>
                  </tr>
                ))

              }
            </tbody>
          </table>
        </MainContent>
      </div>
    </div>
  )
}

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="26" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="44" rx="3" ry="3" width="178" height="6" />
  </ContentLoader>
)

interface MainContentProps {
  children?: React.ReactNode,
  isLoading: boolean,
  isEmpty: boolean,
  text: string
}

const MainContent: React.FC<MainContentProps> = ({ children, isLoading, isEmpty, text }: MainContentProps) => {
  if (isEmpty) {
    return (
      <div className="flex flex-col items-center">
        <div className=" w-full sm:w-1/4">
          <img src={checklist} className="w-full h-auto" />
        </div>
        <h1>{text}</h1>
      </div>
    )
  }

  if (isLoading) {
    return <MyLoader />
  }

  return(
    <>{ children }</>
  )
}
