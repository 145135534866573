import { AxiosError, AxiosResponse } from "axios";
import * as React from "react";
import { useState } from "react";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { MainLayout } from "../../layouts/Main";
import { API, IDgenerator } from "../../utils";
import { BaseMaterialForm } from "./Form"
import { useHistory } from "react-router-dom"
import { BaseMaterialErrors, BaseMaterialInput } from "../../domain_data/base_material";

export const NewBaseMaterial: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<BaseMaterialErrors>({});
  const history = useHistory();


  const onSubmit = (data: BaseMaterialInput) => {
    setLoading(true);
    API.post("/api/base_materials", { base_material: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/base_materials/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/base_materials" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <BaseMaterialForm data={{
          name: "",
          price: "0",
          unit: "",
        }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        />

      </div>
    </MainLayout>
  )
}
