import * as React from "react"
import { useContext, useEffect, useState } from "react";
import { CurrencyErrors, CurrencyInput } from "../../domain_data/currency";
import { SettingLayout } from "../../layouts/Setting"
import { useHistory } from "react-router-dom"
import { DefaultParamProps } from "../../utils/DefaultProp";
import { API, IDgenerator } from "../../utils";
import { AxiosError, AxiosResponse } from "axios";
import { ItemNotFound } from "../ItemNotFound";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { CurrencyForm } from "./Form";
import { MainContext } from "../../context/MainContext";


export const CurrencyEdit: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<CurrencyErrors>({});
  const [currency, setCurrency] = useState<CurrencyInput>();
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)
  const { onSettingChanged } = useContext(MainContext)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/currencies/${id}`).then(({ data }) => {
      setCurrency(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const onSubmit = (data: CurrencyInput) => {
    setLoading(true);

    API.patch(`/api/currencies/${id}`, { currency: data }).then(({ data: { id, isDefault } }: AxiosResponse) => {
      if (isDefault != currency.isDefault) {
        onSettingChanged()
      }
      history.push(`/currencies/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (notFound) return <ItemNotFound />

  const formID = IDgenerator();
  return(
    <SettingLayout>
      { loading || !currency ? <LoadingRoller loading /> :
        <>
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/currencies" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <CurrencyForm data={currency} id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </SettingLayout>
  )
}
