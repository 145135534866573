export interface ImageAttachment {
  id: number
  name: string
  recordType: string
  recordId: number
  blobId: number
  createdAt: string
  url?: string
}

export interface FileBlob {
  attachable_sgid: string
  byte_size: number
  checksum: string
  content_type: string
  filename: string
  id: number
  service_name: string
  signed_id: string
  url?: string
}

export interface FileBlobNormalized {
  attachableSgid: string
  byteSize: number
  checksum: string
  contentType: string
  filename: string
  id: number
  serviceName: string
  signedId: string
  url?: string
}

export const serialzeFileBlob = ({
  attachableSgid,
  byteSize,
  checksum,
  contentType,
  filename,
  id,
  serviceName,
  signedId,
  url}: FileBlobNormalized): FileBlob => {
    return {
      attachable_sgid: attachableSgid,
      byte_size: byteSize,
      checksum: checksum,
      content_type: contentType,
      filename: filename,
      id: id,
      service_name: serviceName,
      signed_id: signedId,
      url: url,
    }
  }

export const normalizeFileBlob = ({
  attachable_sgid,
  byte_size,
  checksum,
  content_type,
  filename,
  id,
  service_name,
  signed_id,
  url}: FileBlob): FileBlobNormalized => {
    return {
      attachableSgid: attachable_sgid,
      byteSize: byte_size,
      checksum: checksum,
      contentType: content_type,
      filename: filename,
      id: id,
      serviceName: service_name,
      signedId: signed_id,
      url: url,
    }
  }
