import * as React from "react"
import { Product } from "../../domain_data/product";
import { MainLayout } from "../../layouts/Main"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory, Link } from "react-router-dom"
import { API, moneyFormatter, numberFormatter } from "../../utils";
import iziToast from "izitoast";
import { BackButton } from "../../components/BackButton";
import { LoadingRoller } from "../../components";
import { FormBlock } from "../../components/forms/FormBlock";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { Modal } from "../../components/Modal";
import { MultipleFileShow } from "../../components/inputs/MultipleFileShow";
import { AxiosResponse } from "axios";
import { useState, useEffect, useContext } from "react";
import { ProductVariant } from "../../domain_data/product_variant";
import { Recipe } from "../../domain_data/recipe"
import { ItemNotFound } from "../ItemNotFound";
import { MainContext } from "../../context/MainContext";
import { Currency } from "../../domain_data/currency";
import { ServiceFee } from "../../domain_data/service_fee";
import { Material } from "../../domain_data/material";
import { Supplier } from "../../domain_data/supplier";


export const ShowProduct: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDeleting, setIsdeleting] = React.useState<boolean>(false);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<Product>()
  const [category, setCategory] = React.useState<string>("Loading...");
  const history = useHistory();
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])
  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [recipeCost, setRecipeCost] = useState<number>(0)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [serviceFees, setServiceFees] = useState<ServiceFee[]>([])
  const [serviceFeeCost, setServiceFeeCost] = useState<number>(0)

  const loadServiceFee = () => {
    API.get(`/api/products/${id}/assembly_fees`).then(({ data }) => {
      setServiceFees(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch product assembly fee" })
    }).finally(() => {
      setLoading(false)
    })
  }


  const loadRecipes = () => {
    API.get(`/api/products/${id}/recipes?items=100`).then(({ data: { entries } }) => {
      setRecipes(entries)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch product recipes" })
    }).finally(() => {
      loadServiceFee()
    })
  }

  const loadProductVaraint = () => {
    API.get(`/api/products/${id}/product_variants?items=100`).then(({ data: { entries } }) => {
      if (!entries) return;
      setProductVariants(entries)
    }).catch(() => {
      iziToast.error({
        title: "Error",
        message: "Failed to fetch product variants"
      })
    }).finally(() => {
      loadRecipes()
    })
  }

  const loadProduct = () => {
    API.get(`/api/products/${id}`).then(({ data }) => {
      setProduct(data)
      loadProductVaraint()
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  React.useEffect(() => {
    if (!product?.productCategoryId) {
      setCategory("None")
      return;
    } else {
      setCategory("Loading...")
      API.get(`/api/product_categories/${product?.productCategoryId}`).then(({ data: { name } }: AxiosResponse) => {
        setCategory(name)
      }).catch(() => {
        setCategory("None")
      })
    }
  }, [product])

  const handleDelete = () => {
    setIsdeleting(true)
    API.delete(`/api/products/${id}`).then(() => {
      history.push("/products")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this product" })
    }).finally(() => {
      setIsdeleting(false)
    })
  }

  React.useEffect(() => {
    setLoading(true)
    loadProduct()
  }, [])

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  const totalCost = (): number => {
    return serviceFeeCost + recipeCost
  }


  if (notFound) return <ItemNotFound/>

  return(
    <MainLayout>
      { loading || !product || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/products" />
            <div>
              <a target="_blank" rel="noreferrer" className="dark-button-with-text mr-2" href={`/utilities/products/${id}.pdf`}>
                <i className="uil uil-download-alt text-lg"></i>
                <span className="ml-2">Save PDF</span>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ padding: "10px 20px" }}
                className="mr-2 bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
                href={`/utilities/products/${id}.xlsx`}>
                <i className="uil uil-download-alt text-lg"></i>
                <span className="ml-2">Save Excel</span>
              </a>
              <Link className="dark-button-with-text" to={`/products/${id}/edit`}>
                <i className="uil uil-edit-alt text-lg"></i>
                <span className="ml-2">Edit</span>
              </Link>
            </div>
          </div>
          <div>
            <FormBlock heading="Product" description="This is the general information of the product.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Product Name" value={product.name} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="SKU" value={product.sku} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Category" value={category} />
                </div>
              </div>
            </FormBlock>
            <FormUnderline />
            <FormBlock heading="Sample Images" description="This is the product sample images.">
              <div className="grid grid-cols-1 gap-6">
                {product.images ? <MultipleFileShow files={product.images} />
                  :
                  <div className="">
                    <i className="uil uil-image-alt-slash text-7xl text-gray-400"></i>
                    <p className="text-gray-700">No image provided</p>
                  </div>
                }
              </div>
            </FormBlock>

            <FormUnderline />
            <FormBlock heading="Product Varaints" description="This is the different varants of the product.">
              <div className="grid grid-cols-1 gap-6">
                {productVariants.length > 0 ?

                  productVariants.map((item, key) => (
                    <div key={key} className="grid grid-cols-12 gap-6">
                      { item.image ?
                        <div className="col-span-4 md:col-span-2 h-24 overflow-hidden rounded-md">
                          <img className="w-full h-auto" src={item.image.url}/>
                        </div>
                        :
                        <i className="uil uil-image-alt-slash text-7xl text-gray-400"></i> }
                      <div className="mt-1 flex rounded-md shadow-sm  self-center">{item.name}</div>
                    </div>
                  ))
                  :
                  <p className="text-gray-700">No variants for this product</p>
                }
              </div>
            </FormBlock>

            <FormUnderline />

            <FormBlock heading="Recipes" description="This is the materials required to crearte this product.">
              <div className="grid grid-cols-1 gap-6">
                {recipes.length > 0 ?

                  recipes.map((item, key) => (
                    <RecipeItem key={key} recipe={item} onChange={(cost) => setRecipeCost(current => current + cost)} currency={setting?.currency}/>
                  ))
                  :
                  <p className="text-gray-700">No varants for this product</p>
                }
              </div>
              <>
                <div className="block">
                  <div className="border-t border-gray-200" />
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-2 font-bold">Total Material Cost</div>
                  <p className="font-bold">{moneyFormatter(recipeCost, setting?.currency?.code)}</p>
                </div>
              </>
            </FormBlock>
            <FormUnderline />

            { serviceFees.length > 0 &&
             <>
              <FormBlock heading="Assemby Fees" description="This is the additional assembing fee.">
                <div className="grid grid-cols-1 gap-6">
                  {serviceFees.map((service, key) => <ServiceFeeItem key={key} service={service} onLoad={(data) => setServiceFeeCost(c => c + data)}/>) }
                </div>
                <>
                  <div className="block">
                    <div className="border-t border-gray-200" />
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 font-bold">Total Assembling Cost</div>
                    <p className="font-bold">{moneyFormatter(serviceFeeCost, setting?.currency?.code)}</p>
                  </div>
                </>
              </FormBlock>
              <FormUnderline />
             </>
            }

            {totalCost() > 0 &&
              <>
              <FormBlock heading="Total Cost" description="This is the total cost of this product.">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-2 font-bold">Costing Sheet</div>
                  <p className="font-bold">{moneyFormatter(totalCost(), setting?.currency?.code)}</p>
                </div>
              </FormBlock>
              <FormUnderline />
              </>
            }

            <Modal
              onDeclined={() => setModalOpen(false)}
              onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this product?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />
            <div className="inline-block mr-3">
              <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
                <div className="w-5 inline-block">
                  <i className="uil uil-trash text-white"></i>
                </div>
                <p className="text-white font-bold ml-2">Delete</p>
              </button>
            </div>
          </div>
        </div>
      }
    </MainLayout>
  )
}

interface RecipeItemProps {
  recipe: Recipe
  onChange: (cost: number) => void
  currency: Currency
}

const RecipeItem: React.FC<RecipeItemProps> = ({ recipe, onChange, currency }: RecipeItemProps) => {
  const [materialName, setMaterialName] = useState<string>("")
  const [price, setPrice] = useState<number>(0)
  const [totalCost, setTotalCost] = useState<number>(0)
  const [unit, setUnit] = useState<string>("Unit(s)")
  const [material, setMaterial] = useState<Material>(null)
  const [supplier, setSupplier] = useState<Supplier>(null)

  useEffect(() => {
    if (!material || !material?.supplierId) {
      setSupplier(null)
      return
    }

    API.get(`/api/suppliers/${material?.supplierId}`).then(({ data }) => {
      setSupplier(data)
    })

  }, [material])

  useEffect(() => {
    API.get(`/api/materials/${recipe.materialId}`).then(({ data }) => {
      setMaterial(data)
      const { totalCost: price, name, unit, code, status } = data

      setMaterialName(`${code} | ${name}${status == 'discontinued' ? ' (DISCONTINUED)' : ''}`)
      setPrice(price)
      const totalCost = price * recipe.quantity
      onChange(totalCost)
      setTotalCost(totalCost)
      setUnit(unit)

    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch material" })
    })
  }, [])
  return(
    <div className="grid grid-cols-10 gap-6">
      <div className="col-span-5 xl:col-span-2">
        <TextInputShow label="Material">
          <Link to={`/materials/${recipe.materialId}`}>{materialName}</Link>
        </TextInputShow>
      </div>
      <div className="col-span-5 xl:col-span-2">
        { !supplier ?
          <TextInputShow label="Supplier" value="None" />
          :
          <TextInputShow label="Supplier" value="None">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.companyName}</Link>
          </TextInputShow>
        }
      </div>
      <div className="col-span-5 xl:col-span-2">
        <TextInputShow label="Quantity" value={`${numberFormatter(recipe.quantity)} ${unit}`} />
      </div>
      <div className="col-span-5 xl:col-span-2">
        <TextInputShow label="Cost" value={`${moneyFormatter(price, currency?.code)}/${unit}`} />
      </div>
      <div className="col-span-5 xl:col-span-2">
        <TextInputShow label="Total" value={`${moneyFormatter(totalCost, currency?.code)}`} />
      </div>
      <div className="xl:hidden block col-span-12">
        <div className="border-b" />
      </div>
    </div>
  )
}


interface ServiceFeeItemProps {
  service: ServiceFee
  onLoad: (cost: number) => void
}

const ServiceFeeItem: React.FC<ServiceFeeItemProps> = ({ service, onLoad}: ServiceFeeItemProps) => {
  const { setting } = useContext(MainContext)

  useEffect(() => {
    onLoad(service.cost * service.quantity)
  }, [])

  return(
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Service Name" value={service.name} />
      </div>
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Cost" value={moneyFormatter(service.cost, setting?.currency?.code)} />
      </div>
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Quantity" value={numberFormatter(service.quantity)} />
      </div>
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Total" value={`${moneyFormatter(service.cost * service.quantity, setting?.currency?.code)}`} />
      </div>
      <div className="lg:hidden block col-span-12">
        <div className="border-b" />
      </div>
    </div>
  )
}
