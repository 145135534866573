import * as React from "react";
import { Pagination } from "../domain_data/pagination";
import { range, max, min } from "lodash"
import arrowLeft from "../../images/slim-left-arrow.svg"
import arrowLeftDouble from "../../images/slim-left-arrow-double.svg"
import arrowRightDouble from "../../images/slim-right-arrow-double.svg"
import arrowRight from "../../images/slim-right-arrow.svg"

interface PaginationViewProps extends Pagination {
  onNextClicked: () => void
  onPrevClicked: () => void
  onPageClicked?: (page: number) => void
  loading?: boolean
}

export const PaginationView: React.FC<PaginationViewProps> = ({
  currentPage,
  totalPages,
  previousPage,
  nextPage,
  onNextClicked,
  onPrevClicked,
  onPageClicked,
  loading
}: PaginationViewProps) => {
  const padding = 2;

  const nexts = () => {
    if (currentPage == totalPages)  return []

    return range(min([currentPage + 1], totalPages), min([currentPage + padding + 1, totalPages + 1]))
  }

  const prevs = range(max([currentPage - padding, 1]), max([currentPage, 1]))

  return(
    <div>
      <button className="p-1 disabled:opacity-50 w-6 align-middle" disabled={!!loading || !previousPage} onClick={() => onPageClicked && onPageClicked(1)}>
        <img className="w-full h-auto" src={arrowLeftDouble} />
      </button>
      <button className="p-1 disabled:opacity-50 w-6 align-middle" disabled={!!loading || !previousPage} onClick={onPrevClicked}>
        <img className="w-full h-auto" src={arrowLeft}/>
      </button>

      { prevs.map((item: number, index: number) => (
        <button onClick={() => onPageClicked && onPageClicked(item)} disabled={!!loading}
          className="px-2 py-1 hover:bg-indigo-500 hover:text-white transition-all rounded focus:border-transparent"
          key={index}>
            {item}
          </button>)
      )}

      <span className="mx-3 bg-indigo-500 text-white px-2 py-1 rounded">{currentPage}</span>

      { nexts().map((item: number, index: number) => (
        <button onClick={() => onPageClicked && onPageClicked(item)} disabled={!!loading}
          className="px-2 py-1 hover:bg-indigo-500 hover:text-white transition-all rounded focus:border-transparent"
          key={index}>
            {item}
        </button>)
      )}

      <button className="p-1 disabled:opacity-50 w-6 align-middle" disabled={!!loading || !nextPage} onClick={onNextClicked}>
        <img className="w-full h-auto" src={arrowRight} />
      </button>

      <button className="p-1 disabled:opacity-50 w-6 align-middle" disabled={!!loading || !nextPage} onClick={() => onPageClicked && onPageClicked(totalPages)}>
        <img className="w-full h-auto" src={arrowRightDouble} />
      </button>
    </div>
  )
}
