import * as React from "react";
import { IDgenerator } from "../../utils";

export interface TextInputProps {
  error?: string[]
  value: string
  type?: string
  label: string
  name?: string
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  setValue: (value: string) => void
  required?: boolean
  className?: string
}

export const TextInput: React.FC<TextInputProps> = ({ error, value, setValue, type, label, name, placeholder, disabled, required }: TextInputProps) => {

  const id = name ? name : IDgenerator();
  return(
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
          type={type ? type : "text"}
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          disabled={!!disabled}
          onChange={(e) => setValue(e.target.value)}
          required={!!required}
        />
      </div>
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
    </>
  )
}
