import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { RecipeWithMaterial } from "../../domain_data/recipe"
import { SupplierPOArrivalErrors, SupplierPOArrivalInput } from "../../domain_data/supplier_po_arrival"
import DatePicker from "react-datepicker"
import { NumberInput } from "../../components/inputs/NumberInput"
import { Textarea } from "../../components/inputs/Textarea"
import { normalizeNumber } from "../../utils"


interface POArrivalFormProps {
  data: SupplierPOArrivalInput,
  errors: SupplierPOArrivalErrors,
  id: string,
  purchaseOrderId: string,
  onSubmit: (data: SupplierPOArrivalInput) => void
}

export const POArrivalForm: React.FC<POArrivalFormProps> = ({ data, errors, id, onSubmit, purchaseOrderId }: POArrivalFormProps) => {
  const [dataIn, setDataIn] = useState<Date>(data.dataIn)
  const [note, setNote] = useState<string>(data.note)
  const [quantity, setQuantity] = useState<string>(data.quantity)
  const [weight, setWeight] = useState<string>(data.weight)
  const [supplierPoItemId, setSupplierPoItemId] = useState<number>(data.supplierPoItemId)

  return(
    <form
      id={id}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit({
          dataIn,
          note,
          quantity: normalizeNumber(quantity).toString(),
          weight: normalizeNumber(weight).toString(),
          supplierPoItemId,
        })
      }}
    >
      <FormBlock heading="General Information" description="General information about material arrival.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="Material"
              defaultValue={supplierPoItemId}
              error={errors?.supplierPoItem}
              name="material"
              url={`/api/supplier_purchase_orders/${purchaseOrderId}/supplier_po_items/materials`}
              serializeItem={(item: RecipeWithMaterial) => ({ label: `${item.material.code} | ${item.material.name}`, ...item })}
              onListItemClicked={item => setSupplierPoItemId(item?.id)}
              placeholder="Select Material"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor={id} className="block text-sm font-medium text-gray-700 ">
              Arrival Date
            </label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              selected={dataIn}
              onChange={date => setDataIn(date)}
              className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <NumberInput label="Quantity" value={quantity} setValue={setQuantity} error={errors?.quantity} />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <NumberInput label="Weight" value={weight} setValue={setWeight} error={errors?.weight} />
          </div>
          <div className="col-span-6">
            <Textarea label="Note" value={note} setValue={setNote} error={errors?.note} />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}
