import * as React from "react"
import { BackButton } from "../components/BackButton"
import { MainLayout } from "../layouts/Main"
import image from "../../images/item-404.jpg"

export const ItemNotFound: React.FC = () => {
  return(
    <MainLayout>
      <div className="flex justify-between py-5 items-center">
        <BackButton />
      </div>
      <div className="w-full">
        <div className="bg-white rounded-md shadow-md p-5 grid grid-cols-6">
          <div className="col-span-6 sm:col-span-2 self-center text-center sm:text-right">
            <h1 className="text-8xl font-extrabold text-indigo-500">404</h1>
            <p>We can&apos;t find the item you&apos;re looking for</p>
          </div>
          <div className="col-span-6 sm:col-span-4 text-center sm:text-left">
            <img src={image} className="w-8/12 h-auto inline-block"/>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
