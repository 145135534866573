import * as React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { MainContextProvider } from "./context/MainContext";
import { NotFoundPage } from "./pages/404";
import { BaseMaterialList, BaseMaterialShow, EditBaseMaterial, NewBaseMaterial } from "./pages/base_materials";
import { CurrencyEdit, CurrencyList, NewCurrency, ShowCurrency } from "./pages/currencies";
import { HomePage } from "./pages/HomePage"
import { EditJobOrderGroup, JobOrderGropList, NewJobOrderGroup } from "./pages/job_order_group";
import { ImportMaterial, MaterialEdit, MaterialList, MaterialShow, NewMaterial } from "./pages/materials";
import { MaterialAdjustmentList, MaterialAdjustmentShow, NewMaterialAdjustment } from "./pages/material_adjustments";
import { MaterialCategoryList, MaterialCategoryShow, NewMaterialCategory, MaterialCategoryEdit } from "./pages/material_categories";
import { EditOffice, NewOffice, OfficeList, ShowOffice } from "./pages/offices";
import { EditPOArrival, NewPOArrival } from "./pages/po_arrivals";
import { EditProductionReport, NewProductionReport, ProductionReportList, ShowProductionReport } from "./pages/production_reports";
import { NewProduct, ProductEdit, ProductList, ShowProduct } from "./pages/products";
import { NewProductCategory, ProductCategoryEdit, ProductCategoryList, ProductCategoryShow } from "./pages/product_categories";
import { EditProductPO, NewProductPO, ProductPOList, ShowProductPO } from "./pages/product_pos";
import { EditProductPOReturn, NewProductPOReturn } from "./pages/product_po_returns";
import { ImportSupplier, NewSupplier, SupplierEdit, SupplierList, SupplierShow } from "./pages/suppliers";
import { NewSupplierPO, ShowSupplierPro, SupplierPoEdit, SupplierPOList } from "./pages/supplier_pos";
import { ShowTax, TaxEdit, TaxList, NewTax } from "./pages/taxes";
import { NewUser, UserList, UserShow } from "./pages/users";
import { UserEdit } from "./pages/users/Edit";
import { MaterialBalance } from "./pages/reports/MaterialBalance";
import { SupplierReport } from "./pages/reports/SupplierReport";
import { SupplierReportDetail } from "./pages/reports/SupplierReportDetail";
import { JobOrderReport } from "./pages/reports/JobOrderReport";
import { PurchaseOrderReport } from "./pages/reports/PurchaseOrderReport";
import { SupplierJobOrderReport } from "./pages/reports/SupplierJobOrderReport";
import { SupplierJobOrderReportDetail } from "./pages/reports/SupplierJobOrderReportDetail";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <MainContextProvider>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/suppliers" component={SupplierList} />
          <Route exact path="/suppliers/import" component={ImportSupplier} />
          <Route exact path="/suppliers/new" component={NewSupplier} />
          <Route exact path="/suppliers/:id" component={SupplierShow} />
          <Route exact path="/suppliers/:id/edit" component={SupplierEdit} />
          <Route exact path="/material_categories" component={MaterialCategoryList} />
          <Route exact path="/material_categories/new" component={NewMaterialCategory} />
          <Route exact path="/material_categories/:id" component={MaterialCategoryShow} />
          <Route exact path="/material_categories/:id/edit" component={MaterialCategoryEdit} />
          <Route exact path="/materials" component={MaterialList} />
          <Route exact path="/materials/new" component={NewMaterial} />
          <Route exact path="/materials/import" component={ImportMaterial} />
          <Route exact path="/materials/:id" component={MaterialShow} />
          <Route exact path="/materials/:id/edit" component={MaterialEdit} />
          <Route exact path="/materials/:materialId/adjustments" component={MaterialAdjustmentList} />
          <Route exact path="/materials/:materialId/adjustments/new" component={NewMaterialAdjustment} />
          <Route exact path="/materials/:materialId/adjustments/:id" component={MaterialAdjustmentShow} />
          <Route exact path="/base_materials" component={BaseMaterialList} />
          <Route exact path="/base_materials/new" component={NewBaseMaterial} />
          <Route exact path="/base_materials/:id" component={BaseMaterialShow} />
          <Route exact path="/base_materials/:id/edit" component={EditBaseMaterial} />
          <Route exact path="/product_categories" component={ProductCategoryList} />
          <Route exact path="/product_categories/new" component={NewProductCategory} />
          <Route exact path="/product_categories/:id" component={ProductCategoryShow} />
          <Route exact path="/product_categories/:id/edit" component={ProductCategoryEdit} />
          <Route exact path="/products" component={ProductList} />
          <Route exact path="/products/new" component={NewProduct} />
          <Route exact path="/products/:id" component={ShowProduct} />
          <Route exact path="/products/:id/edit" component={ProductEdit} />
          <Route exact path="/currencies" component={CurrencyList} />
          <Route exact path="/currencies/new" component={NewCurrency} />
          <Route exact path="/currencies/:id" component={ShowCurrency} />
          <Route exact path="/currencies/:id/edit" component={CurrencyEdit} />
          <Route exact path="/taxes" component={TaxList} />
          <Route exact path="/taxes/new" component={NewTax} />
          <Route exact path="/taxes/:id" component={ShowTax} />
          <Route exact path="/taxes/:id/edit" component={TaxEdit} />
          <Route exact path="/supplier_purchase_orders" component={SupplierPOList} />
          <Route exact path="/supplier_purchase_orders/new" component={NewSupplierPO} />
          <Route exact path="/supplier_purchase_orders/:id" component={ShowSupplierPro} />
          <Route exact path="/supplier_purchase_orders/:id/edit" component={SupplierPoEdit} />
          <Route exact path="/supplier_purchase_orders/:purchaseOrderId/arrivals/new" component={NewPOArrival} />
          <Route exact path="/supplier_purchase_orders/:purchaseOrderId/arrivals/:id" component={EditPOArrival} />
          <Route exact path="/job_order_groups" component={JobOrderGropList} />
          <Route exact path="/job_order_groups/new" component={NewJobOrderGroup} />
          <Route exact path="/job_order_groups/:id/edit" component={EditJobOrderGroup} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders" component={ProductPOList} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders/new" component={NewProductPO} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders/:id" component={ShowProductPO} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders/:id/edit" component={EditProductPO} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders/:jobOrderId/returns/new" component={NewProductPOReturn} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders/:jobOrderId/returns/new/:stage" component={NewProductPOReturn} />
          <Route exact path="/job_order_groups/:jobOrderGroupId/job_orders/:jobOrderId/returns/:id/edit" component={EditProductPOReturn} />
          <Route exact path="/production_reports" component={ProductionReportList} />
          <Route exact path="/production_reports/new" component={NewProductionReport} />
          <Route exact path="/production_reports/:id" component={ShowProductionReport} />
          <Route exact path="/production_reports/:id/edit" component={EditProductionReport} />
          <Route exact path="/users" component={UserList} />
          <Route exact path="/users/new" component={NewUser} />
          <Route exact path="/users/:id" component={UserShow} />
          <Route exact path="/users/:id/edit" component={UserEdit} />
          <Route exact path="/offices" component={OfficeList} />
          <Route exact path="/offices/new" component={NewOffice} />
          <Route exact path="/offices/:id" component={ShowOffice} />
          <Route exact path="/offices/:id/edit" component={EditOffice} />
          <Route exact path="/material_balance" component={MaterialBalance} />
          <Route exact path="/supplier_reports" component={SupplierReport} />
          <Route exact path="/supplier_job_order_reports" component={SupplierJobOrderReport} />
          <Route exact path="/supplier_job_order_reports/:id" component={SupplierJobOrderReportDetail} />
          <Route exact path="/supplier_reports/:id" component={SupplierReportDetail} />
          <Route exact path="/job_order_reports" component={JobOrderReport} />
          <Route exact path="/purchase_order_reports" component={PurchaseOrderReport} />

          <Route component={NotFoundPage} />
        </Switch>
      </MainContextProvider>
    </BrowserRouter>
  )
}
