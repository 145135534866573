import iziToast from "izitoast"
import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../context/MainContext"
import { SupplierPurchaseOrderErrors, SupplierPurchaseOrderInput } from "../../domain_data/supplier_purchase_order"
import { SupplierPurchaseOrderItemInput } from "../../domain_data/supplier_purchase_order_item"
import { useHistory } from "react-router-dom"
import { FormBlock } from "../../components/forms/FormBlock"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { Supplier } from "../../domain_data/supplier"
import { Currency } from "../../domain_data/currency"
import DatePicker from "react-datepicker"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { Material } from "../../domain_data/material"
import { moneyFormatter, normalizeNumber } from "../../utils"
import { NumberInput } from "../../components/inputs/NumberInput"
import { TextInput } from "../../components/inputs/TextInput"
import { Office } from "../../domain_data/office"

export interface SupplierPOInputProps {
  po: SupplierPurchaseOrderInput,
  orderItems: SupplierPurchaseOrderItemInput[],
}

interface SupplierPOFormProps {
  id: string,
  errors: SupplierPurchaseOrderErrors,
  data: SupplierPOInputProps
  onSubmit?: (data: SupplierPOInputProps) => void
}

export const SupplierPOForm: React.FC<SupplierPOFormProps> = ({ id, data, onSubmit, errors }: SupplierPOFormProps) => {
  const [eta, setEta] = useState<Date>(data.po.eta)
  const [orderDate, setOrderDate] = useState<Date>(data.po.orderDate)
  const [supplierId, setSupplierId] = useState<number>(data.po.supplierId)
  const [currencyId, setCurrencyId] = useState<number>(data.po?.currencyId)
  const [currency, setCurrency] = useState<Currency>(null)
  const [orderItems, setOrderItems] = useState<SupplierPurchaseOrderItemInput[]>(data.orderItems)
  const [totalMaterialCost, setTotalMaterialCost] = useState<number>(0)
  const [orderFor, setOrderFor] = useState<string>(data.po.orderFor || "")
  const [orderNumber, setOrderNumber] = useState<string>(data.po.orderNumber || "")
  const [officeId, setOfficeId] = useState<number>(data.po.officeId)

  const history = useHistory();

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  const totalMaterialCostWithCurrency = (): number => {
    if (currency?.id == setting?.currency.id) return totalMaterialCost
    return (totalMaterialCost * (currency?.rate || 1)) / (setting?.currency.rate || 1)
  }

  return (
    <form id={id}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit({
          po: {
            eta,
            currencyId,
            supplierId,
            orderFor,
            orderNumber,
            officeId,
            orderDate,
          },
          orderItems: orderItems,
        })
      }}
    >
      <FormBlock heading="General Information" description="This is the general PO information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput label="Purchase Order Number" value={orderNumber} setValue={setOrderNumber} />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="BILL TO"
              defaultValue={officeId}
              error={errors?.office}
              name="offices"
              url="/api/offices"
              serializeItem={(item: Office) => ({ label: item.name, ...item })}
              onListItemClicked={item => setOfficeId(item?.id)}
              placeholder="Select Billing Address"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="Supplier"
              defaultValue={supplierId}
              error={errors?.supplierId}
              name="supplier"
              url="/api/suppliers"
              serializeItem={(item: Supplier) => ({ label: item.companyName, ...item })}
              onListItemClicked={item => setSupplierId(item?.id)}
              placeholder="Select Supplier"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="Currency"
              defaultValue={currencyId}
              error={errors?.currency}
              name="currency"
              url="/api/currencies"
              serializeItem={(item: Currency) => ({ label: item.code, ...item })}
              onListItemClicked={item => {
                setCurrencyId(item?.id)
                setCurrency(item)
              }}
              onLoad={item => setCurrency(item)}
              placeholder="Select Currency"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor={id} className="block text-sm font-medium text-gray-700 ">
              Order Date
            </label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              selected={orderDate}
              onChange={date => setOrderDate(date)}
              className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor={id} className="block text-sm font-medium text-gray-700 ">
              Arrival Date
            </label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              selected={eta}
              onChange={date => setEta(date)}
              className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput label="Order For" value={orderFor} setValue={setOrderFor} />
          </div>
        </div>
      </FormBlock>
      <FormUnderline />
      <FormBlock heading="Materials" description="This is the list of material needed.">
        <div className="grid grid-cols-6 gap-6">
          {orderItems.map((item, key) => (
            <OrderItemInput
              key={key}
              item={item}
              currency={currency}
              onChange={(price) => setTotalMaterialCost(c => c + price)}
            />
          ))}
        </div>
        <button type="button" className="rounded-md bg-indigo-500 text-white px-3 py-2"
          onClick={() => setOrderItems(c => c.concat({ quantity: "0", materialId: null, _destroy: false }))}
        >
          <i className="uil uil-plus "></i>
          <span className="ml-2">Add Item</span>
        </button>
        {totalMaterialCost > 0 &&
          <>
            <div className="block">
              <div className="border-t border-gray-200" />
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-2 font-bold">Total</div>
              <p className="font-bold">{moneyFormatter(totalMaterialCostWithCurrency(), currency?.code)}</p>
            </div>
          </>
        }
      </FormBlock>
    </form>
  )
}



interface OrderItemInputProps {
  item: SupplierPurchaseOrderItemInput,
  onChange?: (data: number) => void,
  currency: Currency
}
const OrderItemInput: React.FC<OrderItemInputProps> = ({ item, onChange, currency }: OrderItemInputProps) => {

  const { setting } = useContext(MainContext)

  const [material, setMaterial] = useState<Material>(null)

  const [materialId, setMaterialId] = useState<number>(item.materialId)
  const [quantity, setQuantity] = useState<string>(item.quantity)
  const [deleted, setDeleted] = useState<boolean>(item._destroy)

  const [totalCost, setTotalCost] = useState<number>(0)

  useEffect(() => {
    const costNow = normalizeNumber(quantity) * (material?.realPrice || 0)
    if (totalCost == 0) {
      onChange && onChange(costNow)
    } else {
      onChange && onChange(costNow - totalCost)
    }


    setTotalCost(costNow)

    item.quantity = quantity

  }, [quantity, material])

  const handleDelete = () => {
    item._destroy = true
    setDeleted(true)
    setTotalCost(-totalCost)
  }

  if (deleted) return null;

  const costPerUnit = (): number => {
    if (currency?.id == setting?.currency.id) return (material?.realPrice || 0)

    return ((material?.realPrice || 0) * (currency?.rate || 1)) / (setting?.currency.rate || 1)
  }

  const totalMaterialCost = (): number => {
    if (currency?.id == setting?.currency.id) return (totalCost || 0)
    return ((totalCost || 0) * currency?.rate) / (setting?.currency.rate || 1)
  }

  return (
    <div className="col-span-6 grid grid-cols-12 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <SingleAjaxSelect
          label="Material"
          defaultValue={materialId}
          // error={errors?.currency}
          name="material"
          url="/api/materials"
          serializeItem={(item: Material) => ({ label: `${item.code} | ${item.name}`, ...item })}
          onListItemClicked={data => {
            setMaterial(data)
            setMaterialId(data?.id)
            item.materialId = data?.id

          }}
          onLoad={item => setMaterial(item)}
          placeholder="Select Material"
        />
      </div>
      <div className="col-span-3 xl:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Cost/pcs</label>
        <div className="mt-1 flex rounded-md">
          <p className="sm:text-sm">{moneyFormatter(costPerUnit(), currency?.code)}</p>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <NumberInput label="pcs" value={quantity} setValue={setQuantity} />
      </div>
      <div className="col-span-3 xl:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Cost</label>
        <div className="mt-1 flex rounded-md">
          <p className="sm:text-sm">{moneyFormatter(totalMaterialCost(), currency?.code)}</p>
        </div>
      </div>
      <div className="col-span-1 self-center">
        <button type="button" className="bg-red-500 rounded-md text-white p-3" onClick={handleDelete}>
          <i className="uil uil-trash"></i>
        </button>
      </div>
      <div className="block xl:hidden col-span-6">
        <div className="border-t border-gray-200" />
      </div>
    </div>
  )
}
