import * as React from "react"
import image from "../../images/404.jpg"
import { Link } from "react-router-dom"

export const NotFoundPage: React.FC = () => {
  return(
    <div className="w-full flex main-layout max-w-full">
      <div className="grid grid-cols-12 md:gap-6 min-h-screen">
        <div className="col-span-12 md:col-span-4 self-end md:self-center text-center md:text-right">
          <p className="text-9xl text-indigo-500 font-extrabold">404</p>
          <p>We can&apos;t seem to find anything here</p>
          <div className="mt-5">
            <Link className="inline-block bg-indigo-500 text-white p-3 rounded-md" to="/">
              <i className="uil uil-angle-left-b mr-2"></i>
              <span>Go Home</span>
            </Link>
          </div>
        </div>
        <div className="col-span-12 md:col-span-8 md:self-center text-center md:text-left">
          <img src={image} className=" w-full md:w-8/12 h-auto inline-block"/>
        </div>
      </div>
    </div>
  )
}
