import * as React from "react"
import { BackButton } from "../../components/BackButton"
import { MainLayout } from "../../layouts/Main"
import { Link, useHistory } from "react-router-dom"
import { LoadingRoller } from "../../components"
import { useEffect, useState } from "react"
import { ProductPurchaseOrder, PurchaseOrderStatus } from "../../domain_data/product_purchase_order"
import { FormBlock, FullFormBlock } from "../../components/forms/FormBlock"
import { Modal } from "../../components/Modal"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { TextInputShow } from "../../components/inputs/TextInputShow"
import * as dayjs from "dayjs"
import { API, numberFormatter } from "../../utils"
import { ProductPurchaseOrderItem } from "../../domain_data/product_purchase_order_item"
import { Material } from "../../domain_data/material"
import iziToast from "izitoast"
import { Recipe } from "../../domain_data/recipe"
import { ItemNotFound } from "../ItemNotFound"
import { ProductPOReturn, ProductPOReturnOperation, ProductPOReturnStage } from "../../domain_data/product_po_return"
import { Office } from "../../domain_data/office"
import { RouteComponentProps } from "react-router-dom"
import { JoSmithData } from "../../domain_data/jo_smith"
import { JoSmithShow } from "./Utils"


type JobOrderGroupParam = RouteComponentProps<{ jobOrderGroupId: string, id: string }>


export const ShowProductPO: React.FC<JobOrderGroupParam> = ({ match: { params: { jobOrderGroupId, id } } }: JobOrderGroupParam) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [jobOrder, setJobOrder] = useState<ProductPurchaseOrder>(null)
  const [jobOrderItems, setJobOrderItems] = useState<ProductPurchaseOrderItem[]>([])
  const [notFound, setNotFound] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [office, setOffice] = useState<Office>()
  const history = useHistory()
  const [joSmiths, setJoSmiths] = useState<JoSmithData[]>([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);


  const loadPO = () => {
    API.get(`/api/product_purchase_orders/${id}`).then(({ data }) => {
      setJobOrder(data)

      if (data.officeId) {
        loadOffice(data.officeId)
      }
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      loadPOItems()
    })
  }

  const loadPOItems = () => {
    API.get(`/api/product_purchase_orders/${id}/product_po_items`).then(({ data: { entries } }) => {
      setJobOrderItems(entries)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  const loadOffice = (id) => {
    API.get(`/api/offices/${id}`).then(({ data }) => {
      setOffice(data)
    })
  }

  useEffect(() => {
    loadPO()
  }, [])

  const finalize = () => {
    API.post(`/api/product_purchase_orders/${id}/finalize`).then(() => {
      history.go(0)
    })
      .catch(() => {
        iziToast.error({ title: "Error", message: "Failed to finalize job order" })
      }).finally(() => {
        setIsModalOpen(false)
      })
  }

  const deleteJobOrder = () => {
    API.delete(`/api/product_purchase_orders/${id}`).then(() => {
      history.push(`/job_order_groups/${jobOrderGroupId}/job_orders`)
    }).catch((e) => {
      iziToast.error({ title: "Error", message: e.response.data.message })
    }).finally(() => {
      setIsDeleteModalOpen(false)
    })
  }

  useEffect(() => {
    API.get(`/api/product_purchase_orders/${id}/jo_smiths`).then(({ data: { entries } }) => {
      setJoSmiths(entries)
    })
  }, [])

  if (notFound) return <ItemNotFound />

  const totalWeightToTheSmith = jobOrderItems.map(i => i.realWeight - (i.realWeight * i.depreciation / 100)).reduce((sum, current) => sum + current, 0)

  return (
    <MainLayout>
      {loading || !jobOrder ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path={`/job_order_groups/${jobOrderGroupId}/job_orders`} />
            <div className="di">
              <Link to={`/job_order_groups/${jobOrderGroupId}/job_orders/${id}/edit`} className="dark-button-with-text mr-2">
                <i className="uil uil-edit-alt text-lg"></i>
                <span className="ml-2">Edit</span>
              </Link>

              {jobOrder.status == PurchaseOrderStatus.Pending ?
                <>
                  <button className="dark-button-with-text mr-2" onClick={() => { setIsModalOpen(true) }}>
                    <i className="uil uil-truck text-lg"></i>
                    <span className="ml-2">Finalize</span>
                  </button>
                </>
                :
                <>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ padding: "10px 20px" }}
                    className="mr-2 bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
                    href={`/utilities/job-orders/${id}.xlsx`}>
                    <i className="uil uil-download-alt text-lg"></i>
                    <span className="ml-2">Save Excel</span>
                  </a>

                  <a target="_blank" rel="noreferrer" className="dark-button-with-text mr-2" href={`/utilities/job-orders/${id}.pdf`}>
                    <i className="uil uil-download-alt text-lg"></i>
                    <span className="ml-2">Save PDF</span>
                  </a>
                </>
              }

              <button className="red-button-with-text mr-2 " onClick={() => setIsDeleteModalOpen(true)}>
                <i className="uil uil-trash text-lg"></i>
                <span className="ml-2">Delete</span>
              </button>
            </div>
          </div>
          <div>
            <FormBlock heading="Order Status" description="The shipping status of this order.">
              <div className="grid grid-cols-4 gap-6">
                {jobOrder.status == PurchaseOrderStatus.Pending &&
                  <div className="sm:col-span-auto col-span-3"><span className="bg-yellow-600 inline-block text-white p-2 rounded-md">PENDING</span></div>
                }

                {jobOrder.status == PurchaseOrderStatus.Doing &&
                  <div className="sm:col-span-auto col-span-3"><span className="bg-green-600 inline-block text-white p-2 rounded-md">DOING</span></div>
                }

                {jobOrder.status == PurchaseOrderStatus.Arrived &&
                  <div className="sm:col-span-auto col-span-3"><span className="bg-green-600 inline-block text-white p-2 rounded-md">FINISHED</span></div>
                }
              </div>

              {jobOrder.status == PurchaseOrderStatus.Pending &&
                <>
                  <Modal
                    onDeclined={() => setIsModalOpen(false)}
                    onAccepted={finalize}
                    label="Are you sure want to set the order status as arrived?"
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                </>

              }

              <Modal
                onDeclined={() => setIsDeleteModalOpen(false)}
                onAccepted={deleteJobOrder}
                label="Are you sure want delete this Job Order. The process is irreversible?"
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
              />
            </FormBlock>

            <FormUnderline />

            <FormBlock heading="Purchase Order" description="This is the general information of the purchase order.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Purchase Order Number">
                    <b>{jobOrder.orderNumber}</b>
                  </TextInputShow>
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="BILL TO">
                    {office ?
                      <b>
                        <Link to={`/offices/${office.id}`}>{office.name}</Link>
                      </b>
                      :
                      'No Location'
                    }
                  </TextInputShow>
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Product">
                    {jobOrder.productName}
                  </TextInputShow>
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Quantity">
                    {numberFormatter(jobOrder.quantity)}
                  </TextInputShow>
                </div>

                <div className="col-span-4">
                  <TextInputShow label="Note">
                    {jobOrder.note}
                  </TextInputShow>
                </div>
              </div>
            </FormBlock>

            <FullFormBlock heading="Smiths" description="This is the list of supplier(s) that are working on the job order.">
              <div className="grid grid-cols-12 gap-6">
                {joSmiths && joSmiths.map((item, key) => <JoSmithShow key={key} item={item} />)}
              </div>
            </FullFormBlock>

            <FormUnderline />

            <FullFormBlock heading="Materials" description="This is the list of material needed.">
              <div className="grid grid-cols-1 gap-6">
                {jobOrderItems.map((item, key) => (
                  <OrderItem
                    key={key}
                    item={item}
                    jo={jobOrder}
                    productId={jobOrder.productId}
                  />
                ))}
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">
                  <p className="font-bold">Total Weight to the smith {numberFormatter(totalWeightToTheSmith)} gram(s)</p>
                </div>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">
                  <p className="font-bold">Total Weight From the smith {numberFormatter(jobOrder.netWeight)} gram(s)</p>
                </div>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">
                  <p className="font-bold">Silver Use {numberFormatter(jobOrder.netWeight - totalWeightToTheSmith)} gram(s)</p>
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">
                  <p className="font-bold">Silver Use {numberFormatter((jobOrder.netWeight - totalWeightToTheSmith) / (jobOrder.quantity || 1))} gram(s) Silver/PC</p>
                </div>
              </div>
            </FullFormBlock>
            {jobOrder.status != PurchaseOrderStatus.Pending &&
              <>
                <FormUnderline />
                <POReturns noWeight={false} jobOrderGroupId={jobOrderGroupId} jobOrder={jobOrder} title="Order Arriving" description="Coming in from the Smith." stage={ProductPOReturnStage.Arriving} />
                <FormUnderline />
                <POReturns noWeight={true} jobOrderGroupId={jobOrderGroupId} jobOrder={jobOrder} title="Order In Service" description="In Service." stage={ProductPOReturnStage.InService} />
                <FormUnderline />
                <POReturns noWeight={true} jobOrderGroupId={jobOrderGroupId} jobOrder={jobOrder} title="Order In Plating" description="Order in plating service" stage={ProductPOReturnStage.Plating} />
                <FormUnderline />
                <POReturns noWeight={true} jobOrderGroupId={jobOrderGroupId} jobOrder={jobOrder} title="Order In QC House" description="Order in QC House" stage={ProductPOReturnStage.QcHouse} />
                <FormUnderline />
                <POReturns noWeight={false} jobOrderGroupId={jobOrderGroupId} jobOrder={jobOrder} title="Order In Stock" description="Order process is finished, and currently in the warehouse." stage={ProductPOReturnStage.InStock} />
              </>
            }
          </div>
        </div>
      }
    </MainLayout>
  )
}

export interface POReturnsProps {
  stage: ProductPOReturnStage,
  jobOrder: ProductPurchaseOrder,
  title: string,
  description: string,
  jobOrderGroupId: string,
  noWeight: boolean,
}

export const POReturns: React.FC<POReturnsProps> = ({ stage, jobOrder, title, description, jobOrderGroupId, noWeight }: POReturnsProps) => {
  const [returnItems, setReturnItems] = useState<ProductPOReturn[]>([])

  useEffect(() => {
    API.get(`/api/product_purchase_orders/${jobOrder.id}/product_po_returns`, { params: { stage } }).then(({ data }) => {
      setReturnItems(data)
    })
      .catch(() => {
        iziToast.error({ title: "Error", message: "Failed to fetch po returns" })
      })
  }, [])

  const totalIn = (): number => {
    if (!returnItems || returnItems.length == 0) return 0
    return returnItems.filter(i => i.operation == ProductPOReturnOperation.In)
      .map(i => i.quantity)
      .reduce((sum, item) => sum + item, 0)
  }

  const totalOut = (): number => {
    if (!returnItems || returnItems.length == 0) return 0
    return returnItems.filter(i => i.operation == ProductPOReturnOperation.Out)
      .map(i => i.quantity)
      .reduce((sum, item) => sum + item, 0)
  }

  const withWeight = noWeight ? "no_weight=true" : ""


  let returnWeightAccumulation = 0;

  return (
    <FormBlock heading={title} description={description}>
      <div className="grid grid-cols-4 gap-6">
        {returnItems.map((item, key) => {
          returnWeightAccumulation += item.weight
          return <ReturnItem noWeight={noWeight} key={key} item={item} jo={jobOrder} returnWeightAccumulation={returnWeightAccumulation} jobOrderGroupId={jobOrderGroupId} />
        })}

      </div>

      <div>
        <div>Total In {totalIn()}</div>
        <div>Total Out {totalOut()}</div>
        <div>Balance {totalIn() - totalOut()}</div>
      </div>

      <Link className="dark-button-with-text" to={`/job_order_groups/${jobOrderGroupId}/job_orders/${jobOrder.id}/returns/new/${stage}?${withWeight}`}>Add</Link>
    </FormBlock>
  )
}

export interface ReturnItemProp {
  item: ProductPOReturn,
  jo: ProductPurchaseOrder,
  returnWeightAccumulation: number,
  jobOrderGroupId: string,
  noWeight: boolean
}
export const ReturnItem: React.FC<ReturnItemProp> = ({ item, jo, jobOrderGroupId, noWeight }: ReturnItemProp) => {
  return (
    <div className="col-span-4 grid grid-cols-5">
      <div className="col-span-2 md:col-span-1">
        <TextInputShow label="Operation Date">
          {dayjs(item.dateIn).format("DD MMMM YYYY")}
        </TextInputShow>
      </div>
      <div className="col-span-2 md:col-span-1">
        <TextInputShow label="Quantity">
          {numberFormatter(item.quantity)} pcs
        </TextInputShow>
      </div>
      {!noWeight &&
        <div className="col-span-2 md:col-span-1">
          <TextInputShow label="Weight">
            {numberFormatter(item.weight || 0)} gram(s)
          </TextInputShow>
        </div>
      }
      <div className="col-span-2 md:col-span-1">
        {
          item.operation == ProductPOReturnOperation.In ?
            <div className="px-3 py-2 bg-green-500 rounded-lg text-white w-12 text-center">In</div>
            :
            <div className="px-3 py-2 bg-yellow-500 rounded-lg text-white w-12 text-center">Out</div>
        }
      </div>
      <div className="col-span-2 md:col-span-1">
        <Link className="dark-button-with-text" to={`/job_order_groups/${jobOrderGroupId}/job_orders/${jo.id}/returns/${item.id}/edit`}>
          <i className="uil uil-edit-alt text-lg"></i>
          <span>Edit</span>
        </Link>
      </div>

      {item?.note &&
        <div className="col-span-5">
          <TextInputShow label="">
            <span className="italic text-xs">Note: {item?.note}</span>
          </TextInputShow>
        </div>
      }
    </div>
  )
}

export interface OrderItemProps {
  item: ProductPurchaseOrderItem
  jo: ProductPurchaseOrder
  productId: number
}

export const OrderItem: React.FC<OrderItemProps> = ({ item, jo, productId }: OrderItemProps) => {
  const [material, setMaterial] = useState<Material>(null)
  const [recipe, setRecipe] = useState<Recipe>(null)

  useEffect(() => {
    API.get(`/api/materials/${item.materialId}`).then(({ data }) => {
      setMaterial(data)
    })
      .catch(() => {
        iziToast.error({ title: "Error", message: "Can't find material" })
      })
  }, [])

  useEffect(() => {
    API.get(`/api/products/${productId}/recipes/${item.recipeId}`).then(({ data }) => {
      setRecipe(data)
    })
      .catch(() => {
        iziToast.error({ title: "Error", message: "Can't find recipe" })
      })
  }, [])

  const quantityNeeded = jo.quantity * recipe?.quantity

  const balance = item.pulled - quantityNeeded

  return (
    <>{!material || !recipe ?
      <div className="col-span-6 grid grid-cols-12 gap-6">
        <div className="col-span-6 sm:col-span-3">
          Loading...
        </div>
      </div>
      :
      <div className="col-span-6 grid grid-cols-12 gap-6">
        <div className="col-span-6 xl:col-span-2 ">
          <TextInputShow
            label="Material">
            <Link to={`/materials/${material.id}`}>{`${material.code} | ${material.name} x ${recipe.quantity}`}</Link>
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            label="Quantity Needed">{numberFormatter(quantityNeeded) || "0"}
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <TextInputShow
            value={numberFormatter(item.pulled)}
            label="Pulled From Stock"
          />
        </div>

        <div className="col-span-6 xl:col-span-1 text-gray-700">
          <TextInputShow
            value={numberFormatter(balance)}
            label="Balance Quantity"
          />
        </div>

        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <TextInputShow
            value={`${numberFormatter(item.realWeight)} gram(s)`}

            label="Real Weight"
          />
        </div>
        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <TextInputShow
            value={`${numberFormatter(item.depreciation)}%`}
            label="Depreciation"
          />
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            label="Weight to the Smith">{numberFormatter(item.realWeight - (item.realWeight * item.depreciation / 100)) || "0"} gram
          </TextInputShow>
        </div>

        <div className="block col-span-12">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    }</>
  )
}
