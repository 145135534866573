import iziToast from "izitoast"
import * as React from "react"
import { useContext, useEffect, useState} from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { MultipleFilesInput } from "../../components/inputs/MutipleFilesInput"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { TextInput } from "../../components/inputs/TextInput"
import { MainContext } from "../../context/MainContext"
import { FileBlob } from "../../domain_data/image_attachment"
import {  ProductErrors, ProductInput } from "../../domain_data/product"
import { ProductCategory } from "../../domain_data/product_category"
import {  ProductVariantInput } from "../../domain_data/product_variant"
import { RecipeInput } from "../../domain_data/recipe"
import { IDgenerator, moneyFormatter } from "../../utils"
import { ProductVariantFormItem } from "./ProductVariant"
import { RecipeFormItem } from "./Recipe"
import { useHistory } from "react-router-dom"
import { ServiceFeeInput } from "../../domain_data/service_fee"
import { ServiceFeeForm } from "./ServiceFee"

export interface ProductFormInputProps {
  product: ProductInput,
  productVariants: ProductVariantInput[],
  recipes: RecipeInput[],
  serviceFees: ServiceFeeInput[],
}

interface ProductFormProps {
  id: string,
  errors: ProductErrors,
  data: ProductFormInputProps
  onSubmit?: (data: ProductFormInputProps) => void
}

export const ProductForm: React.FC<ProductFormProps> =
  ({ id, errors, data: { product, productVariants: varaints, recipes: product_recipes, serviceFees: fees }, onSubmit }: ProductFormProps) => {
    const [name, setName] = useState<string>(product.name)
    const [recipeCost, setRecipeCost] = useState<number>(0)
    const [serviceFees, setServiceFees] = useState<ServiceFeeInput[]>(fees)
    const [serviceCost, setServiceCost] = useState<number>(0)

    const defaultImages = (product.images || []).map(i => ({
      attachable_sgid: i.attachableSgid,
      byte_size: i.byteSize,
      checksum: i.checksum,
      content_type: i.contentType,
      filename: i.filename,
      id: i.id,
      service_name: i.serviceName,
      signed_id: i.signedId,
      url: i?.url,
    }));

    const [images, setImages] = useState<FileBlob[]>([])
    const [sku, setSku] = useState<string>(product.sku)
    const [productCategoryId, setProductCategoryId] = useState<number>(product.productCategoryId)

    const [productVariants, setProductVariants] = useState<ProductVariantInput[]>(varaints)
    const [recipes, setRecipes] = useState<RecipeInput[]>(product_recipes)

    const history = useHistory();

    const { setting } = useContext(MainContext)

    useEffect(() => {
      if (!setting) return

      if (!setting.currency) {
        iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
        history.push("/currencies")
      }

    }, [setting])

    const totalCost = (): number => {
      return recipeCost + serviceCost
    }

  return(
    <form id={id} onSubmit={(e) => {
      e.preventDefault()

      onSubmit && onSubmit({
        product: {
          name,
          productCategoryId,
          sku,
          images: images.map(i => ({
            attachableSgid: i.attachable_sgid,
            byteSize: i.byte_size,
            checksum: i.checksum,
            contentType: i.content_type,
            filename: i.filename,
            id: i.id,
            serviceName: i.service_name,
            signedId: i.signed_id,
            url: i?.url,
          }))
        },
        serviceFees,
        productVariants,
        recipes
      })
    }}>
      <FormBlock heading="Product Information" description="This is the general product information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Name"
              value={name}
              setValue={setName}
              error={errors?.name}
              name="name"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="SKU"
              value={sku}
              setValue={setSku}
              error={errors?.sku}
              name="SKU"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="Category"
              defaultValue={productCategoryId}
              error={errors?.productCategoryId}
              name="productCategoryId"
              url="/api/product_categories"
              serializeItem={(item: ProductCategory) => ({ label: item.name, ...item })}
              onListItemClicked={item => setProductCategoryId(item?.id)}
              placeholder="Select Category"
            />
          </div>
        </div>
      </FormBlock>

      <FormUnderline />

      <FormBlock heading="Image Samples" description="This is the sample images of the products.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <MultipleFilesInput initialFiles={(defaultImages || [])} onChange={(files) => setImages(files)} error={errors?.images}/>
          </div>
        </div>
     </FormBlock>

      <FormUnderline />

      <FormBlock heading="Product Variants" description="This is the list of the different variant of the product.">
        <div className="grid grid-cols-6 gap-6">
          {productVariants.map((varaint, key) => (
            <ProductVariantFormItem
              variant={varaint}
              key={key}
              onDelete={(varaint) => setProductVariants(current => {
                return current.map(item => {
                  if (item.identifier == varaint.identifier) {
                    return {...varaint, delete: true}
                  }
                  varaint
                } )
              })}
            />
          ))}

        </div>
          <button type="button" className="rounded-md bg-indigo-500 text-white px-3 py-2"
            onClick={() => setProductVariants(c => c.concat({ name: "", identifier: IDgenerator() }))}
          >
            <i className="uil uil-plus "></i>
            <span className="ml-2">Add Variant</span>
          </button>
      </FormBlock>
      <FormUnderline />

      <FormBlock heading="Recipes" description="This is the material recipes for this product.">
        <div className="grid grid-cols-6 gap-6">
          {recipes.map((recipe, key) => (
            <RecipeFormItem
              recipe={recipe}
              key={key}
              onDelete={cost => setRecipeCost(c => c + cost)}
              onChange={cost => setRecipeCost(c => c + cost)}
              currency={setting?.currency}
            />
          ))}

        </div>
        <button type="button" className="rounded-md bg-indigo-500 text-white px-3 py-2"
          onClick={() => setRecipes(c => c.concat({ quantity: 0, _destroy: false,  identifier: IDgenerator() }))}
        >
          <i className="uil uil-plus "></i>
          <span className="ml-2">Add Material</span>
        </button>
        {recipeCost > 0 &&
          <>
            <div className="block">
              <div className="border-t border-gray-200" />
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-2 font-bold">Material Cost</div>
            <p className="font-bold">{moneyFormatter(recipeCost, setting?.currency?.code)}</p>
            </div>
          </>
        }
      </FormBlock>
      <FormUnderline />
      <FormBlock heading="Assembly Fees" description="This is the additional cost of the product.">
        <div className="grid grid-cols-6 gap-6">
          {serviceFees.map((fee, key) => (
            <ServiceFeeForm
              fee={fee}
              key={key}
              onChange={(cost) => setServiceCost(c => c + cost)}
            />
          ))}

        </div>
        <button type="button" className="rounded-md bg-indigo-500 text-white px-3 py-2"
          onClick={() => setServiceFees(c => c.concat({ name: "", quantity: "0", cost: "", _destroy: false }))}
        >
          <i className="uil uil-plus"></i>
          <span className="ml-2">Add Fee</span>
        </button>

        {serviceCost > 0 &&
          <>
            <div className="block">
              <div className="border-t border-gray-200" />
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-2 font-bold">Assembly Cost</div>
              <p className="font-bold">{moneyFormatter(serviceCost, setting?.currency?.code)}</p>
            </div>
          </>
        }
      </FormBlock>

      { totalCost() > 0 &&
        <>
          <FormUnderline />
          <FormBlock heading="Total Cost" description="This is the total cost of this product.">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-2 font-bold">Costing Sheet</div>
              <p className="font-bold">{moneyFormatter(totalCost(), setting?.currency?.code)}</p>
            </div>
          </FormBlock>
        </>
      }
    </form>
  )
}

