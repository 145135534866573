import * as React from "react";
import { useEffect, useState } from "react";

interface MenuWithSubMenu {
  children?: React.ReactNode
  header: React.ReactElement
  overridesClassContainer?: string
  isActive?: boolean
}

export const MenuWithSubMenu: React.FC<MenuWithSubMenu> = ({ children, header, overridesClassContainer, isActive }: MenuWithSubMenu) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsOpen(isActive)
  }, [isActive])
  return(
    <div className={`overflow-hidden ${overridesClassContainer}`}>
      <div onClick={() => setIsOpen(c => !c)}>{header}</div>
      <div className={`${isOpen ? "h-auto" : "h-0"} ml-3 transition-all duration-1000`}>
        {children}
      </div>
    </div>
  )
}
