import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { RouteComponentProps, Link, useHistory } from "react-router-dom"
import { SearchComponent } from "../../components/SeachComponent";
import { AdjustmentOperation, MaterialAdjustment } from "../../domain_data/material_adjustment";
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import { useEffect, useState } from "react";
import { Pagination } from "../../domain_data/pagination";
import { ContentLoading } from "../../components/ContentLoading";
import { AxiosResponse } from "axios";
import { API } from "../../utils";
import iziToast from "izitoast";
import { BackButton } from "../../components/BackButton";
import * as dayjs from "dayjs"
import { ItemNotFound } from "../ItemNotFound";
import { truncate } from "lodash";

export type MaterialAdjustmentParams = RouteComponentProps<{ materialId: string }>;

export const MaterialAdjustmentList: React.FC<MaterialAdjustmentParams> = ({ match: { params: { materialId } } }: MaterialAdjustmentParams) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [adjustments, setAdjustments] = useState<MaterialAdjustment[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);
  const [notFound, setNotFound] = React.useState<boolean>(false)

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  useEffect(() => {
    setLoading(true)
    API.get(`/api/materials/${materialId}/material_adjustments`, { params: {
      page,
      items: itemPerPage,
      sort: "id",
      direction: "DESC"
  }}).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setAdjustments(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [page, itemPerPage])

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <div className="flex items-center mb-5 sm:mb-0">
          <BackButton path={`/materials/${materialId}`}/>
          <div className="mr-5" />
          <Link to={`/materials/${materialId}/adjustments/new`} className="dark-button-with-text max-w-max">Add New Adjustment</Link>
        </div>
        <div className="inline-block sm:w-2/5 w-full align-middle sm:ml-10">
          <SearchComponent
            placeholder="Type to search by title...."
            url="/api/materials/${materialId}/material_adjustments"
            interceptRequest={(entries: MaterialAdjustment[]) => entries.map(item => ({ label: item.title, ...item }))}
            listComponent={(data) => (
              <>
                <div className="text-lg">{data.label}</div>
              </>
            )}
            onListItemClicked={(data) => {
              history.push(`/materials/${materialId}/adjustments/${data.id}`)
            }}
          />
        </div>
      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">
        <div className="mt-5" />
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">Title</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Adjustment</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Description</th>
              <th className="text-center py-3 px-6 md:table-cell hidden">Created At</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                :
                adjustments?.map(adjustment => (
                  <tr key={adjustment.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/materials/${materialId}/adjustments/${adjustment.id}`}>{truncate(adjustment.title)}</Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      { adjustment.operation == AdjustmentOperation.addition ?
                        <span className="py-1 px-2 text-white font-bold bg-green-400 rounded">+{adjustment.quantity}</span>
                        :
                        <span className="py-1 px-2 text-white font-bold bg-red-400 rounded">-{adjustment.quantity}</span>
                      }
                    </td>
                    <td className="py-3 px-6 text-center md:table-cell hidden">{ truncate(adjustment.description, { length: 70}) }</td>
                    <td className="py-3 px-6 text-center md:table-cell hidden">{dayjs(adjustment.createdAt).format('DD MMM YYYY')}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </MainLayout>
  )
}
