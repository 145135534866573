import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone'
import excel from "../../../images/excel.svg"

export interface ExcelInputProps {
  onChange: (data: File) => void;
}
export const ExcelInput: React.FC<ExcelInputProps> = ({ onChange }: ExcelInputProps) => {
  const [file, setFile] = useState<File>()
  const onDrop = useCallback((acceptedFiles) => {
    const newFile = acceptedFiles[0]
    setFile(newFile)
  }, [])

  useEffect(() => {
    onChange(file)
  }, [file])

  const isEmpty = !file
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  return (
    <div {...getRootProps()} className="border-2 border-dotted p-5 xl:p-10 bg-white">
      <input {...getInputProps()} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" multiple={false} />
      { !isEmpty &&
        <div className="flex justify-center">
          <div>
            <div className="w-20">
              <img src={excel} alt="" className="w-full h-auto" />
            </div>
            <div className="bg-gray-200 p-1 mt-2 rounded-md">{file.name}</div>
          </div>
        </div>
      }
      {isEmpty &&
        <div className="text-center cursor-pointer">
          <i className="uil uil-image-upload text-3xl text-gray-700"></i>
          <p className="text-gray-700">Drag n drop some files here, or click to select files</p>
        </div>
      }
    </div>
  )
}
