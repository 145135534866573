import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { TextInput } from "../../components/inputs/TextInput"
import { ProductCategoryErrors, ProductCategoryInput } from "../../domain_data/product_category"

import { DefaultFormProps } from "../../utils"

export const ProductCategoryForm:
  React.FC<DefaultFormProps<ProductCategoryInput, ProductCategoryErrors>> =
  ({
    data,
    onSubmit,
    id,
    errors
  }: DefaultFormProps<ProductCategoryInput, ProductCategoryErrors>) => {
    const [name, setName] = useState<string>(data.name)
    return (
      <form id={id} onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit({ name })
      }}>
        <div>
          <FormBlock heading="Category Name" description="This is the name of the raw product category.">
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-4 md:col-span-2">
                <TextInput
                  label="Category Name"
                  value={name}
                  setValue={setName}
                  error={errors?.name}
                />
              </div>
            </div>
          </FormBlock>
        </div>
      </form>
    )
  }
