import * as React from "react"
import { useEffect, useState } from "react";
import { FormBlock, FullFormBlock } from "../../components/forms/FormBlock";
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect";
import { ProductPurchaseOrderErrors, ProductPurchaseOrderInput } from "../../domain_data/product_purchase_order";
import { ProductPurchaseOrderItemInput } from "../../domain_data/product_purchase_order_item";
import { Product } from "../../domain_data/product";
import { NumberInput } from "../../components/inputs/NumberInput";
import { FormUnderline } from "../../components/forms/FormUnderline";
import API from "../../utils/api";
import { AxiosResponse } from "axios";
import { Recipe } from "../../domain_data/recipe";
import { Material } from "../../domain_data/material";
import iziToast from "izitoast";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { normalizeNumber, numberFormatter } from "../../utils";
import { Link } from "react-router-dom"
import { NumberInputWithPrefix } from "../../components/inputs/NumberInputWithPrefix";
import { TextInput } from "../../components/inputs/TextInput";
import { Office } from "../../domain_data/office";
import { Textarea } from "../../components/inputs/Textarea";
import { JoSmithInput } from "../../domain_data/jo_smith";
import { JoSmithItemForm } from "./Utils";


export interface ProductPOInputProps {
  po: ProductPurchaseOrderInput,
  orderItems: ProductPurchaseOrderItemInput[],
  joSmiths: JoSmithInput[]
}

interface ProductPOFormProps {
  id: string,
  errors: ProductPurchaseOrderErrors,
  data: ProductPOInputProps
  onSubmit?: (data: ProductPOInputProps) => void
}


export const ProductPOForm: React.FC<ProductPOFormProps> = ({ id, errors, data, onSubmit }: ProductPOFormProps) => {
  const [productId, setProductId] = useState<number>(data.po.productId)
  const [quantity, setQuantity] = useState<string>(data.po.quantity)
  const [orderItems, setOrderItems] = useState<JobOrderItemData[]>([])
  const [totalToTheSmith, setTotalToTheSmith] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [netWeight, _setNetWeight] = useState<string>(data.po.netWeight)
  const [orderNumber, setOrderNumber] = useState<string>(data.po.orderNumber)
  const [officeId, setOfficeId] = useState<number>(data.po.officeId)
  const [note, setNote] = useState<string>(data.po.note)

  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [joSmiths, setJoSmiths] = useState<JoSmithInput[]>(data.joSmiths)


  useEffect(() => {
    if (!productId) return;
    API.get(`/api/products/${productId}/recipes`, { params: { items: 1000 } }).then(({ data: { entries } }: AxiosResponse) => {
      setRecipes(entries)
    })
  }, [productId])

  useEffect(() => {
    setOrderItems(recipes.map(item => ({
      recipe: item,
      orderItem: {
        recipeId: item.id,
        depreciation: "5",
        pulled: "0",
        realWeight: "0",
        materialId: item.materialId
      }
    })))
  }, [recipes])

  const silverUse = normalizeNumber(netWeight) - totalToTheSmith

  const addJoSmith = (_) => {
    setJoSmiths(j => j.concat({
      orderDate: new Date(),
      eta: new Date(),
      quantity: 1,
      _destroy: false
    }))
  }

  useEffect(() => {
    if (!data.po.jobOrderGroupId) return
    API.get(`/api/job_order_groups/${data.po.jobOrderGroupId}`).then(({ data: { name } }: AxiosResponse) => {
      setOrderNumber(name);
    })
  }, [])

  return (
    <form id={id} onSubmit={(e) => {
      e.preventDefault()
      onSubmit && onSubmit({
        po: {
          productId,
          quantity,
          title: null,
          note,
          orderNumber,
          officeId,
        },
        orderItems: orderItems.map(i => i.orderItem),
        joSmiths: joSmiths
      })
    }}>
      <FormBlock heading="General Information" description="This is the general Job Order information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput label="Purchase Order Number" disabled error={errors.orderNumber} value={orderNumber} setValue={setOrderNumber} />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="BILL TO"
              defaultValue={officeId}
              error={errors?.office}
              name="office"
              url="/api/offices"
              serializeItem={(item: Office) => ({ label: item.name, ...item })}
              onListItemClicked={item => setOfficeId(item?.id)}
              placeholder="Select Billing Address"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <SingleAjaxSelect
              label="Product"
              defaultValue={productId}
              error={errors?.product}
              name="supplier"
              url="/api/products"
              serializeItem={(item: Product) => ({ label: `${item.sku} | ${item.name}`, ...item })}
              onListItemClicked={item => setProductId(item?.id)}
              placeholder="Select Product"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <NumberInput label="Quantity" value={quantity} setValue={setQuantity} />
          </div>
          <div className="col-span-6">
            <Textarea label="Note" value={note} setValue={setNote} error={errors?.note} />
          </div>
        </div>
      </FormBlock>
      <FormUnderline />
      <FullFormBlock heading="Smiths" description="This is the list of supplier(s) that are working on the job order.">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            {joSmiths && joSmiths.map((i, key) =>
              <div className="col-span-12 my-5" key={key}>
                <JoSmithItemForm joSmith={i} />
              </div>)}
          </div>

          {(errors.joSmithQuantity || errors.joSmithSupplier) && (
            <div className="col-span-12 grid grid-cols-1">
              {errors.joSmithQuantity && <p className="text-red-500">The total quantity must be equal to {quantity}</p>}
              {errors.joSmithSupplier && <p className="text-red-500">The smith needs to be unique</p>}
            </div>
          )}

          <div className="col-span-12 mt-5">
            <button type="button" className="dark-button-with-text" onClick={addJoSmith}>
              Add
            </button>
          </div>
        </div>
      </FullFormBlock>
      <FormUnderline />
      <FullFormBlock heading="Billing of Material" description="This is the list of material needed.">
        <div className="grid grid-cols-6 gap-6">
          {orderItems && orderItems.map((item, key) => (
            <JobOrderItem
              key={key}
              data={item}
              orderQuantity={normalizeNumber(quantity)}
              onChange={data => setTotalToTheSmith(c => c + data)}
            />
          ))}
        </div>
        {
          productId &&
          <>
            <div className="grid grid-cols-6 gap-6">
              <p className="col-span-6 text-lg font-bold">Total Weight to the smith {numberFormatter(totalToTheSmith)} gram(s)</p>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <p className="col-span-6 text-lg font-bold">Silver Use {numberFormatter(silverUse)} gram(s)</p>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <p className="col-span-6 text-lg font-bold">Silver Use/PC {numberFormatter(silverUse / (parseInt(quantity) || 1))} gram(s) Silver/PO</p>
            </div>
          </>
        }
      </FullFormBlock>
    </form>
  )
}

export interface JobOrderItemData {
  recipe: Recipe,
  orderItem: ProductPurchaseOrderItemInput,
}

export interface JobOrderItemProps {
  data: JobOrderItemData,
  orderQuantity: number
  onChange: (data: number) => void
}

export const JobOrderItem: React.FC<JobOrderItemProps> = ({ data, orderQuantity, onChange }: JobOrderItemProps) => {
  const [material, setMaterial] = useState<Material>(null)
  const [pulled, setPulled] = useState<string>(data.orderItem.pulled)
  const [realWeight, setRealWeight] = useState<string>(data.orderItem.realWeight)
  const [depreciation, setDepreciation] = useState<string>(data.orderItem.depreciation)
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    data.orderItem.pulled = pulled
    data.orderItem.realWeight = realWeight
    data.orderItem.depreciation = depreciation
    onChange(totalToTheSmith - total)
    setTotal(totalToTheSmith)
  }, [pulled, realWeight, depreciation])

  useEffect(() => {
    API.get(`/api/materials/${data.recipe.materialId}`).then(({ data }) => {
      setMaterial(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Can't find material" })
    })
  }, [data.recipe])

  const isStockNotEnough = normalizeNumber(pulled) > material?.stock

  const totalToTheSmith = normalizeNumber(realWeight) - (normalizeNumber(depreciation) / 100 * normalizeNumber(realWeight))
  const quantityNeeded = orderQuantity * data?.recipe?.quantity
  const quantityBalance = normalizeNumber(pulled) - quantityNeeded

  return (
    <>{!material ?
      <div className="col-span-6 grid grid-cols-12 gap-6">
        <div className="col-span-6 sm:col-span-3">
          Loading...
        </div>
      </div>
      :
      <div className={`col-span-6 grid grid-cols-12 gap-6 ${isStockNotEnough ? "bg-red-500 p-2 text-white" : ""}`}>
        <div className="col-span-6 xl:col-span-2 ">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Material">
            <Link to={`/materials/${material.id}`}>{`${material.code} | ${material.name} x ${data.recipe.quantity}`}</Link>
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Stock">{numberFormatter(material.stock) || "0"}
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Quantity Needed">{numberFormatter(quantityNeeded) || "0"}
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1 text-gray-700">
          <NumberInput
            className={`${isStockNotEnough ? "text-white" : ""}`}
            value={pulled}
            setValue={setPulled}
            label="Pulled From Stock"
          />
        </div>

        <div className="col-span-6 xl:col-span-1 text-gray-700">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Balance Quantity">{numberFormatter(quantityBalance)}
          </TextInputShow>
        </div>

        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <NumberInputWithPrefix
            className={`${isStockNotEnough ? "text-white" : ""}`}
            value={realWeight}
            setValue={setRealWeight}
            label="Real Weight"
            prefix="gram"
          />
        </div>
        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <NumberInputWithPrefix
            prefix="%"
            className={`${isStockNotEnough ? "text-white" : ""}`}
            value={depreciation}
            setValue={setDepreciation}
            label="Depreciation (%)"
          />
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Weight to the Smith">{numberFormatter(totalToTheSmith) || "0"} gram
          </TextInputShow>
        </div>

        <div className="block col-span-12">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    }</>
  )
}
