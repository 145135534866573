import * as React from "react"
import { useState } from "react";
import { SettingLayout } from "../../layouts/Setting"
import { IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import API from "../../utils/api";
import { AxiosError, AxiosResponse } from "axios";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { TaxErrors, TaxInput } from "../../domain_data/tax";
import { TaxForm } from "./Form";



export const NewTax: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<TaxErrors>({});
  const history = useHistory();


  const onSubmit = (data: TaxInput) => {
    setLoading(true);
    API.post("/api/taxes", { tax: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/taxes/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <SettingLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/taxes" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <TaxForm
          data={{
            name: "",
            amount: "",
            isActive: true,
          }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        >

        </TaxForm>
      </div>
    </SettingLayout>
  )
}
