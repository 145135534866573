import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { TextInput } from "../../components/inputs/TextInput"
import { MaterialBalance as MaterialBalanceModel } from "../../domain_data/material";
import { ContentLoading } from "../../components/ContentLoading";
import { Link } from "react-router-dom"
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import { Pagination } from "../../domain_data/pagination";
import { API } from "../../utils";
import { AxiosResponse } from "axios";
import iziToast from "izitoast";
import { debounce } from "lodash"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect";
import { MaterialCategory } from "../../domain_data/material_category";
import { pickBy, forOwn, truncate } from "lodash"

export const MaterialBalance: React.FC = () => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [materialBalances, setMaterialBalances] = React.useState<MaterialBalanceModel[]>([])
  const [itemPerPage, setItemPerPage] = React.useState<number>(20)
  const [page, setPage] = React.useState<number>(1);
  const [categoryId, setCategoryId] = React.useState<number>(null)

  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const fetchBalances = (page, itemPerPage, searchQuery, categoryId) => {
    API.get(`/api/reports/material_balances`, {
      params: pickBy({
        material_category_id: categoryId,
        page: page,
        items: itemPerPage,
        search: searchQuery,
      })
    }).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setPagination(pagination)
      setMaterialBalances(entries)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processing your request"
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const queryString = (): string => {
    const params = {
      page: page,
      items: itemPerPage,
      search: searchQuery,
      material_category_id: categoryId
    }

    let qs = ""
    forOwn(pickBy(params), (value, key) => {
      qs += key + "=" + value + "&"
    })
    return qs
  }

  const debouncedSearchQuery = React.useCallback(debounce((page, itemPerPage, searchQuery, categoryId) => {
    fetchBalances(page, itemPerPage, searchQuery, categoryId)
  }, 500), [])

  React.useEffect(() => {
    if (isLoading) return;

    setIsLoading(true)
    fetchBalances(page, itemPerPage, searchQuery, categoryId);
  }, [page]);

  React.useEffect(() => {
    if (isLoading) return;
    setIsLoading(true)
    setPage(1)
    debouncedSearchQuery(page, itemPerPage, searchQuery, categoryId)
  }, [searchQuery, categoryId]);

  const visitDownloadPage = (any) => {
    const url = `/utilities/reports/material_balances.xlsx?${queryString()}`;
    window.open(url, "_blank");
  }

  return (
    <MainLayout>
      <div className="mt-5 grid grid-cols-12 gap-6 mb-10">
        <div className="grid grid-cols-12 gap-6 col-span-12">
          <div className="col-span-4">
            <TextInput
              label=""
              value={searchQuery}
              setValue={setSearchQuery}
              placeholder="Search material name"
              name="Search"
            />
          </div>

          <div className="col-span-3">
            <SingleAjaxSelect
              url="/api/material_categories"
              name="material_category_id"
              serializeItem={(item: MaterialCategory) => ({ label: item.name, ...item })}
              onListItemClicked={item => {
                setCategoryId(item?.id)
              }}
              placeholder="Select Category"
            />
          </div>

          <div className="col-span-2 col-start-11 text-right">
            <button
              onClick={visitDownloadPage}
              style={{ padding: "10px 20px" }}
              className="mr-2 bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
            >
              <i className="uil uil-download-alt text-lg"></i>
              <span className="ml-2">Save Excel</span>
            </button>
          </div>
        </div>

        <div className="col-span-12">
          <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
                <th className="text-center py-3 px-6">Name</th>
                <th className="text-center py-3 px-6">Total Needed</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Total Ordered</th>
                <th className="text-center py-3 px-6 sm:table-cell hidden">Balance</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-lg font-light w-full">
              {
                isLoading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                  : materialBalances.map(balance => (
                    <tr key={balance.id} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6 text-center">
                        <Link className="hover:underline text-gray-900" to={`/ materials / ${balance.id} `}>
                          <b>{balance.name}</b>
                        </Link>
                      </td>

                      <td className="py-3 px-6 text-center">
                        {balance.totalNeeded}
                      </td>

                      <td className="py-3 px-6 text-center">
                        {balance.totalOrdered}
                      </td>

                      <td className="py-3 px-6 text-center">
                        {balance.balance}
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>

          <br />

          <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
            <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
            <br className="sm:hidden mb-5" />
            <PaginationView loading={isLoading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
              setPage(c => c - 1)
            }} {...pagination} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}