import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { Checkbox } from "../../components/inputs/Checkbox"
import { NumberInputWithPrefix } from "../../components/inputs/NumberInputWithPrefix"
import { TextInput } from "../../components/inputs/TextInput"
import { TaxErrors, TaxInput } from "../../domain_data/tax"
import { DefaultFormProps, normalizeNumber } from "../../utils"


export const TaxForm: React.FC<DefaultFormProps<TaxInput, TaxErrors>> = ({ data, errors, id, onSubmit}: DefaultFormProps<TaxInput, TaxErrors>) => {
  const [name, setName] = useState<string>(data.name)
  const [amount, setAmount] = useState<string>(data.amount)
  const [isActive, setIsActive] = useState<boolean>(data.isActive)

  return(
    <form
      id={id}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit({
          name,
          isActive,
          amount: normalizeNumber(amount).toString()
        })
      }}
    >
      <FormBlock heading="Currency Information" description="This is the general currency information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Name"
              value={name}
              setValue={setName}
              error={errors?.name}
              name="name"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <NumberInputWithPrefix
              label="Amount (in percent)"
              value={amount}
              setValue={setAmount}
              error={errors?.amount}
              name="amount"
              prefix="%"
            />
          </div>
          <div className="col-span-6">
            <Checkbox
              value={isActive}
              setValue={setIsActive}
              label="Is Active?"
              description="if set to active, this tax will be shown in the form dropdown." />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}
