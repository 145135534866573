import * as React from "react"
import { useEffect, useState } from "react";
import { ProductErrors, ProductInput } from "../../domain_data/product";
import { MainLayout } from "../../layouts/Main"
import { useHistory } from "react-router-dom"
import { API, IDgenerator, normalizeNumber } from "../../utils";
import iziToast from "izitoast";
import { AxiosError, AxiosResponse } from "axios";
import { DefaultParamProps } from "../../utils/DefaultProp";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { ProductForm, ProductFormInputProps } from "./Form";
import { ProductVariantInput, productVariantToInput } from "../../domain_data/product_variant";
import { RecipeInput } from "../../domain_data/recipe";
import { ItemNotFound } from "../ItemNotFound";
import { ServiceFeeInput, serviceFeeToInput } from "../../domain_data/service_fee";

export const ProductEdit: React.FC<DefaultParamProps> = ({ match: { params: { id } } }: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductErrors>({});
  const [product, setProduct] = useState<ProductInput>();
  const [productVariants, setProductVariants] = useState<ProductVariantInput[]>([])
  const [recipes, setRecipes] = useState<RecipeInput[]>([])
  const history = useHistory();
  const [notFound, setNotFound] = useState<boolean>(false)
  const [serviceFees, setServiceFees] = useState<ServiceFeeInput[]>([])
  const [submitting, setSubmitting] = useState<boolean>(false)

  const loadServiceFee = () => {
    API.get(`/api/products/${id}/assembly_fees`).then(({ data }) => {
      setServiceFees(data.map(i => serviceFeeToInput(i)))
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch product assembly fee" })
    }).finally(() => {
      setLoading(false)
    })
  }


  const loadRecipes = () => {
    API.get(`/api/products/${id}/recipes?items=100`).then(({ data: { entries} }) => {
      setRecipes(entries)
    }).catch(() => {
      iziToast.error({title: "Error", message: "Failed to fetch product recipes"})
    }).finally(() => {
      loadServiceFee()
    })
  }

  const loadProductVaraint = () => {
    API.get(`/api/products/${id}/product_variants?items=100`).then(({ data: { entries } }) => {
      if (!entries) return;
      setProductVariants(entries.map(i => productVariantToInput(i)))
    }).catch(() => {
      iziToast.error({
        title: "Error",
        message: "Failed to fetch product variants"
      })
    }).finally(() => {
      loadRecipes()
    })
  }

  const loadProduct = () => {
    setLoading(true)
    API.get(`/api/products/${id}`).then(({ data }) => {
      setProduct(data)
      loadProductVaraint()
    }).catch(() => {
     setNotFound(true)
    })
  }


  useEffect(() => {
    loadProduct()
  }, [])



  const onSubmit = (data: ProductFormInputProps) => {
    setSubmitting(true);
    const product = {
      ...data.product,
      images: data.product.images.map(i => i.signedId),
      productVariantsAttributes: data.productVariants.filter(v => v.id || !v.deleted).map(variant => {
        const item = { ...variant, image: variant.image?.signedId }
        if (variant.deleted) {
          item["_destroy"] = true
        }
        return item
      }),
      recipesAttributes: data.recipes,
      assemblyFeesAttributes: data.serviceFees.map(service => ({ ...service, cost: normalizeNumber(service.cost), quantity: normalizeNumber(service.quantity)}))
    }

    API.patch(`/api/products/${id}`, { product }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/products/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setSubmitting(false)
    })
  }
  const formID = IDgenerator();

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      { loading || !product ? <LoadingRoller loading /> :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/products" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <ProductForm data={
              {
                product: product,
                productVariants: productVariants,
                recipes,
                serviceFees
            }
            } id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </MainLayout>
  )
}
