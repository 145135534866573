import iziToast from "izitoast"
import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { MainContext } from "../../context/MainContext"
import { MainLayout } from "../../layouts/Main"
import { useHistory, Link } from "react-router-dom"
import { IDgenerator, normalizeNumber, numberFormatter } from "../../utils"
import { ProductPurchaseOrder, ProductPurchaseOrderErrors } from "../../domain_data/product_purchase_order"
import API from "../../utils/api"
import { productPOItemToInput, ProductPurchaseOrderItemInput } from "../../domain_data/product_purchase_order_item"
import { ItemNotFound } from "../ItemNotFound"
import { FormBlock, FullFormBlock } from "../../components/forms/FormBlock"
import { Material } from "../../domain_data/material"
import { Recipe } from "../../domain_data/recipe"
import { TextInputShow } from "../../components/inputs/TextInputShow"
import { NumberInput } from "../../components/inputs/NumberInput"
import { NumberInputWithPrefix } from "../../components/inputs/NumberInputWithPrefix"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { Product } from "../../domain_data/product"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { AxiosError } from "axios"
import { Office } from "../../domain_data/office"
import { TextInput } from "../../components/inputs/TextInput"
import { Textarea } from "../../components/inputs/Textarea"
import { RouteComponentProps } from "react-router-dom"
import { JoSmithInput } from "../../domain_data/jo_smith"
import { JoSmithItemForm } from "./Utils"

type JobOrderGroupParam = RouteComponentProps<{ jobOrderGroupId: string, id: string }>


export const EditProductPO: React.FC<JobOrderGroupParam> = ({ match: { params: { jobOrderGroupId, id } } }: JobOrderGroupParam) => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductPurchaseOrderErrors>({});
  const [jobOrder, setJobOrder] = useState<ProductPurchaseOrder>(null)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [jobOrderItems, setJobOrderItems] = useState<ProductPurchaseOrderItemInput[]>([])
  const [netWeight, setNetWeight] = useState<string>("")
  const [product, setProduct] = useState<Product>(null)
  const [eta, setEta] = useState<Date>(new Date())
  const [orderDate, setOrderDate] = useState<Date>(new Date())
  const [quantity, setQuantity] = useState<string>("")
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [officeId, setOfficeId] = useState<number>(null)
  const [orderNumber, setOrderNumber] = useState<string>("")
  const [note, setNote] = useState<string>("")
  const [joSmiths, setJoSmiths] = useState<JoSmithInput[]>([])


  const [totalToTheSmith, setTotalToTheSmith] = useState<number>(0)

  const loadPO = () => {
    API.get(`/api/product_purchase_orders/${id}`).then(({ data }) => {
      setJobOrder(data)
      setNetWeight(data.netWeight)
      // setSupplierId(data.supplier.id)
      data.eta && setEta(new Date(data.eta))
      setQuantity(data.quantity)
      setOfficeId(data.officeId)
      setOrderNumber(data.orderNumber)
      data.orderDate && setOrderDate(new Date(data.orderDate))
      setNote(data.note)

    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      loadPOItems()
    })
  }

  const loadPOItems = () => {
    API.get(`/api/product_purchase_orders/${id}/product_po_items`).then(({ data: { entries } }) => {
      setJobOrderItems(entries.map(i => productPOItemToInput(i)))
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  const loadJoSmiths = () => {
    API.get(`/api/product_purchase_orders/${id}/jo_smiths`).then(({ data: { entries } }) => {
      setJoSmiths(entries.map(i => {
        i.orderDate = new Date(i.orderDate);
        i.eta = new Date(i.eta);

        return i
      }))
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch jo smiths" })
    })
  }

  useEffect(() => {
    if (!jobOrder) return

    API.get(`/api/products/${jobOrder.productId}`).then(({ data }) => {
      setProduct(data)
    }).catch(() => {
      setNotFound(true)
    })
  }, [jobOrder])

  useEffect(() => {
    loadPO()
    loadJoSmiths()
  }, [])

  const addJoSmith = (_) => {
    setJoSmiths(j => j.concat({
      orderDate: new Date(),
      eta: new Date(),
      quantity: 1,
      _destroy: false
    }))
  }

  const { setting } = useContext(MainContext)
  const history = useHistory();

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before preceding this process" })
      history.push("/currencies")
    }

  }, [setting])

  if (notFound) return <ItemNotFound />

  const silverUse = normalizeNumber(netWeight) - totalToTheSmith

  const onSubmit = () => {
    if (!jobOrder) return
    const jo = {
      quantity,
      netWeight,
      eta,
      productId: jobOrder.productId,
      orderNumber,
      officeId,
      orderDate,
      note,
      jobOrderGroupId,
      productPoItemsAttributes: jobOrderItems.map(item => ({
        ...item,
        depreciation: normalizeNumber(item.depreciation),
        pulled: normalizeNumber(item.pulled),
        realWeight: normalizeNumber(item.realWeight),
      })),
      joSmithsAttributes: joSmiths
    }

    API.patch(`/api/product_purchase_orders/${id}`, { productPurchaseOrder: jo }).then(() => {
      history.push(`/job_order_groups/${jobOrderGroupId}/job_orders/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      console.log(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setSubmitting(false)
    })

  }

  return (
    <MainLayout>
      {loading || !jobOrder ? <LoadingRoller loading />
        :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>
          <div className="min-h-screen">
            <form id={formID} onSubmit={e => {
              e.preventDefault()
              onSubmit()
            }}>
              <FormBlock heading="PO Information" description="The main po information.">
                <div className="grid grid-cols-6 gap-6">
                  {
                    !product ?
                      <p>Loading...</p>
                      :
                      <Link to={`/products/${product.id}`} className="col-span-6 hover:underline">
                        <p className=" text-lg">Product: {product.sku} | {product?.name}</p>
                      </Link>
                  }
                </div>
              </FormBlock>
              <FormUnderline />
              <FormBlock heading="General Information" description="This is the general Job Order information.">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput label="Order Number" placeholder="Order Number" value={orderNumber} setValue={setOrderNumber} error={errors.orderNumber} />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    {!officeId ?
                      <p>Loading...</p>
                      :
                      <SingleAjaxSelect
                        label="BILL TO"
                        defaultValue={officeId}
                        error={errors?.office}
                        name="office"
                        url="/api/offices"
                        serializeItem={(item: Office) => ({ label: item.name, ...item })}
                        onListItemClicked={item => item && setOfficeId(item?.id)}
                        placeholder="Select Billing Address"
                      />
                    }
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <NumberInput label="Quantity" value={quantity} setValue={setQuantity} />
                  </div>
                  <div className="col-span-6">
                    <Textarea label="Note" value={note} setValue={setNote} error={errors?.note} />
                  </div>
                </div>
              </FormBlock>

              <FullFormBlock heading="Smiths" description="This is the list of supplier(s) that are working on the job order.">
                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    {joSmiths && joSmiths.map((i, key) =>
                      <div className="col-span-12 my-5" key={key}>
                        <JoSmithItemForm joSmith={i} />
                      </div>)}
                  </div>

                  {(errors.joSmithQuantity || errors.joSmithSupplier) && (
                    <div className="col-span-12 grid grid-cols-1">
                      {errors.joSmithQuantity && <p className="text-red-500">The total quantity must be equal to {quantity}</p>}
                      {errors.joSmithSupplier && <p className="text-red-500">The smith needs to be unique</p>}
                    </div>
                  )}

                  <div className="col-span-12 mt-5">
                    <button type="button" className="dark-button-with-text" onClick={addJoSmith}>
                      Add
                    </button>
                  </div>
                </div>
              </FullFormBlock>

              <FullFormBlock heading="Billing of Material" description="This is the list of material needed.">
                <div className="grid grid-cols-6 gap-6">
                  {jobOrderItems && jobOrderItems.map((item, key) => (
                    <JobOrderItem
                      key={key}
                      data={item}
                      productId={jobOrder.productId}
                      orderQuantity={normalizeNumber(quantity)}
                      onChange={data => setTotalToTheSmith(c => c + data)}
                    />
                  ))}
                </div>
                {
                  jobOrder.productId &&
                  <>
                    <div className="grid grid-cols-6 gap-6">
                      <p className="col-span-6 text-lg font-bold">Total Weight to the smith {numberFormatter(totalToTheSmith)} gram(s)</p>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <p className="col-span-6 text-lg font-bold">Silver Use {numberFormatter(silverUse)} gram(s)</p>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <p className="col-span-6 text-lg font-bold">Silver Use/PC {numberFormatter(silverUse / (parseInt(quantity) || 1))} gram(s) Silver/PO</p>
                    </div>
                  </>
                }
              </FullFormBlock>
            </form>
          </div>
        </>
      }

    </MainLayout>
  )
}

export interface JobOrderItemProps {
  data: ProductPurchaseOrderItemInput,
  orderQuantity: number
  onChange: (data: number) => void,
  productId: number
}

export const JobOrderItem: React.FC<JobOrderItemProps> = ({ data, orderQuantity, onChange, productId }: JobOrderItemProps) => {
  const [material, setMaterial] = useState<Material>(null)
  const [pulled, setPulled] = useState<string>(data.pulled)
  const [realWeight, setRealWeight] = useState<string>(data.realWeight)
  const [depreciation, setDepreciation] = useState<string>(data.depreciation)
  const [total, setTotal] = useState<number>(0)
  const [recipe, setRecipe] = useState<Recipe>(null)

  useEffect(() => {
    data.pulled = pulled
    data.realWeight = realWeight
    data.depreciation = depreciation
    onChange(totalToTheSmith - total)
    setTotal(totalToTheSmith)

  }, [pulled, realWeight, depreciation])

  const loadRecipe = () => {
    API.get(`/api/products/${productId}/recipes/${data.recipeId}`).then(({ data }) => {
      setRecipe(data)
    })
      .catch(() => {
        iziToast.error({ title: "Error", message: "Can't find recipe" })
      })
  }

  const loadMaterial = () => {
    API.get(`/api/materials/${data.materialId}`).then(({ data }) => {
      setMaterial(data)
    })
      .catch(() => {
        iziToast.error({ title: "Error", message: "Can't find material" })
      })
  }

  useEffect(() => {
    loadRecipe()
    loadMaterial()
  }, [])

  const isStockNotEnough = normalizeNumber(pulled) > material?.stock

  const totalToTheSmith = normalizeNumber(realWeight) - (normalizeNumber(depreciation) / 100 * normalizeNumber(realWeight))
  const quantityNeeded = orderQuantity * recipe?.quantity
  const quantityBalance = normalizeNumber(pulled) - quantityNeeded

  return (
    <>{!material || !recipe ?
      <div className="col-span-6 grid grid-cols-12 gap-6">
        <div className="col-span-6 sm:col-span-3">
          Loading...
        </div>
      </div>
      :
      <div className={`col-span-6 grid grid-cols-12 gap-6 ${isStockNotEnough ? "bg-red-500 p-2 text-white" : ""}`}>
        <div className="col-span-6 xl:col-span-2 ">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Material">
            <Link to={`/materials/${material.id}`}>{`${material.code} | ${material.name} x ${recipe.quantity}`}</Link>
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Stock">{numberFormatter(material.stock) || "0"}
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Quantity Needed">{numberFormatter(quantityNeeded) || "0"}
          </TextInputShow>
        </div>
        <div className="col-span-6 xl:col-span-1 text-gray-700">
          <NumberInput
            className={`${isStockNotEnough ? "text-white" : ""}`}
            value={pulled}
            setValue={setPulled}
            label="Pulled From Stock"
          />
        </div>

        <div className="col-span-6 xl:col-span-1 text-gray-700">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Balance Quantity">{numberFormatter(quantityBalance)}
          </TextInputShow>
        </div>

        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <NumberInputWithPrefix
            className={`${isStockNotEnough ? "text-white" : ""}`}
            value={realWeight}
            setValue={setRealWeight}
            label="Real Weight"
            prefix="gram"
          />
        </div>
        <div className="col-span-6 xl:col-span-2 text-gray-700">
          <NumberInputWithPrefix
            prefix="%"
            className={`${isStockNotEnough ? "text-white" : ""}`}
            value={depreciation}
            setValue={setDepreciation}
            label="Depreciation (%)"
          />
        </div>
        <div className="col-span-6 xl:col-span-1">
          <TextInputShow
            className={`${isStockNotEnough ? "text-white" : ""}`}
            label="Weight to the Smith">{numberFormatter(totalToTheSmith) || "0"} gram
          </TextInputShow>
        </div>

        <div className="block col-span-12">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    }</>
  )
}
