import iziToast from "izitoast"
import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { NumberInput } from "../../components/inputs/NumberInput"
import { NumberInputWithPrefix } from "../../components/inputs/NumberInputWithPrefix"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { TextInput } from "../../components/inputs/TextInput"
import { MainContext } from "../../context/MainContext"
import { MaterialErrors, MaterialInput } from "../../domain_data/material"
import { MaterialCategory } from "../../domain_data/material_category"
import { Supplier } from "../../domain_data/supplier"
import { DefaultFormProps, moneyFormatter, normalizeNumber, numberFormatter } from "../../utils"
import { useHistory } from "react-router-dom"
import { BaseMaterial } from "../../domain_data/base_material"
import { TextInputShow } from "../../components/inputs/TextInputShow"
import { Tax } from "../../domain_data/tax"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { Textarea } from "../../components/inputs/Textarea"
import { MultipleFilesInput } from "../../components/inputs/MutipleFilesInput"
import { FileBlob } from "../../domain_data/image_attachment"
import PriceCalculator from "../../lib/calculation/calculator"
import { BaseCalculation } from "../../lib/calculation/base"
import { SingleSelect } from "../../components/inputs/SingleSelect"

interface MaterialFormPros<T, I> extends DefaultFormProps<T, I> {
  skipStock?: boolean
}

export const MaterialForm:
  React.FC<MaterialFormPros<MaterialInput, MaterialErrors>> =
  ({
    data,
    onSubmit,
    errors,
    id,
    skipStock
  }: MaterialFormPros<MaterialInput, MaterialErrors>) => {
    const [code, setCode] = useState<string>(data.code || "")
    const [labor, setLabor] = useState<string>(data.labor || "")
    const [status, setStatus] = useState<string>(data.status || "")
    const [minimumQuantity, setMinimumQuantity] = useState<string>(data.minimumQuantity || "")
    const [name, setName] = useState<string>(data.name || "")
    const [note, setNote] = useState<string>(data.note || "")
    const [price, setPrice] = useState<string>(data.price || "")
    const [size, setSize] = useState<string>(data.size || "")
    const [stock, setStock] = useState<string>(data.stock || "")
    const [unit, setUnit] = useState<string>(data.unit || "gram")
    const [weight, setWeight] = useState<string>(data.weight || "")
    const [shape, setShape] = useState<string>(data.shape || "")
    const [baseMaterialId, setBaseMaterialId] = useState<number>(data.baseMaterialId)
    const [materialCategoryId, setMaterialCategoryId] = useState<number>(data.materialCategoryId)
    const [supplierId, setSupplierId] = useState<number>(data.supplierId)
    const [taxId, setTaxId] = useState<number>(data.taxId)
    const [baseMaterial, setBaseMaterial] = useState<BaseMaterial>(null)
    const [tax, setTax] = useState<Tax>(null)
    const [images, setImages] = useState<FileBlob[]>([])
    const [pricePerCarat, setPricePerCarat] = useState<string>(data.pricePerCarat)
    const [priceCalculator, setPriceCalculator] = useState<BaseCalculation>(null)


    const { setting } = useContext(MainContext)
    const history = useHistory();

    useEffect(() => {
      if (!setting) return

      if (!setting.currency) {
        iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
        history.push("/currencies")
      }

    }, [setting])

    useEffect(() => {
      setPriceCalculator(PriceCalculator.execute(normalizeNumber(labor), normalizeNumber(price), normalizeNumber(weight), baseMaterial?.price || 0, tax?.amount || 0))
    }, [baseMaterialId, weight, labor, price, taxId, baseMaterial?.price, tax?.amount])

    const totalInventoryValue = (): number => priceCalculator?.finalPrice * normalizeNumber(stock)

    const defaultImages = (data.images || []).map(i => ({
      attachable_sgid: i.attachableSgid,
      byte_size: i.byteSize,
      checksum: i.checksum,
      content_type: i.contentType,
      filename: i.filename,
      id: i.id,
      service_name: i.serviceName,
      signed_id: i.signedId,
      url: i?.url,
    }));

    const statusOptions = [
      { label: "Active", value: "active", selected: status == "active" },
      { label: "Discontinued", value: "discontinued", selected: status == "discontinued" }
    ];

    return (
      <form
        id={id}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit && onSubmit({
            code,
            labor: normalizeNumber(labor).toString(),
            minimumQuantity: normalizeNumber(minimumQuantity).toString(),
            name,
            note,
            price: normalizeNumber(price).toString(),
            size,
            stock: normalizeNumber(stock).toString(),
            unit,
            weight: normalizeNumber(weight).toString(),
            pricePerCarat: normalizeNumber(pricePerCarat).toString(),
            baseMaterialId,
            materialCategoryId,
            supplierId,
            taxId,
            shape,
            status,
            images: images.map(i => ({
              attachableSgid: i.attachable_sgid,
              byteSize: i.byte_size,
              checksum: i.checksum,
              contentType: i.content_type,
              filename: i.filename,
              id: i.id,
              serviceName: i.service_name,
              signedId: i.signed_id,
              url: i?.url,
            }))
          })
        }}
      >
        <FormBlock heading="Material Information" description="This is the general material information.">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 xl:col-span-3">
              <TextInput
                label="Name"
                value={name}
                setValue={setName}
                error={errors?.name}
                name="name"
              />
            </div>
            <div className="col-span-6 xl:col-span-3">
              <TextInput
                label="Code"
                value={code}
                setValue={setCode}
                error={errors?.code}
                name="code"
              />
            </div>
            <div className="grid grid-cols-12 col-span-6 xl:col-span-3 gap-6">
              <div className="col-span-6 relative">
                <SingleAjaxSelect
                  label="Base Material"
                  defaultValue={baseMaterialId}
                  error={errors?.baseMaterialId}
                  name="base_material_id"
                  url="/api/base_materials"
                  serializeItem={(item: BaseMaterial) => ({ label: item.name, ...item })}
                  onListItemClicked={item => {
                    setBaseMaterial(item)
                    setBaseMaterialId(item?.id)
                  }}
                  onLoad={item => setBaseMaterial(item)}
                  placeholder="Select Base Material"
                />
                {baseMaterial &&
                  <button className="absolute right-1 top-8" type="button" onClick={() => {
                    setBaseMaterialId(null)
                    setBaseMaterial(null)
                  }}>
                    <i className="uil uil-times text-2xl"></i>
                  </button>
                }
              </div>

              {baseMaterial &&
                <div className="col-span-6">
                  <TextInputShow label={`Price per ${baseMaterial.unit}`}>
                    <p>{moneyFormatter(baseMaterial.price, setting?.currency?.code)}</p>
                  </TextInputShow>

                </div>
              }
            </div>

            <div className="grid grid-cols-12 col-span-6 xl:col-span-3 gap-2">
              <div className="col-span-9">
                <NumberInput
                  label="Weight/Unit/PCS"
                  value={weight}
                  setValue={setWeight}
                  error={errors?.weight}
                  name="weight"
                />
              </div>
              <div className="col-span-3">
                <TextInput
                  label="Measurement"
                  value={unit}
                  setValue={setUnit}
                  error={errors?.unit}
                  name="unit_of_measurement"
                  placeholder="e.g. cm"
                />
              </div>
            </div>

            <div className="col-span-6 xl:col-span-3">
              <SingleAjaxSelect
                label="Tax"
                defaultValue={taxId}
                error={errors?.taxId}
                name="tax_id"
                url="/api/taxes"
                serializeItem={(item: Tax) => ({ label: `${item.name} ${item.amount}%`, ...item })}
                onListItemClicked={item => {
                  setTax(item)
                  setTaxId(item?.id)
                }}
                onLoad={item => setTax(item)}
                placeholder="Select Tax"
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              <TextInput
                label="Size"
                value={size}
                setValue={setSize}
                error={errors?.size}
                name="size"
                placeholder="e.g. 2x3, without space"
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              <NumberInputWithPrefix
                label={`Price by PCS/CARAT`}
                value={price}
                setValue={setPrice}
                error={errors?.price}
                name="price"
                prefix={setting?.currency?.code}
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              <TextInput
                label="Shape"
                value={shape}
                setValue={setShape}
                error={errors?.shape}
                name="shape"
                placeholder="e.g. facade, ovale, etc."
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              <NumberInputWithPrefix
                label="Labor"
                value={labor}
                setValue={setLabor}
                error={errors?.labor}
                name="labor"
                prefix={setting?.currency?.code}
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              <NumberInputWithPrefix
                label="Price Per Carat"
                value={pricePerCarat}
                setValue={setPricePerCarat}
                error={errors?.pricePerCarat}
                name="price_per_carat"
                prefix={setting?.currency?.code}
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              <SingleSelect
                label="Status"
                setValue={setStatus}
                name="status"
                placeholder="Select status"
                defaultItem={statusOptions.find((i) => i.value == status)}
                options={statusOptions}

              ></SingleSelect>
            </div>
          </div>

          <div className="block">
            <div className="border-t border-gray-200" />
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-2 font-bold">Final Price</div>
            <p className="font-bold">{moneyFormatter(priceCalculator?.finalPrice/* totalCost() */, setting?.currency?.code)}</p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-2 font-bold">Real Price (For Purchase Order)</div>
            <p className="font-bold">{moneyFormatter(priceCalculator?.realPrice/* realPrice */, setting?.currency?.code)}</p>
          </div>
        </FormBlock>
        <FormUnderline />
        <FormBlock heading="Inventory Information" description="This is the inventory information.">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 xl:col-span-3">
              <NumberInput
                label="Minimum Quantity"
                value={minimumQuantity}
                setValue={setMinimumQuantity}
                error={errors?.minimumQuantity}
                name="minimum_quantity"
              />
            </div>

            <div className="col-span-6 xl:col-span-3">
              {skipStock ?
                <>
                  <TextInputShow label="Stock">
                    <p>{numberFormatter(normalizeNumber(stock))}</p>
                  </TextInputShow>
                </>
                :
                <NumberInput
                  label="Stock"
                  value={stock}
                  setValue={setStock}
                  error={errors?.stock}
                  name="stock"
                />
              }
            </div>

            <div className="col-span-6 xl:col-span-3">
              <SingleAjaxSelect
                label="Category"
                defaultValue={materialCategoryId}
                error={errors?.materialCategoryId}
                name="materialCategoryId"
                url="/api/material_categories"
                serializeItem={(item: MaterialCategory) => ({ label: item.name, ...item })}
                onListItemClicked={item => setMaterialCategoryId(item?.id)}
                placeholder="Select Category"
              />
            </div>
            <div className="col-span-6 xl:col-span-3">
              <SingleAjaxSelect
                label="Default Suplier"
                defaultValue={supplierId}
                error={errors?.supplierId}
                name="supplierId"
                url="/api/suppliers"
                serializeItem={(item: Supplier) => ({ label: item.companyName, ...item })}
                listComponent={(item) => (
                  <>
                    <div className="text-lg">{item.label}</div>
                  </>
                )}
                onListItemClicked={item => setSupplierId(item?.id)}
                placeholder="Select Default Supplier"
              />
            </div>
          </div>
          <>
            <div className="block">
              <div className="border-t border-gray-200" />
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-2 font-bold">Total Value Inventory</div>
              <p className="font-bold">{moneyFormatter(totalInventoryValue() || 0, setting?.currency?.code)}</p>
            </div>
          </>
        </FormBlock>
        <FormUnderline />
        <FormBlock heading="Additional Information" description="This is the additional information.">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <Textarea
                label="Note"
                value={note}
                setValue={setNote}
                error={errors?.note}
                description="Brief description of the material, e.g. what is the purpose"
              />
            </div>
          </div>
        </FormBlock>

        <FormUnderline />

        <FormBlock heading="Image Samples" description="This is the sample images of the material.">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <MultipleFilesInput initialFiles={(defaultImages || [])} onChange={(files) => setImages(files)} error={errors?.images} />
            </div>
          </div>
        </FormBlock>
      </form>
    )
  }
