import { number, string } from "prop-types";
import { Supplier } from "./supplier";

export interface ProductPurchaseOrder {
  id: number;
  eta?: Date
  note: string
  quantity: number
  status: PurchaseOrderStatus
  title: string
  createdAt: Date
  updatedAt: Date
  productId: number
  userId: number
  netWeight: number
  orderNumber: string
  supplier: Supplier
  officeId?: number
  productName?: string
  orderDate?: string
  jobOrderGroupId?: number,
  totalInStock: number,
  quantityBalance: number
}

export enum PurchaseOrderStatus {
  Pending = "pending",
  Doing = "doing",
  Arrived = "arrived"
}

export interface ProductPurchaseOrderInput {
  title: string
  productId: number
  note: string
  quantity: string
  netWeight?: string
  orderNumber: string
  officeId: number
  jobOrderGroupId?: number
}

export interface ProductPurchaseOrderErrors {
  title?: string[]
  productId?: string[]
  eta?: string[]
  note?: string[]
  quantity?: string[]
  supplier?: string[]
  currency?: string[]
  product?: string[]
  netWeight?: string[]
  office?: string[]
  orderNumber?: string[],
  joSmithQuantity?: string[],
  joSmithSupplier?: string[]
}

export interface JobOrderMonthlyReport {
  startDate: string,
  endDate: string,
  total: number
}