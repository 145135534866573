import * as React from "react"
import { useState } from "react";
import { ProductErrors } from "../../domain_data/product";
import { API, IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios";
import { MainLayout } from "../../layouts/Main";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { ProductForm, ProductFormInputProps } from "./Form";
import iziToast from "izitoast";

export const NewProduct: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductErrors>({});
  const history = useHistory();


  const onSubmit = (data: ProductFormInputProps) => {
    const product = {
      ...data.product,
      images: data.product.images.map(i => i.signedId),
      productVariantsAttributes: data.productVariants.filter(v => !v.deleted).map(variant => {
        const item = { ...variant, image: variant.image?.signedId }
        if (variant.deleted) {
          item["_destroy"] = true
        }
        return item
      }),
      recipesAttributes: data.recipes,
      assemblyFeesAttributes: data.serviceFees,
    }
    API.post("/api/products", { product }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/products/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/products" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <ProductForm data={{
          product: {
            name: "",
            images: [],
            sku: ""
          },
          productVariants: [],
          recipes: [],
          serviceFees: []
        }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        />
      </div>
    </MainLayout>
  )
}
