import * as React from "react";
import { IDgenerator } from "../../utils";

interface TextareaProps {
  error?: string[]
  value: string
  label: string
  name?: string
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  description?: string
  setValue: (value: string) => void
}

export const Textarea: React.FC<TextareaProps> = (
  { error,
    value,
    setValue,
    label,
    name,
    placeholder,
    disabled,
    description,
  }: TextareaProps) => {
  const id = name ? name : IDgenerator();
  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <textarea className="px-3 py-3 border focus:outline-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
          defaultValue={value}
          rows={3}
          onChange={(e) => setValue(e.target.value)}
          name={name}
          id={id}
          placeholder={placeholder}
          disabled={!!disabled}
        />
      </div>
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
      {
        description &&
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      }

    </>
  )
}
