import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { useHistory, Link } from "react-router-dom"
import { useContext, useEffect, useState } from "react";
import { ProductPurchaseOrder, PurchaseOrderStatus } from "../../domain_data/product_purchase_order";
import { MainContext } from "../../context/MainContext";
import iziToast from "izitoast";
import { Pagination } from "../../domain_data/pagination";
import { API } from "../../utils";
import { AxiosResponse } from "axios";
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import { ContentLoading } from "../../components/ContentLoading";
import * as dayjs from "dayjs";
import { truncate } from "lodash";
import { RouteComponentProps } from "react-router-dom"
import { ItemNotFound } from "../ItemNotFound";
import { BackButton } from "../../components/BackButton";
import { TextInput } from "../../components/inputs/TextInput";
import { callback } from "chart.js/dist/helpers/helpers.core";

type JobOrderGroupParam = RouteComponentProps<{ jobOrderGroupId: string }>

export const ProductPOList: React.FC<JobOrderGroupParam> = ({ match: { params: { jobOrderGroupId } } }: JobOrderGroupParam) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [purchaseOrders, setPurchaseOrders] = useState<ProductPurchaseOrder[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);
  const [notFound, setNotFound] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [isInitial, setIsInitial] = useState<boolean>(true);


  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const fetchJobOrders = (callback?: () => void) => {
    API.get(`/api/job_order_groups/${jobOrderGroupId}/job_orders?page=${page}&items=${itemPerPage}&search=${searchQuery}`).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setPurchaseOrders(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processiong your request"
      })
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
      callback && callback();
    })
  }

  useEffect(() => {
    setLoading(true);
    fetchJobOrders(() => {
      setIsInitial(false);
    })
  }, [])

  useEffect(() => {
    if (isInitial) return;
    setLoading(true)
    fetchJobOrders()
  }, [page, itemPerPage])

  useEffect(() => {
    if (isInitial) return;
    setPage(1)
    setLoading(true)
    fetchJobOrders()
  }, [searchQuery])

  if (notFound) return <ItemNotFound />

  return (
    <MainLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <BackButton path={`/job_order_groups`} />
        <Link to={`/job_order_groups/${jobOrderGroupId}/job_orders/new`} className="dark-button-with-text max-w-max mb-5 sm:mb-0 ml-3">Create New JO</Link>
      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">

        <div className="mt-5 grid grid-cols-12 gap-6 mb-10">
          <div className="col-span-4">
            <TextInput
              label=""
              value={searchQuery}
              setValue={setSearchQuery}
              placeholder="Search PO number"
              name="Search"
            />
          </div>
        </div>
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">JO Number</th>
              <th className="text-center py-3 px-6">Product</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Quantity</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Stock In</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Balance</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Status</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                :
                purchaseOrders?.map(purchaseOrder => (
                  <tr key={purchaseOrder.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/job_order_groups/${jobOrderGroupId}/job_orders/${purchaseOrder.id}`}>
                        <b>{purchaseOrder.orderNumber}</b>
                      </Link>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/products/${purchaseOrder.productId}`}>
                        <b>{truncate(purchaseOrder.productName)}</b>
                      </Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.quantity}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.totalInStock}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.quantityBalance}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.status == PurchaseOrderStatus.Pending &&
                        <div><span className="bg-yellow-600 inline-block text-white p-2 rounded-md">Pending</span></div>
                      }

                      {purchaseOrder.status == PurchaseOrderStatus.Doing &&
                        <div><span className="bg-green-600 inline-block text-white p-2 rounded-md">In Progress</span></div>
                      }

                      {purchaseOrder.status == PurchaseOrderStatus.Arrived &&
                        <div><span className="bg-indigo-600 inline-block text-white p-2 rounded-md">Finished</span></div>
                      }
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </MainLayout>
  )
}
