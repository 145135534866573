import * as React from "react"
import { Office } from "../../domain_data/office";
import { SettingLayout } from "../../layouts/Setting"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory, Link } from "react-router-dom"
import { useEffect } from "react";
import { API } from "../../utils";
import { ItemNotFound } from "../ItemNotFound";
import iziToast from "izitoast";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { FormBlock } from "../../components/forms/FormBlock";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { Modal } from "../../components/Modal";

export const ShowOffice: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDeleting, setIsdeleting] = React.useState<boolean>(false);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [office, setOffice] = React.useState<Office>()
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/offices/${id}`).then(({ data }) => {
      setOffice(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  if (notFound) return <ItemNotFound />

  const handleDelete = () => {
    setIsdeleting(true)
    API.delete(`/api/offices/${id}`).then(() => {
      history.push("/offices")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this office" })
    }).finally(() => {
      setIsdeleting(false)
    })
  }


  return(
    <SettingLayout>
      {loading || !office || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/offices" />
            <Link className="dark-button-with-text" to={`/offices/${id}/edit`}>Edit</Link>
          </div>
          <div>
            <FormBlock heading="currency" description="This is the general information of the currency.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Company Name" value={office.name} />
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Email" value={office.email} />
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Phone Number" value={office.phoneNumber} />
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Address" value={office.address} />
                </div>

                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Second Line Address" value={office.additionalAddress} />
                </div>
              </div>
            </FormBlock>

            <FormUnderline />
            <Modal
              onDeclined={() => setModalOpen(false)}
              onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this office?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />
            <div className="inline-block mr-3">
              <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
                <div className="w-5 inline-block">
                  <i className="uil uil-trash text-white"></i>
                </div>
                <p className="text-white font-bold ml-2">Delete</p>
              </button>
            </div>
          </div>
        </div>
      }
    </SettingLayout>
  )
}
