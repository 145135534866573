import * as React from "react"
import { useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { MainLayout } from "../../layouts/Main"
import { API, IDgenerator } from "../../utils"
import { MaterialAdjustmentParams } from "./List"
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios"
import { AdjustmentOperation, MaterialAdjustmentErrors, MaterialAdjustmentInput } from "../../domain_data/material_adjustment"
import { MaterialAdjustmentForm } from "./Form"

export const NewMaterialAdjustment: React.FC<MaterialAdjustmentParams> = ({ match: { params: { materialId } } }: MaterialAdjustmentParams ) => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<MaterialAdjustmentErrors>({});
  const history = useHistory();

  const onSubmit = (data: MaterialAdjustmentInput) => {
    setLoading(true);
    API.post(`/api/materials/${materialId}/material_adjustments`, { material_adjustment: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/materials/${materialId}/adjustments/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path={`/materials/${materialId}/adjustments`}/>
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>

      <div className="min-h-screen">
        <MaterialAdjustmentForm data={{ title: "", description: "", quantity: "0", operation: AdjustmentOperation.subtraction }} id={formID} errors={errors} onSubmit={onSubmit} />
      </div>
    </MainLayout>
  )
}
