
import { BaseCalculation } from "./base";
import CastingCalculation from "./casting";
import StoneCalculation from "./stone";

class PriceCalculator {
  static execute(labor: number, priceUnit: number, weight: number, baseMaterialPrice: number, taxAmount: number) : BaseCalculation {
    if (!baseMaterialPrice) {
      return new StoneCalculation(labor, priceUnit, weight, baseMaterialPrice, taxAmount)
    } else {
      return new CastingCalculation(labor, priceUnit, weight, baseMaterialPrice, taxAmount)
    }
  }
}

export default PriceCalculator;
