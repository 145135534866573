import * as React from "react"
import { useContext, useEffect, useState } from "react";
import {  JobOrderGroupErrors } from "../../domain_data/job_order_group";
import { IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import API from "../../utils/api";
import { AxiosError, AxiosResponse } from "axios";
import iziToast from "izitoast";
import { MainContext } from "../../context/MainContext";
import { MainLayout } from "../../layouts/Main";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { JobOrderGroupForm } from "./Form";


export const NewJobOrderGroup: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<JobOrderGroupErrors>();
  const history = useHistory();

  const onSubmit = (data) => {
    API.post("/api/job_order_groups", { jobOrderGroup: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/job_order_groups/${id}/job_orders`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setLoading(false)
    })
  }


  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  return(
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/job_order_groups" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <JobOrderGroupForm data={{name: ""}} id={formID} onSubmit={onSubmit} errors={errors} />
      </div>
    </MainLayout>
  )
}
