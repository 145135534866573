import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { ProductPurchaseOrderErrors } from "../../domain_data/product_purchase_order"
import { MainLayout } from "../../layouts/Main"
import { API, IDgenerator, normalizeNumber } from "../../utils"
import { ProductPOForm, ProductPOInputProps } from "./Form"
import { useHistory } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios"
import iziToast from "izitoast"
import { MainContext } from "../../context/MainContext"
import { RouteComponentProps } from "react-router-dom"

type JobOrderGroupParam = RouteComponentProps<{ jobOrderGroupId: string }>


export const NewProductPO: React.FC<JobOrderGroupParam> = ({ match: { params: { jobOrderGroupId } } }: JobOrderGroupParam) => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductPurchaseOrderErrors>({});
  const history = useHistory();

  const onSubmit = (data: ProductPOInputProps) => {
    const params = {
      ...data.po,
      jobOrderGroupId,
      quantity: normalizeNumber(data.po.quantity),
      netWeight: normalizeNumber(data.po.netWeight),
      productPoItemsAttributes: data.orderItems.map((item => ({
        ...item,
        depreciation: normalizeNumber(item.depreciation),
        pulled: normalizeNumber(item.pulled),
        realWeight: normalizeNumber(item.realWeight),
      }))),
      joSmithsAttributes: data.joSmiths
    }


    API.post("/api/product_purchase_orders", { productPurchaseOrder: params }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/job_order_groups/${jobOrderGroupId}/job_orders/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setLoading(false)
    })
  }

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  return (
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path={`/job_order_groups/${jobOrderGroupId}/job_orders`} />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <ProductPOForm data={{
          po: {
            quantity: "0",
            title: "",
            productId: null,
            note: "",
            netWeight: "0",
            officeId: null,
            orderNumber: "",
            jobOrderGroupId: jobOrderGroupId
          },
          orderItems: [],
          joSmiths: [{ orderDate: new Date(), eta: new Date(), quantity: 1, _destroy: false }]
        }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        />
      </div>
    </MainLayout>
  )
}
