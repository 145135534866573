import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { LoadingRoller } from "../../components";
import { useEffect, useState } from "react";
import { BackButton } from "../../components/BackButton";
import { API, IDgenerator, normalizeNumber } from "../../utils";
import { ProductPORetunForm } from "./Form";
import { ProductPOReturnErrors, ProductPOReturnInput, ProductPOReturnOperation, ProductPOReturnStage } from "../../domain_data/product_po_return";
import { AxiosError } from "axios";
import iziToast from "izitoast";

export type ProductPOReturnParams = RouteComponentProps<{ jobOrderGroupId: string, jobOrderId: string, stage: ProductPOReturnStage }>;

export const NewProductPOReturn: React.FC<ProductPOReturnParams> = ({ match: { params: { jobOrderGroupId, jobOrderId, stage } } }: ProductPOReturnParams) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setError] = useState<ProductPOReturnErrors>({})
  const formID = IDgenerator();
  const history = useHistory();

  const withWeight = [ProductPOReturnStage.Arriving, ProductPOReturnStage.InStock].includes(stage)


  useEffect(() => {
    if (!Object.values(ProductPOReturnStage).includes(stage)) {
      history.push(`/job_order_groups/${jobOrderGroupId}/job_orders/${jobOrderId}`)
    }
  }, [])

  const submit = (data: ProductPOReturnInput) => {
    setLoading(true)
    const form = {
      quantity: normalizeNumber(data.quantity),
      weight: data.weight && normalizeNumber(data.weight),
      dateIn: data.dateIn,
      note: data.note,
      stage: data.stage,
      operation: data.operation,
      supplierId: data.supplierId
    }
    API.post(`/api/product_purchase_orders/${jobOrderId}/product_po_returns`, { productPoReturn: form }).then(() => {
      history.push(`/job_order_groups/${jobOrderGroupId}/job_orders/${jobOrderId}`)
    }).catch(({ response: { data } }: AxiosError) => {
      iziToast.error({ title: "Error", message: "Failed to save the record, please check the form" })
      setError(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path={`/job_order_groups/${jobOrderGroupId}/job_orders/${jobOrderId}`} />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <ProductPORetunForm
          errors={errors}
          id={formID}
          noWeight={!withWeight}
          data={{
            quantity: "",
            weight: "",
            dateIn: new Date(),
            note: "",
            stage: stage,
            operation: ProductPOReturnOperation.In,
          }}
          onSubmit={submit}
        />
      </div>
    </MainLayout>
  )
}
