import * as React from "react";

interface TextInputShowProps {
  label: string
  value?: string
  children?: React.ReactNode
  className?: string
}

export const TextInputShow: React.FC<TextInputShowProps> = ({ label, value, children, className}: TextInputShowProps) => {
  return(
    <>
      <label className={`block text-sm font-medium  ${className || "text-gray-700"}`}>
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm break-all">
        {children ? children : <p className="sm:text-sm break-all">{value}</p>}
      </div>
    </>
  )
}
