import * as axios from "./api"
export { DefaultFormProps, DefaultProp } from "./DefaultProp";
import * as numeral from "numeral"


export const API = axios.default;

export const IDgenerator = (): string => {
    return `react_gen_${Math.random().toString(36).substr(2, 10)}`;
}

export const numberFormatter = (number: number): string => {
  return numeral(number).format("0,0.[00]")
}

export const moneyFormatter = (number: number, currency: string): string => {
  try {
    return Intl.NumberFormat('id-ID', {style: 'currency', currency: currency}).format(number)
  } catch (e) {
    return numberFormatter(number)
  }
}

export const normalizeNumber = (data: number | string): number => {
  const newNumber = (data || 0).toString().replace(/,/g, "")
  return parseFloat(newNumber) || 0
}
