import * as React from "react"
import { Currency } from "../../domain_data/currency";
import { SettingLayout } from "../../layouts/Setting"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory, Link } from "react-router-dom"
import { API, numberFormatter } from "../../utils";
import { ItemNotFound } from "../ItemNotFound";
import { BackButton } from "../../components/BackButton";
import { LoadingRoller } from "../../components";
import { FormBlock } from "../../components/forms/FormBlock";
import { Modal } from "../../components/Modal";
import iziToast from "izitoast";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { TextInputShow } from "../../components/inputs/TextInputShow";

export const ShowCurrency: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDeleting, setIsdeleting] = React.useState<boolean>(false);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [currency, setCurrency] = React.useState<Currency>()
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  React.useEffect(() => {
    setLoading(true)
    API.get(`/api/currencies/${id}`).then(({ data }) => {
      setCurrency(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  if (notFound) return <ItemNotFound />

  const handleDelete = () => {
    setIsdeleting(true)
    API.delete(`/api/currencies/${id}`).then(() => {
      history.push("/currencies")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this currency" })
    }).finally(() => {
      setIsdeleting(false)
    })
  }

  return (
    <SettingLayout>
      { loading || !currency || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/currencies" />
            <Link className="dark-button-with-text" to={`/currencies/${id}/edit`}>Edit</Link>
          </div>
          <div>
            <FormBlock heading="currency" description="This is the general information of the currency.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Code" value={currency.code} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Symbol" value={currency.symbol} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Rate" value={numberFormatter(currency.rate)} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Is active?" value={(!!currency.isActive).toString()} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Is default currency?" value={(!!currency.isDefault).toString()} />
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Is secondary currency?" value={(!!currency.isSecondary).toString()} />
                </div>
              </div>
            </FormBlock>
            <FormUnderline />
            <Modal
              onDeclined={() => setModalOpen(false)}
              onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this currency?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />
            <div className="inline-block mr-3">
              <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
                <div className="w-5 inline-block">
                  <i className="uil uil-trash text-white"></i>
                </div>
                <p className="text-white font-bold ml-2">Delete</p>
              </button>
            </div>
          </div>
        </div>
      }
    </SettingLayout>
  )
}
