import {  AxiosResponse } from "axios";
import iziToast from "izitoast";
import *  as React from "react"
import { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom"
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { Supplier } from "../../domain_data/supplier"
import { MainLayout } from "../../layouts/Main";
import API from "../../utils/api";
import { Modal } from "../../components/Modal";
import { FormBlock } from "../../components/forms/FormBlock";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { ItemNotFound } from "../ItemNotFound";

type SupplierShowProps = RouteComponentProps<{id: string}>

export const SupplierShow: React.FC<SupplierShowProps> = ({ match: { params: { id } } }: SupplierShowProps) => {
  const [supplier, setSupplier] = useState<Supplier>();
  const [ loading, setLoading ] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false)
  const history = useHistory();
  const [notFound, setNotFound] = useState<boolean>(false)


  useEffect(() => {
    setLoading(true)
    API.get(`/api/suppliers/${id}`).then(({data}: AxiosResponse) => {
      setSupplier(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [id])

  const handleDelete = () => {
    setDeleting(true)
    API.delete(`/api/suppliers/${id}`).then(() => {
      history.push("/suppliers")
    }).catch(() => {
      iziToast.error({title: "Error", message: "Failed to Delete this supplier"})
    }).finally(() => {
      setDeleting(false)
    })
  }

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      { loading || isDeleting || !supplier ? <LoadingRoller loading/>
      :
      <>
        <div className="flex justify-between  py-5 items-center">
          <BackButton />
          <Link className="dark-button-with-text" to={`/suppliers/${id}/edit`}>Edit</Link>
        </div>
        <div>
          <div>
            <FormBlock heading="Company Information" description="This is the general company information.">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Company Name" value={supplier?.companyName}/>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Contact Person" value={supplier?.contactFullName} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Email" value={supplier?.contactEmail} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Phone Number" value={supplier?.contactPhoneNumber} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Country" value={supplier?.country} />
                </div>

                <div className="col-span-6">
                  <TextInputShow label="Address" value={supplier?.address} />
                </div>

                <div className="col-span-6">
                  <TextInputShow label="Note" value={supplier?.description} />
                </div>
              </div>
            </FormBlock>
          </div>

          <FormUnderline />

          <div className="mt-10 sm:mt-0">
            <FormBlock heading="Billing Detail" description="The information of the billing address of the company.">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <TextInputShow label="Holder Name" value={supplier?.bankHolderName} />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <TextInputShow label="Bank Name" value={supplier?.bankName} />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <TextInputShow label="Bank Account Number" value={supplier?.bankAccountNumber} />
                </div>

                <div className="col-span-6 sm:col-span-3">
                    <TextInputShow label="Country" value={supplier?.bankCountry} />
                </div>
              </div>
            </FormBlock>
          </div>

          <FormUnderline />

            <div className="mt-10 sm:mt-0">
              <FormBlock heading="Shipping Address" description="The information of the shipping address of the company.">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <TextInputShow label="Country" value={supplier?.shippingCountry} />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <TextInputShow label="Postal Code" value={supplier?.shippingPostalCode} />
                  </div>

                  <div className="col-span-6">
                    <TextInputShow label="Address" value={supplier?.shippingAddress} />
                  </div>
                </div>
              </FormBlock>
            </div>
            <FormUnderline />
            <Modal
              onDeclined={() => setModalOpen(false)}
              onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this supplier?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />

            <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
              <div className="w-5 inline-block">
                <i className="uil uil-trash text-white"></i>
              </div>
              <p className="text-white font-bold ml-2">Delete</p>
            </button>
        </div>
      </>
      }
    </MainLayout>
  )
}
