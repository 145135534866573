import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { Link } from "react-router-dom"
import { SearchComponent } from "../../components/SeachComponent"
import { useHistory } from "react-router-dom"
import { Material } from "../../domain_data/material"
import { useContext, useEffect, useState } from "react"
import { ContentLoading } from "../../components/ContentLoading"
import { ItemPerPage } from "../../components/ItemPerPage"
import { PaginationView } from "../../components/Pagination"
import { API, moneyFormatter, numberFormatter } from "../../utils"
import { AxiosResponse } from "axios"
import iziToast from "izitoast"
import { Pagination } from "../../domain_data/pagination"
import { MainContext } from "../../context/MainContext"
import { pickBy, forOwn, truncate } from "lodash"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { MaterialCategory } from "../../domain_data/material_category"

export const MaterialList: React.FC = () => {
  const { setting } = useContext(MainContext)
  const history = useHistory();
  const [name, setName] = useState<string>("")
  const [weight, setWeight] = useState<string>("")
  const [shape, setShape] = useState<string>("")
  const [size, setSize] = useState<string>("")
  const [categoryId, setCategoryId] = useState<number>(null)
  const [status, setStatus] = useState<string>("")

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])


  const [loading, setLoading] = useState<boolean>(false);
  const [materials, setMaterials] = useState<Material[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const loadMaterials = () => {
    const params = {
      page,
      items: itemPerPage,
      search: name,
      weight,
      shape,
      size,
      material_category_id: categoryId,
      status,
    }

    setLoading(true)
    API.get("/api/materials", {
      params: pickBy(params)
    }).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setMaterials(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processiong your request"
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const applyFilter = () => {
    setPage(0)
    loadMaterials()
  }

  const clearFilter = () => {
    setPage(1)
    setItemPerPage(10)
    setName("")
    setWeight("")
    setShape("")
    setSize("")
    loadMaterials()
  }

  useEffect(() => {
    loadMaterials()
  }, [page, itemPerPage, categoryId, status])

  const queryString = (): string => {
    const params = {
      search: name,
      weight,
      shape,
      size,
      material_category_id: categoryId,
      status
    }

    let qs = ""
    forOwn(pickBy(params), (value, key) => {
      qs += key + "=" + value + "&"
    })
    return qs
  }

  return (
    <MainLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <Link to="/materials/new" className="dark-button-with-text max-w-max mb-5 sm:mb-0">Add New Material</Link>
        <div className="inline-block sm:w-2/5 w-full align-middle sm:ml-10">
          <SearchComponent
            placeholder="Type to search by name or code...."
            url="/api/materials"
            interceptRequest={(entries: Material[]) => entries.map(item => ({ label: `${item.code} - ${item.name}`, ...item }))}
            listComponent={(data) => (
              <>
                <div className="text-lg">{data.label}</div>
              </>
            )}
            onListItemClicked={(data) => {
              history.push(`/materials/${data.id}`)
            }}
          />
        </div>
      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">
        <Link to="/materials/import" className="dark-button-with-text max-w-max mb-5">Import Material</Link>
        <h2 className="text-xl font-bold mb-2">
          <i className="uil uil-sliders-v"></i>
          <span className="ml-2">Filter</span>
        </h2>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-6 sm:col-span-2">
            <input className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Filter by name..."
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <input className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="Filter by weight..."
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <input className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              type="text"
              value={shape}
              onChange={(e) => setShape(e.target.value)}
              placeholder="Filter by shape..."
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <input className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              type="text"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              placeholder="Filter by size..."
            />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <SingleAjaxSelect
              url="/api/material_categories"
              serializeItem={(item: MaterialCategory) => ({ label: item.name, ...item })}
              onListItemClicked={item => {
                setCategoryId(item?.id)
              }}
              placeholder="Select Category"
            />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <select className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              onChange={(e) => setStatus(e.target.value)}
              defaultValue={status}
              placeholder="Filter by size..."
            >
              <option value="">All</option>
              <option value="active">Active</option>
              <option value="discontinued">Discontinued</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-6 mt-3">
          <div className="col-span-1">
            <button
              onClick={applyFilter}
              style={{ padding: "10px 20px" }}
              className="w-full bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
            >
              <i className="uil uil-search text-lg"></i>
              <span className="ml-2">Apply</span>
            </button>

          </div>

          <div className="col-span-1">
            <button
              onClick={clearFilter}
              style={{ padding: "10px 20px" }}
              className="w-full bg-yellow-500 text-white h-auto transition-all inline-block hover:bg-yellow-800 hover:text-white font-bold rounded text-sm"
            >
              <i className="uil uil-times text-lg"></i>
              <span className="ml-2">Clear</span>
            </button>

          </div>
          <div className="col-span-1">
            <a target="_blank" rel="noreferrer" className="w-full dark-button-with-text text-center" href={`/utilities/materials.pdf?${queryString()}`}>
              <i className="uil uil-download-alt text-lg"></i>
              <span className="ml-2">Save PDF</span>
            </a>
          </div>
          <div className="col-span-1">
            <a
              target="_blank"
              rel="noreferrer"
              style={{ padding: "10px 20px" }}
              className="w-full text-center bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
              href={`/utilities/materials.xlsx?${queryString()}`}>
              <i className="uil uil-download-alt text-lg"></i>
              <span className="ml-2">Save Excel</span>
            </a>
          </div>
        </div>
        <div className="mt-5" />
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">Code</th>
              <th className="text-center py-3 px-6">Name</th>
              <th className="text-center py-3 px-6">Status</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Weight</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Shape</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Size</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Stock</th>
              <th className="text-center py-3 px-6 md:table-cell hidden">Total Cost</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                :
                materials?.map(material => (
                  <tr key={material.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/materials/${material.id}`}>{material.code}</Link>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/materials/${material.id}`}>{truncate(material.name)}</Link>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <p className={`inline-block px-3 py-1 text-white rounded-lg ${material.status == 'active' ? 'bg-green-500' : 'bg-yellow-500'}`}>{material.status}</p>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {numberFormatter(material.weight)}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {material.shape}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {material.size}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {numberFormatter(material.stock)}
                    </td>
                    <td className="py-3 px-6 text-center md:table-cell hidden">{moneyFormatter(material.totalCost, setting?.currency?.code)}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </MainLayout>
  )
}
