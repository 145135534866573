import * as React from "react"
import { useEffect, useState } from "react"
import { Office, OfficeErrors, OfficeInput } from "../../domain_data/office"
import { SettingLayout } from "../../layouts/Setting"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory } from "react-router-dom"
import { API, IDgenerator } from "../../utils"
import { AxiosError, AxiosResponse } from "axios"
import { ItemNotFound } from "../ItemNotFound"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { OfficeForm } from "./Form"

export const EditOffice: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<OfficeErrors>({});
  const [office, setOffice] = useState<Office>();
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/offices/${id}`).then(({ data }) => {
      setOffice(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const onSubmit = (data: OfficeInput) => {
    setLoading(true);

    API.patch(`/api/offices/${id}`, { office: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/offices/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (notFound) return <ItemNotFound />

  const formID = IDgenerator();

  return (
    <SettingLayout>
      {loading || !office ? <LoadingRoller loading /> :
        <>
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/offices" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <OfficeForm data={office} id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </SettingLayout>
  )
}
