import * as React from "react";
import { useState } from "react";
import { IDgenerator } from "../../utils";
import API from "../../utils/api";
import { SearchDataEntry } from "../../utils/DefaultProp";
import { debounce, isEmpty } from "lodash"
import { Pagination } from "../../domain_data/pagination";

export interface SingleAjaxSelectProps<T extends SearchDataEntry> {
  error?: string[]
  defaultValue?: number
  label?: string
  name?: string
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serializeItem: (data: any) => T,
  listComponent?: (data: T) => React.ReactNode
  onListItemClicked?: (data: T) => void
  required?: boolean
  onLoad?: (data: T) => void
}

export function SingleAjaxSelect<T extends SearchDataEntry>(
  { error, defaultValue, label, name, placeholder, disabled, url, serializeItem, listComponent, onListItemClicked, required, onLoad }: SingleAjaxSelectProps<T>):
  React.ReactElement {
  const [value, setValue] = useState<string>("")
  const [selectedItem, setSelectedItem] = useState<T>()
  const [loading, setLoading] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [resultOpen, setResultOpen] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const [isInitialLoad, setIsInitialLoad] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (isInitialLoad) return;

    if (!defaultValue) {
      setSelectedItem(null)
    }
  }, [defaultValue])

  const loadingHeight = 70;

  const fetchData = (query: string, page: number, callback: (data: T[]) => void) => {
    API.get(url, { params: { search: query, items: 10, page } }).then(({ data: { entries, pagination } }) => {
      const results = (entries || []).map((entry) => serializeItem(entry));
      if (callback) {
        callback(results)
      }

      setPagination(pagination)

    }).finally(() => {
      setSearching(false)
    })
  }

  const debouncedSearchQuery = React.useCallback(debounce((query, page, callback: (data: T[]) => void) => {
    fetchData(query, page, callback)
  }, 500), [])

  // Fetch inital data
  React.useEffect(() => {
    if (!defaultValue) {
      setIsInitialLoad(false);
      return
    }

    setLoading(true)
    API.get(`${url}/${defaultValue}`).then(({ data }) => {
      const item = serializeItem(data)
      setSelectedItem(item)
      setValue(item.label)
      onLoad && onLoad(item)
    }).finally(() => {
      setIsInitialLoad(false)
      setLoading(false)
    })
  }, [])

  // Search based on keyword
  React.useEffect(() => {
    if (isInitialLoad) return;

    setPage(1)
    if (!value || value == "") {
      onListItemClicked && onListItemClicked(null);
      setSelectedItem && setSelectedItem(null);
      onLoad && onLoad(null)
    }
    if (!resultOpen) return
    setSearching(true)
    const searchParams = value ? value : null;
    debouncedSearchQuery(searchParams, page, results => setSearchResults(results))
  }, [value,])

  // Load for pagination
  React.useEffect(() => {
    if (isInitialLoad) return;
    const searchParams = value ? value : null;
    if (page == pagination.currentPage) return

    setSearching(true)
    debouncedSearchQuery(searchParams, page, results => setSearchResults(current => current.concat(results)))
  }, [page])

  React.useEffect(() => {
    if (isInitialLoad) return;
    setValue(selectedItem?.label)
  }, [selectedItem])

  const paginationHandler = ({ scrollTop, scrollHeight, clientHeight }) => {
    if ((scrollTop + clientHeight + loadingHeight) >= scrollHeight && pagination.nextPage) {
      setPage(pagination.nextPage)
    }
  }

  const handleInputBlur = () => {
    const list = (searchResults || []).map(item => item.label)
    if (!list.includes(value)) {
      setValue(selectedItem?.label)
    }
    setResultOpen(false)
  }

  const handleFirstFocus = () => {
    setResultOpen(true)
    if (!value && isEmpty(searchResults)) {
      setSearching(true)
      debouncedSearchQuery(null, page, results => setSearchResults(results))
    }
  }


  const id = name ? name : IDgenerator();
  return (
    <div className="relative" onBlur={handleInputBlur}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}

      <div className="flex rounded-md shadow-sm">
        {loading ? <div className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300">
          Loading...
        </div> :

          <input className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
            type="text"
            value={value || ""}
            name={name}
            id={id}
            placeholder={placeholder}
            disabled={!!disabled}
            onChange={(e) => setValue(e.target.value)}
            onFocus={handleFirstFocus}
            autoComplete="off"
            required={!!required}
          />
        }
      </div>

      <div className={`${resultOpen ? "h-auto" : "h-0"}
          max-h-96 overflow-y-scroll transition-all absolute bg-white shadow-md rounded overflow-hidden w-full z-10`}
        onScroll={(e) => paginationHandler(e.currentTarget)}
      >
        {isEmpty(searchResults) && !searching && value ? <div className="p-3 hover:bg-gray-200 cursor-pointe">No result found...</div> : null}
        {isEmpty(searchResults) && !searching && !value ? <div className="p-3 hover:bg-gray-200 cursor-pointe">{placeholder || "Type to search..."}</div> : null}

        {
          (searchResults || []).map((item, index) => (
            <div key={index} className="p-3 hover:bg-gray-200 cursor-pointer"
              onMouseDown={() => {
                setSelectedItem(item)
                onListItemClicked && onListItemClicked(item)
              }}
            >
              {listComponent ? listComponent(item) : item.label}
            </div>
          ))
        }

        {searching ? <div className="p-3 hover:bg-gray-200 cursor-pointe">Loading...</div> : null}
      </div>
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
    </div>
  )
}
