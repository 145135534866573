import * as React from "react"
import { useState, useEffect } from "react"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { NumberInput } from "../../components/inputs/NumberInput"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { JoSmithData, JoSmithInput } from "../../domain_data/jo_smith"
import { Supplier } from "../../domain_data/supplier"
import { API, IDgenerator } from "../../utils"
import DatePicker from "react-datepicker"
import * as dayjs from "dayjs"
import iziToast from "izitoast"
import { TextInputShow } from "../../components/inputs/TextInputShow"

interface JoSmithItemFormProps {
  joSmith: JoSmithInput,
}

export const JoSmithItemForm: React.FC<JoSmithItemFormProps> = ({ joSmith }: JoSmithItemFormProps) => {
  const [supplierId, setSupplierId] = useState<number>(joSmith.supplierId)
  const [quantity, setQuantity] = useState<string>(joSmith.quantity?.toString())
  const [orderDate, setOrderDate] = useState<Date>(joSmith.orderDate)
  const [eta, setEta] = useState<Date>(joSmith.eta)
  const [notes, setNotes] = useState<string>(joSmith.notes)
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [isDeleted, setIsDeleted] = useState<boolean>(joSmith._destroy)
  const [outDate, setOutDate] = useState<Date>(joSmith.outDate)

  const assignDataBack = () => {
    joSmith.supplierId = supplierId;
    joSmith.quantity = parseFloat(quantity);
    joSmith.orderDate = orderDate;
    joSmith.eta = eta;
    joSmith.notes = notes;
    joSmith._destroy = isDeleted;
    joSmith.outDate = outDate
  }

  useEffect(() => {
    if (isInitial) return;

    assignDataBack()

  }, [supplierId, quantity, orderDate, eta, notes, isDeleted])

  useEffect(() => {
    setIsInitial(false)
  }, [])

  const id = IDgenerator();

  if (isDeleted) return null;

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-4">
        <SingleAjaxSelect
          label="Supplier"
          defaultValue={supplierId}
          name="supplier"
          url="/api/suppliers"
          serializeItem={(item: Supplier) => ({ label: item.companyName, ...item })}
          onListItemClicked={item => setSupplierId(item?.id)}
          placeholder="Select Supplier"
        />

        <div className="mt-5">
          <NumberInput label="Quantity" value={quantity} setValue={setQuantity} />
        </div>
      </div>

      <div className="col-span-4">
        <div className="w-full ">
          <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
            Out Date
          </label>
          <DatePicker
            dateFormat="dd MMM yyyy"
            selected={outDate}
            onChange={date => setOutDate(date)}
            className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
          />
        </div>

        <div className="w-full  mt-5">
          <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
            Order Date
          </label>
          <DatePicker
            dateFormat="dd MMM yyyy"
            selected={orderDate}
            onChange={date => setOrderDate(date)}
            className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
          />
        </div>
      </div>

      <div className="col-span-4">
        <div className="w-full">
          <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
            ETA Date
          </label>
          <DatePicker
            dateFormat="dd MMM yyyy"
            selected={eta}
            onChange={date => setEta(date)}
            className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
          />
        </div>

        <div className="w-full">
          <label htmlFor={id} className="block text-sm font-medium text-gray-700">Notes</label>

          <div className="flex rounded-md shadow-sm">
            <textarea className="px-3 py-3 border focus:outline-none shadow-sm focus:ring-indigo-500
              focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
              defaultValue={notes}
              onChange={(e) => setNotes(e.target.value)}
              id={id}
              placeholder="notes"
              style={{ height: "138px" }}
            />
          </div>
        </div>
      </div>

      <button className="bg-red-500 py-1 rounded" type="button" onClick={(e) => setIsDeleted(true)}>
        <i className="uil uil-trash text-white text-lg"></i>
      </button>

      <div className="col-span-12">
        <FormUnderline />
      </div>
    </div >
  )
}


export interface JoSmithShowProps {
  item: JoSmithData
}
export const JoSmithShow: React.FC<JoSmithShowProps> = ({ item }: JoSmithShowProps) => {

  const [supplierName, setSupplierName] = useState<string>(null)

  useEffect(() => {
    API.get(`/api/suppliers/${item.supplierId}`).then(({ data }) => {
      setSupplierName(data.companyName)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch supplier" })
    })
  }, [])

  return <React.Fragment>
    <div className="col-span-4">
      <TextInputShow label="Supplier">
        {supplierName ? <p>{supplierName}</p> : <p>Loading....</p>}
      </TextInputShow>
      <div className="mt-5">
        <TextInputShow label="Quantity">
          <p>{item.quantity}</p>
        </TextInputShow>
      </div>
    </div>

    <div className="col-span-4">
      <TextInputShow label="Out Date">
        <p>{item.outDate ? dayjs(item.outDate).format("DD MMMM YYYY") : "-"} </p>
      </TextInputShow>

      <div className="mt-5">
        <TextInputShow label="Order Date">
          <p>{item.orderDate ? dayjs(item.orderDate).format("DD MMMM YYYY") : "-"}</p>
        </TextInputShow>
      </div>
    </div>

    <div className="col-span-4">
      <div >
        <TextInputShow label="Arrival Date">
          <p>{item.eta ? dayjs(item.eta).format("DD MMMM YYYY") : "-"}</p>
        </TextInputShow>
      </div>

      <div className="mt-5">
        <TextInputShow label="Notes">
          <p>{item.notes || "-"}</p>
        </TextInputShow>
      </div>
    </div>

    <div className="col-span-12">
      <FormUnderline />
    </div>
  </React.Fragment >
}