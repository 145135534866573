import { User } from "./user";

export enum AdjustmentOperation {
  addition = "addition",
  subtraction = "subtraction"
}

export interface MaterialAdjustment {
  id: number
  description: string
  operation: AdjustmentOperation
  quantity: number
  title: string
  createdAt: string
  updatedAt: string
  materialId: number
  productAdjustmentId: number
  user: User
}

export interface MaterialAdjustmentInput {
  description: string
  operation: AdjustmentOperation
  quantity: string
  title: string
}

export interface MaterialAdjustmentErrors {
  description?: string[]
  operation?: string[]
  quantity?: string[]
  title?: string[]
  materialId?: string[]
  productAdjustmentId?: string[]
}
