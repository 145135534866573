import * as React from "react"
import { useEffect, useState } from "react"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { MaterialErrors, MaterialInput } from "../../domain_data/material"
import { MainLayout } from "../../layouts/Main"
import { API, IDgenerator } from "../../utils"
import { useHistory } from "react-router-dom"
import { MaterialForm } from "./Form"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { AxiosError, AxiosResponse } from "axios"
import { ItemNotFound } from "../ItemNotFound"

export const MaterialEdit: React.FC<DefaultParamProps> = ({ match: { params: { id } } }: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<MaterialErrors>(null);
  const [material, setMaterial] = useState<MaterialInput>();
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)
  const [submitting, setSubmitting] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/materials/${id}`).then(({ data }) => {
      setMaterial(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const onSubmit = (data: MaterialInput) => {
    setSubmitting(true);
    delete data["stock"]

    API.patch(`/api/materials/${id}`, { material: { ...data, images: data.images.map(i => i.signedId) }}).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/materials/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  if (notFound) return <ItemNotFound />

  const formID = IDgenerator();
  return(
    <MainLayout>
      { loading || !material ? <LoadingRoller loading /> :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/materials" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <MaterialForm data={material} id={formID} errors={errors} onSubmit={onSubmit} skipStock/>
          </div>
        </>

      }
    </MainLayout>
  )
}
