import * as React from "react"
import { useContext, useState } from "react";
import { CurrencyErrors, CurrencyInput } from "../../domain_data/currency";
import { SettingLayout } from "../../layouts/Setting"
import { IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import API from "../../utils/api";
import { AxiosError, AxiosResponse } from "axios";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { CurrencyForm } from "./Form";
import { MainContext } from "../../context/MainContext";

export const NewCurrency: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<CurrencyErrors>({});
  const history = useHistory();
  const { onSettingChanged } = useContext(MainContext)


  const onSubmit = (data: CurrencyInput) => {
    setLoading(true);
    API.post("/api/currencies", { currency: data }).then(({ data: { id, isDefault } }: AxiosResponse) => {

      if (isDefault) {
        onSettingChanged()
      }

      history.push(`/currencies/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return(
    <SettingLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/currencies" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <CurrencyForm
          data={{
            isDefault: false,
            code: "",
            rate: "",
            decimalPlace: "2",
            isActive: true,
            symbol: "",
            isSecondary: false,
          }}
          id={formID}
          onSubmit={onSubmit}
          errors={errors}
        >

        </CurrencyForm>
      </div>
    </SettingLayout>
  )
}
