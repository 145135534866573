import * as React from "react"
import { useEffect, useState } from "react";
import { ProductionReport, ProductionReportErrors, ProductionReportInput, ProductionReportItem } from "../../domain_data/production_report";
import { MainLayout } from "../../layouts/Main"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory } from "react-router-dom"
import { API, IDgenerator } from "../../utils";
import iziToast from "izitoast";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { ProductionReportForm } from "./Form";
import { AxiosError, AxiosResponse } from "axios";
import { ItemNotFound } from "../ItemNotFound";

export const EditProductionReport: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ProductionReport>(null)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [reportItems, setReportItems] = useState<ProductionReportItem[]>([])
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductionReportErrors>({});

  const loadReport = () => {
    setLoading(true)
    API.get(`/api/production_reports/${id}`).then(({ data }) => {
      setReport(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      loadReportItems()
    })
  }

  const loadReportItems = () => {
    API.get(`/api/production_reports/${id}/production_report_items`).then(({ data }) => {
      setReportItems(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to retrieve product report items" })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadReport()
  }, [])
  const formID = IDgenerator();

  const onSubmit = (data: ProductionReportInput) => {
    setSubmitting(true);
    API.patch(`/api/production_reports/${id}`, { productionReport: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/production_reports/${id}`);
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  if (notFound) return <ItemNotFound />

  return (
    <MainLayout>
      {loading || !report ? <LoadingRoller loading /> :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/production_reports" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <ProductionReportForm data={{
              id: report?.id,
              reportNumber: report?.reportNumber,
              productionReportItemsAttributes: reportItems.map(i => ({
                id: i.id,
                jobOrderGroupId: i.jobOrderGroupId,
                productionReportId: i.productionReportId,
                _destroy: false,
              }))
            }}
            id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </MainLayout>
  )
}
