import { Supplier } from "./supplier";
import { SupplierPurchaseOrderItem } from "./supplier_purchase_order_item";

export enum SuplierPurchaseOrderStatus {
  Created = "created",
  Arrived = "arrived",
}

export interface SupplierPurchaseOrder {
  id: number
  status: SuplierPurchaseOrderStatus,
  eta?: string,
  orderDate?: string,
  orderNumber: string
  defaultTotalPrice: number
  currentTotalPrice: number
  supplier: Supplier,
  currency: SupplierPurchaseOrderCurrency
  defaultCurrency: SupplierPurchaseOrderCurrency,
  orderFor?: string
  SupplierPurchaseOrderItems?: SupplierPurchaseOrderItem[],
  createdAt: Date,
  updatedAt: Date,
  officeId?: number,
  materialCount?: number
}

export interface SupplierPurchaseOrderCurrency {
  id?: number
  code: string
  rate: number
}


export interface SupplierPurchaseOrderInput {
  id?: number
  eta?: Date
  orderDate?: Date,
  supplierId?: number
  currencyId?: number
  orderFor?: string
  orderNumber: string
  officeId?: number,
}

export interface SupplierPurchaseOrderErrors {
  eta?: string[]
  supplierId?: string[]
  currency?: string[]
  office?: string[]
}

export const SupplierPurchaseOrderToInput = ({ id, currency, supplier, orderFor, eta, orderNumber, officeId, orderDate }: SupplierPurchaseOrder): SupplierPurchaseOrderInput => {
  return {
    orderFor,
    id,
    supplierId: supplier?.id,
    currencyId: currency?.id,
    eta: eta ? new Date(eta) : null,
    orderDate: orderDate ? new Date(orderDate) : null,
    orderNumber,
    officeId,
  }
}

export interface PurchaseOrderMonthlyReport {
  startDate: string,
  endDate: string,
  total: number
}