import * as React from "react"
import { useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { SingleSelect } from "../../components/inputs/SingleSelect"
import { TextInput } from "../../components/inputs/TextInput"
import { UserErrors, UserInput, UserRole } from "../../domain_data/user"
import { DefaultFormProps } from "../../utils"

export const UserForm: React.FC<DefaultFormProps<UserInput, UserErrors>> = ({ data, errors, id, onSubmit }: DefaultFormProps<UserInput, UserErrors>) => {
  const [email, setEmail] = useState<string>(data.email)
  const [password, setPassword] = useState<string>(data.password)
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>(data.passwordConfirmation)
  const [role, setRole] = useState<UserRole>(data.role)

  return(
    <form
      id={id}
      onSubmit={e => {
        e.preventDefault()
        onSubmit && onSubmit({
          email,
          password,
          passwordConfirmation,
          role
        })
      }}
    >
      <FormBlock heading="User Information" description="This is the general user login information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Email"
              value={email}
              setValue={setEmail}
              error={errors?.email}
              name="name"
              type="email"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <SingleSelect
              label="Role"
              defaultItem={{ label: role, value: role, selected: true }}
              setValue={(item) => {
                if (item == "staff") {
                  setRole(UserRole.Staff)
                } else {
                  setRole(UserRole.Admin)
                }
              }}
              options={[
                { label: "Staff", value: "staff", selected: true },
                { label: "Admin", value: "admin", selected: false }
              ]}
              error={errors?.role}
              name="user_role"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Password"
              value={password}
              setValue={setPassword}
              error={errors?.password}
              name="password"
              type="password"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Password Confirmation"
              value={passwordConfirmation}
              setValue={setPasswordConfirmation}
              error={errors?.passwordConfirmation}
              name="password_confirmation"
              type="password"
            />
          </div>
        </div>
      </FormBlock>
    </form>
  )
}
