import * as React from "react"
import { useEffect, useState } from "react"
import { BackButton } from "../../components/BackButton"
import { MainLayout } from "../../layouts/Main"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { Link } from "react-router-dom"
import { LoadingRoller } from "../../components"
import { API, moneyFormatter, numberFormatter } from "../../utils"
import { SuplierPurchaseOrderStatus, SupplierPurchaseOrder, SupplierPurchaseOrderCurrency } from "../../domain_data/supplier_purchase_order"
import { RemainingPurchaseOrder, SupplierPurchaseOrderItem } from "../../domain_data/supplier_purchase_order_item"
import { ItemNotFound } from "../ItemNotFound"
import { FormBlock } from "../../components/forms/FormBlock"
import { TextInputShow } from "../../components/inputs/TextInputShow"
import * as dayjs from "dayjs"
import { FormUnderline } from "../../components/forms/FormUnderline"
import iziToast from "izitoast"
import { Modal } from "../../components/Modal"
import { useHistory } from "react-router-dom"
import { concat } from "lodash"
import { Office } from "../../domain_data/office"
import { SupplierPOArrival } from "../../domain_data/supplier_po_arrival"
import { RecipeWithMaterial } from "../../domain_data/recipe"

export const ShowSupplierPro: React.FC<DefaultParamProps> = ({ match: { params: { id } } }: DefaultParamProps) => {
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [purchaseOrder, setPurchaseOrder] = React.useState<SupplierPurchaseOrder>(null)
  const [purchaseOrderItems, setPurchaseOrderItems] = React.useState<SupplierPurchaseOrderItem[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const history = useHistory()
  const [office, SetOffice] = useState<Office>()

  const loadPO = () => {
    API.get(`/api/supplier_purchase_orders/${id}`).then(({ data }) => {
      setPurchaseOrder(data)

      if (data.officeId) {
        loadOffice(data.officeId)
      }
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      loadPOItems()
    })
  }

  const loadPOItems = () => {
    API.get(`/api/supplier_purchase_orders/${id}/supplier_po_items`).then(({ data }) => {
      setPurchaseOrderItems(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  const loadOffice = (id) => {
    API.get(`/api/offices/${id}`).then(({ data }) => {
      SetOffice(data)
    })
  }

  useEffect(() => {
    loadPO()
  }, [])

  if (notFound) return <ItemNotFound />

  const confirmArrival = () => {
    API.patch(`/api/supplier_purchase_orders/${id}/arrive`).then(() => {
      history.go(0)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to confirm order arrival, please contect the developer" })
    })
  }

  const totalMaterialCost = (): number => purchaseOrderItems.map(i => i.quantity * i.pricePerUnit).reduce((sum, current) => sum + current, 0)
  return (
    <MainLayout>
      {loading || !purchaseOrder ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/supplier_purchase_orders" />
            <div className="di">
              <Link to={`/supplier_purchase_orders/${id}/edit`} className="dark-button-with-text mr-2">
                <i className="uil uil-edit-alt text-lg"></i>
                <span className="ml-2">Edit</span>
              </Link>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ padding: "10px 20px" }}
                className="mr-2 bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
                href={`/utilities/supplier_purchase_orders/${id}.xlsx`}>
                <i className="uil uil-download-alt text-lg"></i>
                <span className="ml-2">Save Excel</span>
              </a>

              <a target="_blank" rel="noreferrer" className="dark-button-with-text" href={`/utilities/supplier_purchase_orders/${id}`}>
                <i className="uil uil-download-alt text-lg"></i>
                <span className="ml-2">Save PDF</span>
              </a>
            </div>
          </div>
          <div>
            <FormBlock heading="Order Status" description="The shipping status of this order.">
              <div className="grid grid-cols-4 gap-6">
                {purchaseOrder.status == SuplierPurchaseOrderStatus.Created ?
                  <div className="sm:col-span-auto col-span-3"><span className="bg-yellow-600 inline-block text-white p-2 rounded-md">In Progress</span></div>
                  :
                  <div className="sm:col-span-auto col-span-3"><span className="bg-green-600 inline-block text-white p-2 rounded-md">Arrived</span></div>
                }
              </div>

              {purchaseOrder.status == SuplierPurchaseOrderStatus.Created &&
                <>
                  <div className="grid grid-cols-4 gap-6">
                    <div className="sm:col-span-auto col-span-3">
                      <button onClick={() => { setIsModalOpen(true) }} className="dark-button-with-text">Confirm Arrival</button>
                    </div>
                  </div>

                  <Modal
                    onDeclined={() => setIsModalOpen(false)}
                    onAccepted={confirmArrival}
                    label="Are you sure want to set the order status as arrived?"
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                </>

              }
            </FormBlock>

            <FormUnderline />

            <FormBlock heading="Purchase Order" description="This is the general information of the purchase order.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Purchase Order Number">
                    <b>{concat(purchaseOrder?.orderNumber)}</b>
                  </TextInputShow>
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="BILL TO">
                    {office ?
                      <b>
                        <Link to={`/offices/${office.id}`}>{office.name}</Link>
                      </b>
                      :
                      'No Location'
                    }
                  </TextInputShow>
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Supplier Name">
                    {purchaseOrder.supplier ?
                      <Link to={`/suppliers/${purchaseOrder.supplier.id}`}>{purchaseOrder.supplier.companyName}</Link>
                      :
                      `None`
                    }
                  </TextInputShow>
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Currency">
                    {purchaseOrder.currency.code}
                  </TextInputShow>
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Order Date">
                    {purchaseOrder.orderDate && dayjs(purchaseOrder.orderDate).format("DD MMMM YYYY")}
                  </TextInputShow>
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="ETA">
                    {purchaseOrder.eta && dayjs(purchaseOrder.eta).format("DD MMMM YYYY")}
                  </TextInputShow>
                </div>
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Order For">
                    <b>{purchaseOrder.orderFor}</b>
                  </TextInputShow>
                </div>
              </div>
            </FormBlock>

            <FormUnderline />

            <FormBlock heading="Materials" description="This is the list of material ordered.">
              <div className="grid grid-cols-1 gap-6">
                {purchaseOrderItems.map((item, key) => (
                  <RecipeItem
                    key={key}
                    purchaseOrderItem={item}
                    currency={purchaseOrder.currency}
                    defaultCurrency={purchaseOrder.defaultCurrency}
                  />
                ))}
              </div>
              <div className="block">
                <div className="border-t border-gray-200" />
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">Total Purchase Order</div>
                <p className="font-bold">{moneyFormatter((totalMaterialCost() * purchaseOrder.currency.rate) / purchaseOrder.defaultCurrency.rate, purchaseOrder.currency?.code)}</p>
              </div>
            </FormBlock>
            <FormUnderline />
            <RemainingPurchaseOrderSection id={id}/>
            <FormUnderline />
            <ArrivalSection supplierPOId={id} />
          </div>
        </div>
      }
    </MainLayout>
  )

}

interface RecipeItemProps {
  purchaseOrderItem: SupplierPurchaseOrderItem
  currency: SupplierPurchaseOrderCurrency,
  defaultCurrency: SupplierPurchaseOrderCurrency
}

const RecipeItem: React.FC<RecipeItemProps> = ({ purchaseOrderItem: item, currency, defaultCurrency }: RecipeItemProps) => {
  const [materialName, setMaterialName] = useState<string>("")

  useEffect(() => {
    API.get(`/api/materials/${item.materialId}`).then(({ data: { name, code } }) => {
      setMaterialName(`${code} | ${name}`)

    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch material" })
    })
  }, [])
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Material">
          <Link to={`/materials/${item.materialId}`}>{materialName}</Link>
        </TextInputShow>
      </div>
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Quantity" value={`${numberFormatter(item.quantity)} pcs`} />
      </div>
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Cost" value={`${moneyFormatter((item.pricePerUnit * currency.rate) / defaultCurrency.rate, currency?.code)}/pcs`} />
      </div>
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Total" value={`${moneyFormatter((item.pricePerUnit * item.quantity * currency.rate) / defaultCurrency.rate, currency?.code)}`} />
      </div>
      <div className="xl:hidden block col-span-12">
        <div className="border-b" />
      </div>
    </div>
  )
}


interface SupplierArrivalProps {
  supplierPOId: string;
}

const ArrivalSection: React.FC<SupplierArrivalProps> = ({ supplierPOId }: SupplierArrivalProps) => {
  const [arrivals, setArrivals] = useState<SupplierPOArrival[]>([])

  useEffect(() => {
    API.get(`/api/supplier_purchase_orders/${supplierPOId}/supplier_po_arrivals`).then(({ data }) => {
      setArrivals(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch arrivals"})
    })
  }, [])

  return (
    <FormBlock heading="Order Arrivals" description="This is the list of arriverd products.">
      {
        arrivals.map((item, key) => <ArrivalItem item={item} key={key} supplierPOId={supplierPOId}/>)
      }
      <Link className="dark-button-with-text" to={`/supplier_purchase_orders/${supplierPOId}/arrivals/new`}>Add Arrival</Link>
    </FormBlock>
  )
}

interface ArrivalItemProps {
  item: SupplierPOArrival,
  supplierPOId: string
}

const ArrivalItem: React.FC<ArrivalItemProps> = ({ item, supplierPOId }: ArrivalItemProps) => {
  const [recipe, setRecipe] = useState<RecipeWithMaterial>(null)

  useEffect(() => {
    API.get(`/api/supplier_purchase_orders/${supplierPOId}/supplier_po_items/${item.supplierPoItemId}/material`).then(({ data }) => {
      setRecipe(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: `Failed to fetch arrivals id ${item.supplierPoItemId}` })
    })
  }, [])
  return (
    <div className="grid grid-cols-12 gap-6">
      { !recipe ? <>Loading...</>
        :
        <>
          <div className="col-span-6 xl:col-span-3">
            <TextInputShow label="Material">
              <Link to={`/materials/${recipe.materialId}`}>{ recipe.material.code } | { recipe.material.name }</Link>
            </TextInputShow>
          </div>

          <div className="col-span-6 xl:col-span-3">
            <TextInputShow label="Quantity">
              { numberFormatter(item.quantity) }
            </TextInputShow>
          </div>

          <div className="col-span-6 xl:col-span-3">
            <TextInputShow label="Date IN">
              {dayjs(item.dataIn).format('DD MMM YYYY')}
            </TextInputShow>
          </div>

          <div className="col-span-3 md:col-span-1">
            <Link className="dark-button-with-text" to={`/supplier_purchase_orders/${supplierPOId}/arrivals/${item.id}`}>
              <i className="uil uil-edit-alt text-lg"></i>
            </Link>
          </div>

          {
            item.note &&

            <div className="col-span-6 xl:col-span-3">
              <TextInputShow label="">
                <span className="italic text-xs">Note: {item.note}</span>
              </TextInputShow>
            </div>
          }
        </>
      }
    </div>
  )
}

interface RemainingPurchaseOrderProps {
  id: string;
}

const RemainingPurchaseOrderSection: React.FC<RemainingPurchaseOrderProps> = ({ id }: RemainingPurchaseOrderProps) => {
  const [items, setItems] = useState<RemainingPurchaseOrder[]>([])

  useEffect(() => {
    API.get(`/api/supplier_purchase_orders/${id}/supplier_po_items/remainings`).then(({ data }) => {
      setItems(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to fetch material balance"})
    })
  }, [])

  return (
    <FormBlock heading="Material Balance" description="The order material balance.">
      {(items || []).map((item, key) => <RemainingPurchaseOrderItem item={item} key={key}/>)}
    </FormBlock>
  )
}

interface RemainingPurchaseOrderItemProps {
  item: RemainingPurchaseOrder
}

const RemainingPurchaseOrderItem: React.FC<RemainingPurchaseOrderItemProps> = ({ item }: RemainingPurchaseOrderItemProps) => {
  return(
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Material">
          <Link to={`/materials/${item.material.id}`}>{item.material.code} | {item.material.name}</Link>
        </TextInputShow>
      </div>

      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Total Recived">
          { numberFormatter(item.totalReceived) }
        </TextInputShow>
      </div>

      <div className="col-span-6 xl:col-span-3">
        <TextInputShow label="Balance">
          {numberFormatter(item.balance)}
        </TextInputShow>
      </div>
    </div>
  )
}
