import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { API, IDgenerator, normalizeNumber } from "../../utils"
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { SuplierPurchaseOrderStatus, SupplierPurchaseOrder, SupplierPurchaseOrderErrors, SupplierPurchaseOrderToInput } from "../../domain_data/supplier_purchase_order"
import { SupplierPurchaseOrderInputFromItem, SupplierPurchaseOrderItem } from "../../domain_data/supplier_purchase_order_item"
import { ItemNotFound } from "../ItemNotFound"
import { LoadingRoller } from "../../components"
import { SupplierPOForm, SupplierPOInputProps } from "./Form"
import { AxiosError, AxiosResponse } from "axios"
import iziToast from "izitoast"
import { BackButton } from "../../components/BackButton"



export const SupplierPoEdit: React.FC<DefaultParamProps> = ({ match: { params: { id }}}: DefaultParamProps) => {
  const [notFound, setNotFound] = useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [purchaseOrder, setPurchaseOrder] = React.useState<SupplierPurchaseOrder>(null)
  const [purchaseOrderItems, setPurchaseOrderItems] = React.useState<SupplierPurchaseOrderItem[]>([])
  const [errors, setErrors] = useState<SupplierPurchaseOrderErrors>({});
  const [submitting, setSubmitting] = useState<boolean>(false)
  const history = useHistory()


  const loadPO = () => {
    setLoading(true)
    API.get(`/api/supplier_purchase_orders/${id}`).then(({ data }) => {
      setPurchaseOrder(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      loadPOItems()
    })
  }

  const loadPOItems = () => {
    API.get(`/api/supplier_purchase_orders/${id}/supplier_po_items`).then(({ data }) => {
      setPurchaseOrderItems(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadPO()
  }, [])

  if (notFound) return <ItemNotFound />

  const onSubmit = (data: SupplierPOInputProps) => {
    setSubmitting(true)
    const formData = {
      supplierPoItemsAttributes: data.orderItems.map(item => ({ ...item, quantity: normalizeNumber(item.quantity) })),
      ...data.po,
      status: SuplierPurchaseOrderStatus.Created
    }
    API.patch(`/api/supplier_purchase_orders/${id}`, { supplierPurchaseOrder: formData }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/supplier_purchase_orders/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setSubmitting(false)
    })
  }

  const formID = IDgenerator();

  const purchaseOrderItemsInput = () => {
    return purchaseOrderItems.map(i => SupplierPurchaseOrderInputFromItem(i))
  }
  return(
    <MainLayout>
      {loading || !purchaseOrder ? <LoadingRoller loading /> :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/supplier_purchase_orders" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <SupplierPOForm data={{
              po: SupplierPurchaseOrderToInput(purchaseOrder),
              orderItems: purchaseOrderItemsInput(),
            }}
              id={formID}
              onSubmit={onSubmit}
              errors={errors}
            />
          </div>
        </>

      }
    </MainLayout>
  )
}
