import * as React from "react"

export interface LoadingRollerProps {
    loading: boolean
}

export const LoadingRoller: React.FC<LoadingRollerProps> = ({loading}: LoadingRollerProps) => {
    return(
        <div className={ loading ? "loading-roller" : "hidden"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}
