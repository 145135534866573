import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { ContentLoading } from "../../components/ContentLoading"
import { PaginationView } from "../../components/Pagination"
import { Pagination } from "../../domain_data/pagination"
import { API } from "../../utils"
import * as dayjs from "dayjs"
import { AxiosResponse } from "axios"
import iziToast from "izitoast"
import DatePicker from "react-datepicker"
import { Line } from "react-chartjs-2";
import { Chart, ChartData, registerables } from "chart.js"
import { RandomColors } from "../../domain_data/random_color"
import { JobOrderMonthlyReport } from "../../domain_data/product_purchase_order"

Chart.register(...registerables);

export const JobOrderReport: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [reports, setReports] = React.useState<JobOrderMonthlyReport[]>([]);
  const [startDate, setStartDate] = React.useState<Date>(new Date(dayjs().startOf('year').toISOString()))
  const [endDate, setEndDate] = React.useState<Date>(new Date())
  const [isInitialLoad, setIsInitialLoad] = React.useState<boolean>(true);

  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const fetchReports = (callback?: { (): void }) =>
    API.get(`/api/reports/job_order_monthly_by_quantity?start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format("YYYY-MM-DD")}&page=${page}&paginate=true`)
      .then(({ data: { entries, pagination } }: AxiosResponse) => {
        setPagination(pagination)
        setReports(entries)
        updateChartData(entries)
      }).catch(() => {
        iziToast.error({
          title: "Server Error",
          message: "There is a problem processing your request"
        })
      }).finally(() => {
        setIsLoading(false)
        callback && callback()
      })

  React.useEffect(() => {
    setIsLoading(true)
    fetchReports(() => {
      setIsInitialLoad(false);
    })
  }, [])

  React.useEffect(() => {
    if (isInitialLoad) return;
    fetchReports()
  }, [page])

  React.useEffect(() => {
    if (isInitialLoad) return;
    setPage(1)
    fetchReports()
  }, [startDate, endDate])

  const [chartData, setChartData] = React.useState<ChartData>({ labels: [], datasets: [] });

  const updateChartData = (entries: JobOrderMonthlyReport[]) => {
    setChartData({
      labels: entries.map(i => dayjs(i.startDate).format('MMM YYYY')),
      datasets: [
        {
          label: 'Job Orders',
          data: entries.map(i => i.total),
          backgroundColor: RandomColors,
          borderColor: "black",
          borderWidth: 2
        }
      ]
    })
  }

  return (
    <MainLayout>
      <div className="mt-5 grid grid-cols-12 gap-6 mb-10">
        <div className="grid grid-cols-12 gap-6 col-span-12">
          <div className="col-span-6 grid grid-cols-2 gap-6">
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 ">
                Start Date
              </label>
              <DatePicker showMonthYearPicker
                dateFormat="MMM yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
                className="px-3 py-3 border focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              />
            </div>

            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 ">
                End Date
              </label>
              <DatePicker
                showMonthYearPicker
                dateFormat="MMM yyyy"
                selected={endDate}
                onChange={date => setEndDate(date)}
                className="px-3 py-3 border focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              />
            </div>
          </div>

          <div className="col-span-2 col-start-11 text-right">
            <a
              target="_blank"
              rel="noreferrer"
              style={{ padding: "10px 20px" }}
              className="mr-2 bg-green-500 text-white h-auto transition-all inline-block hover:bg-green-800 hover:text-white font-bold rounded text-sm"
              href={`/utilities/reports/job_order_monthly_by_quantity.xlsx?start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format("YYYY-MM-DD")}`}>
              <i className="uil uil-download-alt text-lg"></i>
              <span className="ml-2">Save Excel</span>
            </a>
          </div>
        </div>

        {isLoading ? null : (
          <div className="col-span-12">
            <Line data={chartData} options={{
              plugins: {
                title: {
                  display: true,
                  text: `Job Order between ${dayjs(startDate).format("MMM YYYY")} and ${dayjs(endDate).format("MMM YYYY")}`
                }
              }
            }} />
          </div>
        )}

        <div className="col-span-12">
          <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
                <th className="text-center py-3 px-6">Name</th>
                <th className="text-center py-3 px-6">Total</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-lg font-light w-full">
              {
                isLoading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                  : reports.map(supplier => (
                    <tr key={supplier.startDate} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6 text-center">
                        <b>{dayjs(supplier.startDate).format('MMM YYYY')}</b>
                      </td>

                      <td className="py-3 px-6 text-center">
                        {supplier.total}
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>

          <br />

          <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
            <br className="sm:hidden mb-5" />
            <PaginationView loading={isLoading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
              setPage(c => c - 1)
            }} {...pagination} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}