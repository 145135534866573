import * as React from "react"
import { MenuItem } from "../components/MenuItem"
import { MainContext, Menu } from "../context/MainContext"
import { DefaultProp } from "../utils"

export const SettingLayout: React.FC<DefaultProp> = ({ children }: DefaultProp) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false)

  return(
    <div className="w-full main-layout grid grid-cols-12">
      <div className={`bg-gray-800 min-h-screen col-span-2
          lg:relative fixed lg:right-0 transition-all
          transition-300 ${isSidebarOpen ? "left-0" : "right-full"} z-50`}
      >
        <div className={` ${isSidebarOpen ? "fixed" : "hidden"} bottom-0 left-0 right-0 top-0 z-40`}
          onClick={() => setIsSidebarOpen(false)}
        >

        </div>
        <div className="bg-gray-800 relative z-50 min-h-screen">
          <div className="text-white text-center text-xl font-bold pt-3 pb-4 bg-gray-800">Ananda Soul</div>
          <div className="px-2 mx-auto text-white text-md z-50">
            <MenuList />
          </div>
        </div>
      </div>
      <div className="main-content col-span-12 lg:col-span-10">
        <div className="bg-gray-800 text-white w-full text-right md:px-10 md:pb-4 px-2 py-2 flex justify-end">
          <div className="w-8">
            <button className={`absolute z-50 left-0 lg:hidden lg:ml-0 ml-3 bg-gray-800 ${isSidebarOpen ? "hidden" : ""}`}
              onClick={() => setIsSidebarOpen(c => !c)}
            >
              <i className=" uil uil-bars text-white text-3xl"></i>
            </button>
          </div>
        </div>
        <div className="md:px-10 md:pb-10 px-2 py-2 bg-gray-100 max-w-full">
          {children}
        </div>
      </div>
    </div>
  )
}


const MenuList: React.FC = () => {
  const { activeMenu } = React.useContext(MainContext);

  return(
    <>
      <MenuItem overridesClassContainer="" link="/" active={false}
        icon={
          <i className="uil uil-angle-left-b"></i>
        } label="Home" />

      <MenuItem overridesClassContainer="" link="/currencies" active={activeMenu == Menu.Currencies}
        icon={
          <i className="uil uil-dollar-sign-alt"></i>
        } label="Currencies"/>

      <MenuItem overridesClassContainer="" link="/taxes" active={activeMenu == Menu.Taxes}
        icon={
          <i className="uil uil-bill"></i>
        } label="Taxes" />

      <MenuItem overridesClassContainer="" link="/users" active={activeMenu == Menu.User}
        icon={
          <i className="uil uil-users-alt"></i>
        } label="Staff" />
      <MenuItem overridesClassContainer="" link="/offices" active={activeMenu == Menu.Office}
        icon={
          <i className="uil uil-building"></i>
        } label="Offices" />
    </>
  )
}
