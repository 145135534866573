import * as React from "react";
import { LoadingRoller } from "../components";
import { Dropdown } from "../components/Dropdown";
import { MainContext, Menu } from "../context/MainContext";
import { DefaultProp } from "../utils/DefaultProp";
import logoutIcon from "../../images/logout.svg"
import { API } from "../utils";
import iziToast from "izitoast";
import { MenuWithSubMenu } from "../components/MenuWithSubMenu";
import { MenuItem } from "../components/MenuItem";
import { UserRole } from "../domain_data/user";

export const MainLayout: React.FC<DefaultProp> = ({ children }: DefaultProp) => {
  const { user, setting } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false)

  const logout = () => {
    setLoading(true)
    API.delete("/users/sign_out").then(() => {
      location.href = "/users/sign_in"
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to log out" })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      {!user || !setting || loading ? <LoadingRoller loading={true} /> :
        <div className="w-full main-layout max-w-full grid grid-cols-12">
          <div className={`bg-gray-800 min-h-screen col-span-2
              lg:relative fixed lg:right-0 transition-all
              transition-300 ${isSidebarOpen ? "left-0" : "right-full"} z-50`}
          >
            <div className={` ${isSidebarOpen ? "fixed" : "hidden"} bottom-0 left-0 right-0 top-0 z-40`}
              onClick={() => setIsSidebarOpen(false)}
            >

            </div>
            <div className="bg-gray-800 relative z-50 min-h-screen">
              <div className="text-white text-center text-xl font-bold pt-3 pb-4 bg-gray-800">Ananda Soul</div>
              <div className="px-2 mx-auto text-white text-md z-50">
                <MenuList />
              </div>
            </div>
          </div>
          <div className="main-content col-span-12 lg:col-span-10">
            <div className="bg-gray-800 text-white w-full text-right md:px-10 md:pb-4 px-2 py-2 flex justify-end">
              <div className="w-8">
                <button className={`absolute z-50 left-0 lg:hidden lg:ml-0 ml-3 bg-gray-800 ${isSidebarOpen ? "hidden" : ""}`}
                  onClick={() => setIsSidebarOpen(c => !c)}
                >
                  <i className=" uil uil-bars text-white text-3xl"></i>
                </button>

                <Dropdown
                  label="You"
                  overridesClass="border-none bg-white p-1 rounded-full"
                  header={<div className="text-gray-600">Me</div>}
                  overridesClassChildren="bg-white w-40 logout-dropdown"
                  internal={true}
                >
                  <div className="text-gray-600 text-left py-1 px-3 cursor-text">
                    <p className="text-xs">Email: </p>
                    <p className="text-xs">{user.email}</p>
                  </div>
                  <div className="flex py-1 px-3 cursor-pointer hover:bg-gray-200 content-center" onClick={logout}>
                    <p className="text-red-500">Logout</p>
                    <div className="w-4 flex content-center ml-2">
                      <img className="w-full h-auto" src={logoutIcon} />
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="md:px-10 md:pb-10 px-2 py-2 bg-gray-100 max-w-full">
              {children}
            </div>
          </div>
        </div>
      }
    </>
  )
}


const MenuList: React.FC = () => {
  const { activeMenu, user } = React.useContext(MainContext);

  return (
    <>
      <MenuItem overridesClassContainer="" link="/" active={activeMenu == Menu.Home}
        icon={
          <i className="ui uil-analysis"></i>
        } label="Dashboard" />

      <MenuItem overridesClassContainer="" link="/suppliers" active={activeMenu == Menu.Supplier}
        icon={<i className="uil uil-truck"></i>} label="Suppliers" />

      <MenuWithSubMenu isActive={[Menu.RawMaterial, Menu.MaterialCategories, Menu.BaseMaterial].includes(activeMenu)} overridesClassContainer="" header={
        <MenuItem active={[Menu.RawMaterial, Menu.MaterialCategories].includes(activeMenu)}
          icon={
            <i className="uil uil-gold"></i>
          }
          label="Raw Materials" />
      }>
        <MenuItem link="/base_materials" active={activeMenu == Menu.BaseMaterial}
          icon={<i className="uil uil-gold"></i>} label="Base Material"
          linkClassOverride="text-sm" />

        <MenuItem link="/materials" active={activeMenu == Menu.RawMaterial}
          icon={<i className="uil uil-gold"></i>} label="Materials"
          linkClassOverride="text-sm" />

        <MenuItem link="/material_categories" active={activeMenu == Menu.MaterialCategories} icon={<i className="uil uil-tag-alt"></i>} label="Categories"
          linkClassOverride="text-sm" />
      </MenuWithSubMenu>

      <MenuWithSubMenu isActive={[Menu.Product, Menu.ProductCategory].includes(activeMenu)} overridesClassContainer="" header={
        <MenuItem active={[Menu.Product, Menu.ProductCategory].includes(activeMenu)}
          icon={
            <i className="uil uil-snowflake"></i>
          }
          label="Final Products" />
      }>
        <MenuItem link="/products" active={activeMenu == Menu.Product}
          icon={<i className="uil uil-snowflake"></i>} label="Products"
          linkClassOverride="text-sm" />

        <MenuItem link="/product_categories" active={activeMenu == Menu.ProductCategory} icon={<i className="uil uil-tag-alt"></i>} label="Categories"
          linkClassOverride="text-sm" />
      </MenuWithSubMenu>

      <MenuWithSubMenu isActive={[
        Menu.MaterialBalanceReport, Menu.SupplierReport, Menu.JobOrderReport,
        Menu.PurchaseOrderReport, Menu.SupplierJobOrderReport].includes(activeMenu)} overridesClassContainer="" header={
          <MenuItem active={[Menu.MaterialBalanceReport].includes(activeMenu)}
            icon={
              <i className="uil uil-chart-line"></i>
            }
            label="Reports" />
        }>

        <MenuItem link="/material_balance" active={activeMenu == Menu.MaterialBalanceReport}
          icon={<i className="uil uil-gold"></i>} label="Material Balance"
          linkClassOverride="text-sm" />

        <MenuItem link="/supplier_reports" active={activeMenu == Menu.SupplierReport}
          icon={<i className="uil uil-gold"></i>} label="Supplier Report"
          linkClassOverride="text-sm" />

        <MenuItem link="/job_order_reports" active={activeMenu == Menu.JobOrderReport}
          icon={<i className="uil uil-gold"></i>} label="Job Order Report"
          linkClassOverride="text-sm" />
        <MenuItem link="/purchase_order_reports" active={activeMenu == Menu.PurchaseOrderReport}
          icon={<i className="uil uil-gold"></i>} label="Purchase Order Report"
          linkClassOverride="text-sm" />
        <MenuItem link="/supplier_job_order_reports" active={activeMenu == Menu.SupplierJobOrderReport}
          icon={<i className="uil uil-gold"></i>} label="Supplier Job Order"
          linkClassOverride="text-sm" />

      </MenuWithSubMenu>

      <MenuItem link="/supplier_purchase_orders" active={activeMenu == Menu.SupplierPO}
        icon={<i className="uil uil-shopping-cart-alt"></i>} label="Purchase Orders"
        linkClassOverride="text-sm" />

      <MenuItem link="/job_order_groups" active={activeMenu == Menu.JobOrder}
        icon={<i className="uil uil-constructor"></i>} label="Job Order Groups"
        linkClassOverride="text-sm" />
      <MenuItem link="/production_reports" active={activeMenu == Menu.ProductionReport}
        icon={<i className="uil uil-file-check-alt"></i>} label="Production Reports"
        linkClassOverride="text-sm" />
      {user?.role == UserRole.Admin &&
        <MenuItem overridesClassContainer="" link="/currencies" active={false}
          icon={
            <i className="uil uil-setting"></i>
          } label="Business Setting" />
      }
    </>
  )
}
