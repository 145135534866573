export interface ProductPOReturn {
  id: number
  weight: number
  dateIn: string
  quantity: number
  note?: string
  stage: ProductPOReturnStage
  operation: ProductPOReturnOperation
}


export interface ProductPOReturnInput {
  id?: number
  weight: string
  dateIn: Date
  quantity: string
  note?: string
  stage: ProductPOReturnStage
  operation: ProductPOReturnOperation
  supplierId?: number
}

export enum ProductPOReturnStage {
  Arriving = "arriving",
  InService = "in_service",
  Plating = "plating",
  QcHouse = "qc_house",
  InStock = "in_stock",
}

export enum ProductPOReturnOperation {
  In = "in",
  Out = "out",
}

export interface ProductPOReturnErrors {
  weight?: string[]
  dateIn?: string[]
  quantity?: string[]
  note?: string[]
  stage?: string[]
  operation?: string[]
}
