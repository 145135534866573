import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Image from "../../images/previous.svg"


interface BackButtonProps {
    path?: string
}

export const BackButton: React.FC<BackButtonProps> = ({ path }: BackButtonProps) => {
    const history = useHistory();

    const followPath = () => {
        if (path) {
            history.push(path)
        } else {
            history.goBack()
        }
    }
    return(
        <button onClick={followPath} className="w-5 rounded-full border-none hover:bg-transparent touchable-back-button">
            <img src={Image} alt=""/>
        </button>
    )
}
