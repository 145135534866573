import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { NumberInput } from "../../components/inputs/NumberInput"
import { NumberInputWithPrefix } from "../../components/inputs/NumberInputWithPrefix"
import { TextInput } from "../../components/inputs/TextInput"
import { MainContext } from "../../context/MainContext"
import { ServiceFeeInput } from "../../domain_data/service_fee"
import { moneyFormatter, normalizeNumber } from "../../utils"

interface ServiceFeeFormProps {
  fee: ServiceFeeInput,
  onChange?: (data: number) => void
}

export const ServiceFeeForm: React.FC<ServiceFeeFormProps> = ({ fee, onChange }: ServiceFeeFormProps) => {
  const [name, setName] = useState<string>(fee.name)
  const [cost, setCost] = useState<string>(fee.cost)
  const [quantity, setQuantity] = useState<string>(fee.quantity)
  const [isDeleted, setIsDeleted] = useState<boolean>(fee._destroy)
  const [totalCost, setTotalCost] = useState<number>(0)
  const { setting } = useContext(MainContext)

  useEffect(() => {
    const costNow = normalizeNumber(quantity) * normalizeNumber(cost)

    if (totalCost == 0) {
      onChange && onChange(costNow)
    } else {
      onChange && onChange(costNow - totalCost)
    }

    setTotalCost(costNow)

    fee.quantity = quantity
    fee.cost = cost

  }, [quantity, cost])

  useEffect(() => {
    fee.name = name
  }, [name])

  const handleDelete = () => {
    onChange && onChange(-totalCost)
    fee._destroy = true
    setIsDeleted(true)
  }

  if (isDeleted) return null;

  return(
    <div className="col-span-6 grid grid-cols-12 gap-6">
      <div className="col-span-6 xl:col-span-3">
        <TextInput
          label="Name"
          value={name}
          setValue={setName}
          // error={errors?.sku}
          name="name"
          required
        />
      </div>

      <div className="col-span-6 xl:col-span-3">
        <NumberInputWithPrefix
          label="Cost"
          value={cost}
          setValue={setCost}
          // error={errors?.price}
          name="cost"
          prefix={setting?.currency.code}
        />
      </div>
      <div className="col-span-6 xl:col-span-2">
        <NumberInput label="QTY" value={quantity} setValue={setQuantity} />
      </div>
      <div className="col-span-4 xl:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Cost</label>
        <div className="mt-1 flex rounded-md">
          <p className="sm:text-sm">{moneyFormatter(totalCost, setting?.currency?.code)}</p>
        </div>
      </div>
      <div className="col-span-2 self-center">
        <button type="button" className="bg-red-500 rounded-md text-white p-3" onClick={handleDelete}>
          <i className="uil uil-trash"></i>
        </button>
      </div>
      <div className="block xl:hidden col-span-12">
        <div className="border-t border-gray-200" />
      </div>
    </div>
  )
}
