import * as React from "react";

export const FormUnderline: React.FC = () => {
  return(
    <>
      <div className="sm:hidden block mt-5" />
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </>
  )
}
