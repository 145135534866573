import * as React from "react"
import { MainLayout } from "../../layouts/Main"
import { useHistory, Link } from "react-router-dom"
import { useContext, useEffect, useState } from "react";
import { SuplierPurchaseOrderStatus, SupplierPurchaseOrder } from "../../domain_data/supplier_purchase_order";
import { MainContext } from "../../context/MainContext";
import iziToast from "izitoast";
import { Pagination } from "../../domain_data/pagination";
import { API } from "../../utils";
import { AxiosResponse } from "axios";
import { ContentLoading } from "../../components/ContentLoading";
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import * as dayjs from "dayjs";
import { concat } from "lodash"
import { TextInput } from "../../components/inputs/TextInput";
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect";
import { Material } from "../../domain_data/material";

export const SupplierPOList: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [supplier_purchase_orders, setSupplierPurchaseOrders] = useState<SupplierPurchaseOrder[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);
  const [material, setMaterial] = useState<Material>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const fetchData = () => {
    const materialId = material?.id ? material?.id : "";
    API.get(`/api/supplier_purchase_orders?page=${page}&items=${itemPerPage}&search=${searchQuery}&material_id=${materialId}`).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setSupplierPurchaseOrders(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processing your request"
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [page])

  useEffect(() => {
    setPage(1)
    setLoading(true)
    fetchData()
  }, [itemPerPage, searchQuery, material])

  return (
    <MainLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <Link to="/supplier_purchase_orders/new" className="dark-button-with-text max-w-max mb-5 sm:mb-0">Create New PO</Link>
      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">
      <div className="mt-5 grid grid-cols-12 gap-6 mb-10">
          <div className="col-span-4">
            <TextInput
                label=""
                value={searchQuery}
                setValue={setSearchQuery}
                placeholder="Search PO number"
                name="Search"
              />
          </div>

          <div className="col-span-4 col-start-10">
            <SingleAjaxSelect
                label=""
                name="tax_id"
                url="/api/materials"
                serializeItem={(item: Material) => ({ label: `${item.name}`, ...item })}
                onListItemClicked={item => {
                  setMaterial(item)
                }}
                placeholder="Select Material"
              />
          </div>
        </div>
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">PO Number</th>
              <th className="text-center py-3 px-6">Supplier Name</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Order Date</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Arival Date</th>
              <th className="text-center py-3 px-6 sm:table-cell hidden">Status</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                :
                supplier_purchase_orders?.map(purchaseOrder => (
                  <tr key={purchaseOrder.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/supplier_purchase_orders/${purchaseOrder.id}`}>
                        <b>{concat(purchaseOrder?.orderNumber)}</b>
                      </Link>
                      {
                        purchaseOrder.materialCount && <p className="text-sm">{`Total: ${purchaseOrder.materialCount} items`}</p>
                      }
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Link className="hover:underline text-gray-900" to={`/supplier_purchase_orders/${purchaseOrder.id}`}>{purchaseOrder?.supplier?.companyName}</Link>
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.orderDate && dayjs(purchaseOrder.orderDate).format('DD MMM YYYY')}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.eta && dayjs(purchaseOrder.eta).format('DD MMM YYYY')}
                    </td>
                    <td className="py-3 px-6 text-center sm:table-cell hidden">
                      {purchaseOrder.status == SuplierPurchaseOrderStatus.Created ?
                        <div><span className="bg-yellow-600 inline-block text-white p-2 rounded-md">In Progress</span></div>
                        :
                        <div><span className="bg-green-600 inline-block text-white p-2 rounded-md">Arrived</span></div>
                      }
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </MainLayout>
  )
}
