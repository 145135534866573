import * as React from "react"
import { useState } from "react";
import { ProductionReportErrors, ProductionReportInput } from "../../domain_data/production_report";
import { MainLayout } from "../../layouts/Main"
import { IDgenerator } from "../../utils";
import { useHistory } from "react-router-dom"
import { ProductionReportForm } from "./Form";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import API from "../../utils/api";
import { AxiosError, AxiosResponse } from "axios";

export const NewProductionReport: React.FC = () => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProductionReportErrors>({});
  const history = useHistory();

  const onSubmit = (data: ProductionReportInput) => {
    setLoading(true);
    API.post(`/api/production_reports`, { productionReport: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/production_reports/${id}`);
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <MainLayout>
      <LoadingRoller loading={loading} />
      <div className="flex justify-between py-5 items-center">
        <BackButton path="/production_reports" />
        <button type="submit" form={formID} className="dark-button-with-text">Save</button>
      </div>
      <div className="min-h-screen">
        <ProductionReportForm
          id={formID}
          errors={errors}
          onSubmit={onSubmit}
          data={{
            reportNumber: "",
            productionReportItemsAttributes: [{
              jobOrderGroupId: null,
              productionReportId: null,
              _destroy: false,
            }]
          }}
        />
      </div>
    </MainLayout>
  )
}
