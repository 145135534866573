

class BaseCalculation {
  labor: number
  priceUnit: number
  weight: number
  baseMaterialPrice: number
  taxAmount: number

  constructor(labor: number, priceUnit: number, weight: number, baseMaterialPrice: number, taxAmount: number) {
    this.labor = labor || 0;
    this.priceUnit = priceUnit || 0;
    this.weight = weight || 0;
    this.baseMaterialPrice = baseMaterialPrice || 0;
    this.taxAmount = taxAmount || 0;
  }

  get finalPrice() : number {
    // Need to overide in every subclass
    return 0;
  }

  // Real Price (For Purchase Order)
   get realPrice() : number {
    // Need to overide in every subclass
    return 0;

  }
}

export { BaseCalculation}
