import { AxiosResponse } from "axios";
import iziToast from "izitoast";
import * as React from "react"
import { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom"
import { ContentLoading } from "../../components/ContentLoading";
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import { MainContext } from "../../context/MainContext";
import { JobOrderGroup } from "../../domain_data/job_order_group";
import { Pagination } from "../../domain_data/pagination";
import { MainLayout } from "../../layouts/Main";
import { API } from "../../utils";
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect";
import { Product } from "../../domain_data/product";
import { TextInput } from "../../components/inputs/TextInput";

export const JobOrderGropList: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [jobOrderGroups, setJobOrderGroups] = useState<JobOrderGroup[]>([])
  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1);
  const [product, setProduct] = useState<Product>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const fetchJobGroup = () => {
    const productId = !product?.id ? "" : product?.id;
    API.get(`/api/job_order_groups?page=${page}&items=${itemPerPage}&search=${searchQuery}&product_id=${productId}`).then(({ data: { entries, pagination } }: AxiosResponse) => {
      setJobOrderGroups(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processing your request"
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchJobGroup();
  }, [page])

  useEffect(() => {
    setPage(1);
    setLoading(true)
    fetchJobGroup();
  }, [itemPerPage, searchQuery, product])

  return(
    <MainLayout>
      <div className="py-5 flex sm:flex-row flex-col">
        <Link to="/job_order_groups/new" className="dark-button-with-text max-w-max mb-5 sm:mb-0">Add Group</Link>
      </div>
      <div className="bg-white md:p-10 p-1.5 rounded shadow-md">

        <div className="mt-5 grid grid-cols-12 gap-6 mb-10">
          <div className="col-span-4">
          <TextInput
              label=""
              value={searchQuery}
              setValue={setSearchQuery}
              placeholder="Search group name"
              name="Search"
            />
          </div>

          <div className="col-span-4 col-start-10">
            <SingleAjaxSelect
                label=""
                name="tax_id"
                url="/api/products"
                serializeItem={(item: Product) => ({ label: `${item.name}`, ...item })}
                onListItemClicked={item => {
                  setProduct(item)
                }}
                placeholder="Select Product"
              />
          </div>
        </div>
        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-left py-3 px-6">Name</th>
              <th className="text-left py-3 px-6"></th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
                :
                jobOrderGroups?.map(jobOrderGroup => (
                  <tr key={jobOrderGroup.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-left">
                      <Link className="hover:underline text-gray-900" to={`/job_order_groups/${jobOrderGroup.id}/job_orders`}>
                        <b>{jobOrderGroup.name}</b>
                      </Link>

                      {jobOrderGroup.productCount ? <p className="text-sm">{` Total: ${jobOrderGroup.productCount} products`}</p> : ''}
                    </td>
                    <td className="py-3 px-6 text-left">
                      <Link className="hover:underline text-gray-900" to={`/job_order_groups/${jobOrderGroup.id}/edit`}>
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n)} />
          <br className="sm:hidden mb-5" />
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination} />
        </div>
      </div>
    </MainLayout>
  )
}
