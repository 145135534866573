import * as React from "react"
import { LoadingRoller } from "../../components"
import { MainLayout } from "../../layouts/Main"
import { RouteComponentProps, Link, useHistory } from "react-router-dom"
import { BackButton } from "../../components/BackButton";
import { FormBlock } from "../../components/forms/FormBlock";
import { Modal } from "../../components/Modal";
import { Material } from "../../domain_data/material";
import { API, moneyFormatter, normalizeNumber, numberFormatter } from "../../utils";
import iziToast from "izitoast";
import { TextInputShow } from "../../components/inputs/TextInputShow";
import { FormUnderline } from "../../components/forms/FormUnderline";
import { AxiosResponse } from "axios";
import { ItemNotFound } from "../ItemNotFound";
import { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainContext";
import { BaseMaterial } from "../../domain_data/base_material";
import { Tax } from "../../domain_data/tax";
import { MultipleFileShow } from "../../components/inputs/MultipleFileShow";
import PriceCalculator from "../../lib/calculation/calculator";
import { BaseCalculation } from "../../lib/calculation/base";

export type MaterialShowProps = RouteComponentProps<{ id: string }>;

export const MaterialShow: React.FC<MaterialShowProps> = ({ match: { params: { id } }}: MaterialShowProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDeleting, setIsdeleting] = React.useState<boolean>(false);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [material, setMaterial] = React.useState<Material>()
  const [category, setCategory] = React.useState<string>("Loading...");
  const [company, setCompany] = React.useState<string>("Loading...");
  const [baseMaterial, setBaseMaterial] = React.useState<BaseMaterial>(null)
  const [tax, setTax] = React.useState<Tax>(null)
  const [priceCalculator, setPriceCalculator] = React.useState<BaseCalculation>(null)

  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!material?.taxId) {
      return;
    } else {
      setCategory("Loading...")
      API.get(`/api/taxes/${material?.taxId}`).then(({ data }: AxiosResponse) => {
        setTax(data)
      })
    }
  }, [material])

  React.useEffect(() => {
    if (!material?.baseMaterialId) {
      return;
    } else {
      setCategory("Loading...")
      API.get(`/api/base_materials/${material?.baseMaterialId}`).then(({ data }: AxiosResponse) => {
        setBaseMaterial(data)
      })
    }
  }, [material])

  React.useEffect(() => {
    if (!material?.materialCategoryId) {
      setCategory("None")
      return;
    } else {
      setCategory("Loading...")
      API.get(`/api/material_categories/${material?.materialCategoryId}`).then(({ data: { name } }: AxiosResponse) => {
        setCategory(name)
      }).catch(() => {
        setCategory("None")
      })
    }
  }, [material])

  React.useEffect(() => {
    if (!material?.supplierId) {
      setCompany("None")
      return;
    } else {
      setCompany("Loading...")
      API.get(`/api/suppliers/${material?.supplierId}`).then(({ data: { companyName } }: AxiosResponse) => {
        setCompany(companyName)
      }).catch(() => {
        setCompany("None")
      })
    }
  }, [material])


  React.useEffect(() => {
    setPriceCalculator(PriceCalculator.execute(material?.labor, material?.price, material?.weight, baseMaterial?.price, tax?.amount))
  }, [material?.price, tax?.amount, baseMaterial?.price])

  const handleDelete = () => {
    setIsdeleting(true)
    API.delete(`/api/materials/${id}`).then(() => {
      history.push("/materials")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this material" })
    }).finally(() => {
      setIsdeleting(false)
    })
  }

  React.useEffect(() => {
    setLoading(true)
    API.get(`/api/materials/${id}`).then(({ data }) => {
      setMaterial(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const { setting } = useContext(MainContext)

  useEffect(() => {
    if (!setting) return

    if (!setting.currency) {
      iziToast.info({ title: "Info", message: "You need to set the currency before perceeding this process" })
      history.push("/currencies")
    }

  }, [setting])


  if (notFound) return <ItemNotFound />


  const totalInventoryValue = (): number => priceCalculator?.finalPrice * normalizeNumber(material?.stock)


  return (
    <MainLayout>
      { loading || !material || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton path="/materials" />
            <Link className="dark-button-with-text" to={`/materials/${id}/edit`}>Edit</Link>
          </div>
          <div>
            <FormBlock heading="Material Information" description="This is the general material information.">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Material Name" value={material.name + `${material.status == 'discontinued' ? ' (DISCONTINUED)' : ''}`} />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Code" value={material.code} />
                </div>

                {material.baseMaterialId &&
                  <div className="grid grid-cols-12 col-span-6 sm:col-span-3 gap-6">
                    <div className="col-span-6">
                      <TextInputShow label="Base Material" value={baseMaterial?.name || "Loading..."} />
                    </div>
                    <div className="col-span-6">
                      <TextInputShow label={`Price per ${baseMaterial?.unit}`} value={moneyFormatter(baseMaterial?.price, setting?.currency?.code) || "Loading..."} />
                    </div>
                  </div>
                }
                {material.taxId &&
                  <div className="col-span-6 md:col-span-3">
                    <TextInputShow label="Tax" value={tax ? `${tax.amount} %` : "Loading..."} />
                  </div>
                }

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Weight/Unit/PCS" value={`${numberFormatter(material.weight)} ${material.unit}`} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Size" value={material.size} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Shape" value={material.shape} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label={`Price by ${material.unit}`} value={moneyFormatter(material.price, setting?.currency?.code)} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Labor" value={moneyFormatter(material.labor, setting?.currency?.code)} />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Price Per Carat" value={moneyFormatter(material.pricePerCarat, setting?.currency?.code)} />
                </div>
              </div>

              <div className="block">
                <div className="border-t border-gray-200" />
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">Final Price</div>
                <p className="font-bold">{moneyFormatter(priceCalculator?.finalPrice, setting?.currency?.code)}</p>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 font-bold">Real Price (For Purchase Order)</div>
                <p className="font-bold">{moneyFormatter(priceCalculator?.realPrice, setting?.currency?.code)}</p>
              </div>

            </FormBlock>
            <FormUnderline />
            <FormBlock heading="Inventory Information" description="This is the inventory information.">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Minimum Quantity" value={numberFormatter(material.minimumQuantity)} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Stock" value={numberFormatter(material.stock)} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Category" value={category} />
                </div>

                <div className="col-span-6 md:col-span-3">
                  <TextInputShow label="Supplier" value={company} />
                </div>
              </div>

              <>
                <div className="block">
                  <div className="border-t border-gray-200" />
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-2 font-bold">Total Value Inventory</div>
                  <p className="font-bold">{moneyFormatter(totalInventoryValue() || 0, setting?.currency?.code)}</p>
                </div>
              </>

            </FormBlock>
            <FormUnderline />
            <FormBlock heading="Additional Information" description="This is the additional information.">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <TextInputShow label="Note" value={material.note} />
                </div>
              </div>
            </FormBlock>

            <FormUnderline />

            <FormBlock heading="Sample Images" description="This is the product sample images.">
              <div className="grid grid-cols-1 gap-6">
                {material.images ? <MultipleFileShow files={material.images} />
                  :
                  <div className="">
                    <i className="uil uil-image-alt-slash text-7xl text-gray-400"></i>
                    <p className="text-gray-700">No image provided</p>
                  </div>
                }
              </div>
            </FormBlock>
            <Modal
              onDeclined={() => setModalOpen(false)}
             onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this material?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />
            <div className="inline-block mr-3">
              <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
                <div className="w-5 inline-block">
                  <i className="uil uil-trash text-white"></i>
                </div>
                <p className="text-white font-bold ml-2">Delete</p>
              </button>
            </div>
            <Link className="dark-button-with-text" to={`/materials/${id}/adjustments`}>See all adjustments</Link>
          </div>
        </div>
      }
    </MainLayout>
  )
}
