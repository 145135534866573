import * as React from "react"
import { useEffect, useState } from "react";
import { UserErrors, UserInput } from "../../domain_data/user";
import { DefaultParamProps } from "../../utils/DefaultProp"
import { useHistory } from "react-router-dom"
import { API, IDgenerator } from "../../utils";
import { AxiosError, AxiosResponse } from "axios";
import { ItemNotFound } from "../ItemNotFound";
import { SettingLayout } from "../../layouts/Setting";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { UserForm } from "./Form";
import iziToast from "izitoast";

export const UserEdit: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<UserErrors>({});
  const [user, setUser] = useState<UserInput>();
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    API.get(`/api/profile/${id}`).then(({ data }) => {
      setUser(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const onSubmit = (data: UserInput) => {
    setSubmitting(true);
    API.patch(`/api/profile/${id}`, { user: data }).then(({ data: { id } }: AxiosResponse) => {
      history.push(`/users/${id}`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setSubmitting(false)
    })
  }

  if (notFound) return <ItemNotFound />

  const formID = IDgenerator();

  return (
    <SettingLayout>
      {loading || !user ? <LoadingRoller loading /> :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/users" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <UserForm data={user} errors={errors} id={formID} onSubmit={onSubmit} />
          </div>
        </>

      }
    </SettingLayout>
  )
}
