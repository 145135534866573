export const RandomColors = [
  '#1ED35A',
  "#1D0F92",
  '#F3BECA',
  '#F3151B',
  '#2A6B9F',
  '#99f567',
  '#817399',
  '#D8E266',
  '#6927DC',
  '#13638A',
  '#43f7c8',
  '#4a88a5',
  '#ff44e6',
  '#b568cb',
  '#6717fd',
  '#558a32',
  '#17CD6D',
  '#BF3CB6',
];