import * as React from "react";
import Cleave from "cleave.js/react";
import { TextInputProps } from "./TextInput"
import { IDgenerator } from "../../utils";

export const NumberInput: React.FC<TextInputProps> = ({ error, value, setValue, label, name, placeholder, disabled, className }: TextInputProps) => {
  const id = name ? name : IDgenerator();

  return(
    <>
      <label htmlFor={id} className={`block text-sm font-medium ${className || "text-gray-700"}`}>
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <Cleave className="px-3 py-3 border focus:outline-none
                          focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          disabled={!!disabled}
          onChange={(e) => setValue(e.target.value)}
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand"
          }}
        />
      </div>
      {error && <div className="text-sm text-red-500">{error.join(", ")}</div>}
    </>
  )
}
