import iziToast from "izitoast"
import * as React from "react"
import { useEffect, useState } from "react"
import { NumberInput } from "../../components/inputs/NumberInput"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { Currency } from "../../domain_data/currency"
import { Material } from "../../domain_data/material"
import { RecipeInput } from "../../domain_data/recipe"
import { Supplier } from "../../domain_data/supplier"
import { moneyFormatter, normalizeNumber } from "../../utils"
import API from "../../utils/api"
import { Link } from "react-router-dom"

export interface RecipeFormItemProps {
  recipe: RecipeInput,
  onDelete?: (cost: number) => void
  onChange?: (cost: number) => void,
  currency: Currency
}

export interface RecipeCost {
  identifier: string,
  cost: number
}

export const RecipeFormItem: React.FC<RecipeFormItemProps> = ({ recipe, onDelete, onChange, currency }: RecipeFormItemProps) => {
  const [materialId, serMaterialId] = useState<number>(recipe.materialId)
  const [quantity, setQuantity] = useState<string>(recipe.quantity.toString())
  const [materialCost, setMaterialCost] = useState<number>(0)
  const [isDeleted, setIsdeleted] = useState<boolean>(recipe._destroy)
  const [totalCost, setTotalCost] = useState<number>(0)
  const [unit, setUnit] = useState<string>(null)
  const [material, setMaterial] = useState<Material>(null);
  const [supplier, setSupplier] = useState<Supplier>(null)

  useEffect(() => {
    recipe.quantity = normalizeNumber(quantity)
    const costNow = (recipe.quantity || 0) * materialCost

    if (totalCost == 0) {
      onChange && onChange(costNow)
      setTotalCost(costNow)
    } else {
      onChange && onChange(costNow - totalCost)
      setTotalCost(costNow)
    }
  }, [quantity])

  useEffect(() => {
    if (!material || !material?.supplierId) {
      setSupplier(null)
      return
    }

    API.get(`/api/suppliers/${material.supplierId}`).then(({ data }) => {
      setSupplier(data)
    })

  }, [material])

  useEffect(() => {
    if (!materialId) {return}

    API.get(`/api/materials/${materialId}`).then(({ data }) => {
      setMaterial(data)

      const { totalCost: price, unit } = data

      setUnit(unit)
      setMaterialCost(price)
      recipe.materialId = materialId

      const costNow = (recipe.quantity || 0) * price

      if (totalCost == 0) {
        onChange && onChange(costNow)
        setTotalCost(costNow)
      } else {
        onChange && onChange(costNow - totalCost)
        setTotalCost(costNow)
      }

    }).catch(() => {
      iziToast.error({title: "Error", message: "Failed to fetch material"})
    })

  }, [materialId])

  const handleDelete = () => {
    recipe._destroy = true
    setIsdeleted(true)
    onDelete && onDelete(-totalCost)
  }

  if (isDeleted) return null

  return (
    <div className="col-span-6 grid grid-cols-6 gap-6">
      <div className="col-span-3 xl:col-span-2">
        <SingleAjaxSelect
          label="Material"
          required={true}
          defaultValue={materialId}
          name={`material_${materialId}`}
          url="/api/materials"
          serializeItem={(item: Material) => ({ label: `${item?.code} | ${item.name}${item.status == 'discontinued' ? ' (DISCONTINUED)' : ''}`, ...item })}
          onListItemClicked={item => serMaterialId(item?.id)}
          placeholder="Select material"
        />
      </div>
      <div className="col-span-3 xl:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Supplier</label>
        <div className="mt-1 flex rounded-md">
          {!supplier ?
            <p className="sm:text-sm">None</p>
            :
            <Link className="hover:underline" to={`/suppliers/${supplier?.id}`}>{supplier?.companyName}</Link>
          }
        </div>
      </div>

      <div className="col-span-3 xl:col-span-2">
        <label className="block text-sm font-medium text-gray-700">{`Cost/${unit ? unit : "unit"}`}</label>
        <div className="mt-1 flex rounded-md">
          <p className="sm:text-sm">{moneyFormatter(materialCost, currency?.code)}</p>
        </div>
      </div>
      <div className="col-span-3 xl:col-span-2">
        <NumberInput label={`QTY ${unit ? `${unit}(s)` : "Unit(s)"}`} value={quantity} setValue={setQuantity}/>
      </div>
      <div className="col-span-3 xl:col-span-2">
        <label className="block text-sm font-medium text-gray-700">Cost</label>
        <div className="mt-1 flex rounded-md">
          <p className="sm:text-sm">{moneyFormatter((materialCost * normalizeNumber(quantity)), currency?.code)}</p>
        </div>
      </div>
      <div className="col-span-2 self-center">
        <button type="button" className="bg-red-500 rounded-md text-white p-3" onClick={handleDelete}>
          <i className="uil uil-trash"></i>
        </button>
      </div>
      <div className="block col-span-6">
        <div className="border-t border-gray-200" />
      </div>
    </div>
  )
}
