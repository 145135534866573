import iziToast from "izitoast"
import * as React from "react"
import { useEffect, useState } from "react"
import { Link, RouteComponentProps, useHistory } from "react-router-dom"
import { LoadingRoller } from "../../components"
import { BackButton } from "../../components/BackButton"
import { FormBlock } from "../../components/forms/FormBlock"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { TextInputShow } from "../../components/inputs/TextInputShow"
import { Modal } from "../../components/Modal"
import { MaterialCategory } from "../../domain_data/material_category"
import { MainLayout } from "../../layouts/Main"
import API from "../../utils/api"
import { ItemNotFound } from "../ItemNotFound"

export type MaterialCategoryShowProps = RouteComponentProps<{ id: string }>;

export const MaterialCategoryShow: React.FC = ({match: { params: {id}}}: MaterialCategoryShowProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [category, setCategory] = useState<MaterialCategory>()
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false)
  const history = useHistory();
  const [notFound, setNotFound] = React.useState<boolean>(false)


  useEffect(() => {
    setLoading(true)
    API.get(`/api/material_categories/${id}`).then(({ data }) => {
      setCategory(data)
    }).catch(() => {
      setNotFound(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [id])

  const handleDelete = () => {
    setDeleting(true)
    API.delete(`/api/material_categories/${id}`).then(() => {
      history.push("/material_categories")
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to Delete this category" })
    }).finally(() => {
      setDeleting(false)
    })
  }

  if (notFound) return <ItemNotFound />

  return(
    <MainLayout>
      { loading || !category || isDeleting ? <LoadingRoller loading />
        :
        <div className="min-h-screen">
          <div className="flex justify-between  py-5 items-center">
            <BackButton />
            <Link className="dark-button-with-text" to={`/material_categories/${id}/edit`}>Edit</Link>
          </div>
          <div>
            <FormBlock heading="Category Name" description="This is the name of the raw material category.">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 md:col-span-2">
                  <TextInputShow label="Raw Material Category Name" value={category.name} />
                </div>
              </div>
            </FormBlock>
            <FormUnderline />
            <Modal
              onDeclined={() => setModalOpen(false)}
              onAccepted={handleDelete}
              label="We can't undo this process, Are you sure want to delete this category?"
              isOpen={isModalOpen}
              setIsOpen={setModalOpen}
            />

            <button className="bg-red-500 px-3 py-2 flex rounded mt-5" onClick={() => setModalOpen(true)}>
              <div className="w-5 inline-block">
                <i className="uil uil-trash text-white"></i>
              </div>
              <p className="text-white font-bold ml-2">Delete</p>
            </button>
          </div>
        </div>
      }
    </MainLayout>
  )
}
