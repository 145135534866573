import iziToast from "izitoast"
import * as React from "react"
import { useEffect, useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { FormUnderline } from "../../components/forms/FormUnderline"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { TextInput } from "../../components/inputs/TextInput"
import { JobOrderGroup } from "../../domain_data/job_order_group"
import { ProductionReportErrors, ProductionReportInput, ProductionReportItemInput } from "../../domain_data/production_report"
import { ProductPurchaseOrderSummary } from "../../domain_data/product_purchase_order_item"
import { DefaultFormProps } from "../../utils"
import API from "../../utils/api"

export const ProductionReportForm: React.FC<DefaultFormProps<ProductionReportInput, ProductionReportErrors>> = ({ data, errors, id, onSubmit }: DefaultFormProps<ProductionReportInput, ProductionReportErrors>) => {
  const [reportNumber, setReportNumber] = useState<string>(data.reportNumber || "")
  const [productionReportItemsAttributes, setProductionReportItemsAttributes] = useState<ProductionReportItemInput[]>(data.productionReportItemsAttributes || [])

  return(
    <form id={id}

      onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit({
          reportNumber,
          productionReportItemsAttributes
        })
      }}
    >
      <FormBlock heading="Report Information" description="This is the general report information.">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Report Number"
              value={reportNumber}
              setValue={setReportNumber}
              error={errors?.reportNumber}
              name="reportNumber"
            />
          </div>
        </div>
      </FormBlock>

      <FormUnderline />

      <FormBlock heading="Job Orders List" description="This is the list of job order that will be reported .">
        <div className="grid grid-cols-6 gap-6">
          {productionReportItemsAttributes.map((item, key) => <ReportItem key={key} item={item} />) }
        </div>
        <button onClick={() => { setProductionReportItemsAttributes(current => current.concat({
          jobOrderGroupId: null,
          productionReportId: null,
          _destroy: false,
        }))}} type="button" className="dark-button-with-text">Add</button>
      </FormBlock>
    </form>
  )
}

interface ReportItemProps {
  item: ProductionReportItemInput
}

const ReportItem: React.FC<ReportItemProps> = ({item}: ReportItemProps) => {
  const [jobOrderId, setJobOrderId] = useState<number>(item.jobOrderGroupId)
  const [jobOrder, setJobOrder] = useState<JobOrderGroup>(null)

  return(
    <div className="col-span-6 grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3 relative">
        <SingleAjaxSelect
          label="Job Order"
          defaultValue={jobOrderId}
          name="job order"
          url="/api/job_order_groups"
          serializeItem={(jo: JobOrderGroup) => ({ label: jo.name, ...jo })}
          onListItemClicked={jo => {
            setJobOrderId(jo?.id)
            item.jobOrderGroupId = jo?.id
            setJobOrder(jo)
            item._destroy = false
          }}
          onLoad={jo => {
            setJobOrderId(jo?.id)
            item.jobOrderGroupId = jo?.id
            setJobOrder(jo)
            item._destroy = false
          }}
          placeholder="Select Job Order (type order number)"
        />

        { jobOrder &&
          <button className="absolute right-1 top-8" type="button" onClick={() => {
            setJobOrderId(null)
            setJobOrder(null)
            item._destroy = true
          }}>
            <i className="uil uil-times text-2xl"></i>
          </button>

        }
      </div>
      { jobOrder &&
        <div className="col-span-6 grid grid-cols-6 gap-6">
          <OrderProgressSummary jobOrderId={jobOrder.id} />
        </div>
      }
    </div>
  )
}

export interface OrderProgressSummaryProps {
  jobOrderId: number;
}

export const OrderProgressSummary: React.FC<OrderProgressSummaryProps> = ({ jobOrderId }: OrderProgressSummaryProps) => {
  const [summary, setSummary] = useState<ProductPurchaseOrderSummary>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    API.get(`/api/job_order_groups/${jobOrderId}/progress_summary`).then(({ data }) => {
      setSummary(data)
    }).catch(() => {
      iziToast.error({ title: "Error", message: "Failed to load summary"})
    }).finally(() => {
      setIsLoading(false)
    })
  }, [jobOrderId])

  return (
    <div className="col-span-6">
      {isLoading && !summary ? <div>Loading...</div>
        :
        <div className="ml-5">
          <h2 className="text-xl font-bold">Order Summary</h2>
          <hr />
          <div className="ml-5">
            <p>Order Total: {summary?.total}</p>
            <p>Arrived: {summary?.arrivingIn} ({(summary?.arrivingIn / summary?.total * 100).toFixed(2)}%)</p>
            <p>In Progress: {(summary?.total - summary?.arrivingIn)} ({((summary?.total - summary?.arrivingIn) / summary?.total * 100).toFixed(2) }%)</p>
            <hr className="my-10"/>
            <p>In Service: {summary?.servicingIn - summary?.servicingOut} ({((summary?.servicingIn - summary?.servicingOut) / summary?.total * 100).toFixed(2) }%)</p>
            <p>In Plating: {summary?.platingIn - summary?.platingOut} ({((summary?.platingIn - summary?.platingOut) / summary?.total * 100).toFixed(2) }%)</p>
            <p>In QC House: {summary?.qcHouseIn - summary?.qcHouseOut} ({((summary?.qcHouseIn - summary?.qcHouseOut) / summary?.total * 100).toFixed(2) }%)</p>
            <p>In Stock: {summary?.inStockIn - summary?.inStockOut} ({((summary?.inStockIn - summary?.inStockOut) / summary?.total * 100).toFixed(2) }%)</p>
          </div>
          <hr />
        </div>
      }
    </div>
  )
}
