import * as React from "react"
import { useEffect, useState } from "react"
import { FormBlock } from "../../components/forms/FormBlock"
import { NumberInput } from "../../components/inputs/NumberInput"
import { ProductPOReturnErrors, ProductPOReturnInput, ProductPOReturnOperation, ProductPOReturnStage } from "../../domain_data/product_po_return"
import DatePicker from "react-datepicker"
import { Textarea } from "../../components/inputs/Textarea"
import { SingleSelect } from "../../components/inputs/SingleSelect"
import { API } from "../../utils"
import iziToast from "izitoast"
import { SingleAjaxSelect } from "../../components/inputs/SingleAjaxSelect"
import { Supplier } from "../../domain_data/supplier"



export interface DefaultFormProps<T, I> {
  id: string
  onSubmit?: (data: T) => void
  errors?: I,
  data: T,
  noWeight: boolean
}

export const ProductPORetunForm:
  React.FC<DefaultFormProps<ProductPOReturnInput, ProductPOReturnErrors>> =
  ({ data, errors, id, onSubmit, noWeight }: DefaultFormProps<ProductPOReturnInput, ProductPOReturnErrors>) => {

    const [weight, setWeight] = useState<string>(data.weight)
    const [dateIn, setDateIn] = useState<Date>(data.dateIn)
    const [quantity, setQuantity] = useState<string>(data.quantity)
    const [note, setNote] = useState<string>(data.note)
    const [operation, setOperation] = useState<ProductPOReturnOperation>(data.operation)
    const [supplierId, setSupplierId] = useState<number>(data.supplierId)

    useEffect(() => {
      if (!supplierId) return;
      API.get(`/api/suppliers/${supplierId}`).then(({ data }) => {
        setSupplierId(data.id)
      }).catch(() => {
        iziToast.error({ title: "Error", message: "failed to get supplier" })
      })
    }, [])

    return (
      <form id={id} onSubmit={e => {
        e.preventDefault();
        onSubmit && onSubmit({
          id: data.id,
          weight: weight || null,
          dateIn,
          quantity,
          note,
          operation,
          stage: data.stage || ProductPOReturnStage.Arriving,
          supplierId
        })
      }}>
        <FormBlock heading="Return Information" description="This is the general information of PO return from the smith.">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <SingleAjaxSelect
                label="Supplier"
                defaultValue={supplierId}
                name="supplier"
                url="/api/suppliers"
                serializeItem={(item: Supplier) => ({ label: item.companyName, ...item })}
                onListItemClicked={item => setSupplierId(item?.id)}
                placeholder="Select Supplier"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <NumberInput label="QTY" value={quantity} setValue={setQuantity} error={errors?.quantity} />
            </div>
            {!noWeight &&
              <div className="col-span-6 sm:col-span-3">
                <NumberInput label="Weight" value={weight} setValue={setWeight} error={errors?.weight} />
              </div>
            }
            <div className="col-span-6 sm:col-span-3">
              <SingleSelect
                label="Operation"
                defaultItem={{ label: operation, value: operation, selected: true }}
                setValue={(item) => {
                  if (item == "in") {
                    setOperation(ProductPOReturnOperation.In)
                  } else {
                    setOperation(ProductPOReturnOperation.Out)
                  }
                }}
                options={[
                  { label: "In", value: "in", selected: true },
                  { label: "Out", value: "out", selected: false }
                ]}
                error={errors?.operation}
                name="operation"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor={id} className="block text-sm font-medium text-gray-700 ">
                Operation Date
              </label>
              <DatePicker
                dateFormat="dd MMM yyyy"
                selected={dateIn}
                onChange={date => setDateIn(date)}
                className="px-3 py-3 border focus:outline-none
                              focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              />
            </div>
            <div className="col-span-6">
              <Textarea label="Note" value={note} setValue={setNote} error={errors?.note} />
            </div>
          </div>
        </FormBlock>
      </form>
    )
  }
