import * as React from "react"
import { useEffect, useState } from "react";
import { LoadingRoller } from "../../components";
import { BackButton } from "../../components/BackButton";
import { JobOrderGroup, JobOrderGroupErrors } from "../../domain_data/job_order_group";
import { MainLayout } from "../../layouts/Main";
import { IDgenerator } from "../../utils";
import API from "../../utils/api";
import { DefaultParamProps } from "../../utils/DefaultProp"
import { ItemNotFound } from "../ItemNotFound";
import { JobOrderGroupForm } from "./Form";
import { useHistory } from "react-router-dom"
import { AxiosError } from "axios";
import iziToast from "izitoast";

export const EditJobOrderGroup: React.FC<DefaultParamProps> = ({ match: { params: { id } }}: DefaultParamProps) => {
  const formID = IDgenerator();
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<JobOrderGroupErrors>({});
  const [jobOrderGroup, setJobOrderGroup] = useState<JobOrderGroup>(null)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const history = useHistory();


  useEffect(() => {
    setLoading(true)
    API.get(`/api/job_order_groups/${id}`).then(({ data  }) => {
      setJobOrderGroup(data)
    })
    .catch(() => {
      setNotFound(true)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [id])

  if (notFound) return <ItemNotFound />


  const onSubmit = (data) => {
    setSubmitting(true)
    API.patch(`/api/job_order_groups/${id}`, { jobOrderGroup: data }).then(() => {
      history.push(`/job_order_groups`)
    }).catch(({ response: { data } }: AxiosError) => {
      setErrors(data)
      iziToast.error({ title: "Invalid Input", message: "Some Fields are invalid, please recheck the form!" })
    }).finally(() => {
      setSubmitting(false)
      setLoading(false)
    })
  }

  return(
    <MainLayout>
      {loading || !jobOrderGroup ? <LoadingRoller loading /> :
        <>
          <LoadingRoller loading={submitting} />
          <div className="flex justify-between py-5 items-center">
            <BackButton path="/job_order_groups" />
            <button type="submit" form={formID} className="dark-button-with-text">Save</button>
          </div>

          <div className="min-h-screen">
            <JobOrderGroupForm data={{ name: jobOrderGroup?.name }} id={formID} errors={errors} onSubmit={onSubmit} />
          </div>
        </>

      }
    </MainLayout>
  )
}
