import { AxiosResponse } from "axios";
import iziToast from "izitoast";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ItemPerPage } from "../../components/ItemPerPage";
import { PaginationView } from "../../components/Pagination";
import { SearchComponent } from "../../components/SeachComponent";
import { Pagination } from "../../domain_data/pagination";
import { Supplier } from "../../domain_data/supplier";
import { MainLayout } from "../../layouts/Main";
import { API, IDgenerator } from "../../utils";
import { Modal } from "../../components/Modal";
import { LoadingRoller } from "../../components";
import { ContentLoading } from "../../components/ContentLoading";
import { truncate } from "lodash";

export const SupplierList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: 1,
    itemsCount: null,
  });

  const [itemPerPage, setItemPerPage] = useState<number>(20)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [page, setPage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [needToReload, setNeedToReload] = useState<string>(IDgenerator())
  const [massLoading, setMassLoading] = useState<boolean>(false)

  const history = useHistory();

  useEffect(() => {
    setLoading(true)
    API.get(`/api/suppliers?page=${page}&items=${itemPerPage}`).then(({data: { entries, pagination }}: AxiosResponse) => {
      setSuppliers(entries)
      setPagination(pagination)
      if (pagination.itemsCount == null) {
        iziToast.info({
          title: "List Overflowing",
          message: "Resetting list"
        })
        setPage(1)
      }
    }).catch(() => {
      iziToast.error({
        title: "Server Error",
        message: "There is a problem processiong your request"
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [page, itemPerPage, needToReload])

  useEffect(() => {
    setSelectedIds([])
  }, [page, suppliers, itemPerPage])


  const deleteSuppliers = () => {
    setMassLoading(true)
    API.delete("/api/suppliers/mass_destroy", {params: {ids: selectedIds}}).then(() => {
      iziToast.success({ title: "Sucess", message: "Suppliers deleted successfully"})
      setNeedToReload(IDgenerator())
    }).catch(({ response: { data } } )=> {
      iziToast.error({ title: "Failed to delete", message: data.message })
    }).finally(() => {
      setMassLoading(false)
    })
  }

  return(
    <MainLayout>
      <LoadingRoller loading={massLoading} />
      <Modal
        onAccepted={deleteSuppliers}
        isOpen={isModalOpen}
        label="Are you sure want to delete these supliers?"
        onClose={() => setIsModalOpen(false)}
        setIsOpen={setIsModalOpen}
      />
      <div className="py-5 flex sm:flex-row flex-col">
        <Link to="/suppliers/new" className="dark-button-with-text max-w-max mb-5 sm:mb-0">Add New Supplier</Link>
        <div className="inline-block sm:w-2/5 w-full align-middle sm:ml-10">
          <SearchComponent
            placeholder="Type to search by name or code...."
            url="/api/suppliers"
            interceptRequest={(entries: Supplier[]) => entries.map(item => ({ label: item.companyName, ...item }))}
            listComponent={(data) => (
              <>
                <div className="text-lg">{data.label}</div>
              </>
            )}
            onListItemClicked={(data) => {
              history.push(`/suppliers/${data.id}`)
            }}
          />

        </div>
      </div>
      <div className="bg-white md:p-10 p-1.5 mt-2 rounded shadow-md">
        <Link to="/suppliers/import" className="dark-button-with-text max-w-max mb-5">Import Supplier</Link>

        <table className="min-w-max w-full table-auto" style={{ maxWidth: "100%" }}>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-lg leading-normal">
              <th className="text-center py-3 px-6">Company Name</th>
              <th className="text-center py-3 px-6">Email</th>
              <th className="text-center py-3 px-6 md:table-cell hidden">Phone Number</th>
              <th className="text-center py-3 px-6 md:table-cell hidden">Country</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-lg font-light w-full">
            {
              loading ? <tr><td className="w-full table-cell text-center" colSpan={3}><ContentLoading /></td></tr>
              :
              suppliers?.map(supplier => (
                <tr key={supplier.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-center">
                    <Link className="hover:underline text-gray-900" to={`/suppliers/${supplier.id}`}>{truncate(supplier.companyName)}</Link>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <Link className="hover:underline text-gray-900" to={`/suppliers/${supplier.id}`}>{truncate(supplier.contactEmail)}</Link>
                  </td>
                  <td className="py-3 px-6 text-center md:table-cell hidden">{truncate(supplier?.contactPhoneNumber)}</td>
                  <td className="py-3 px-6 text-center md:table-cell hidden">{truncate(supplier?.country)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <br />
        <div className="flex sm:justify-between items-center sm:flex-row flex-col ">
          <ItemPerPage current={itemPerPage} items={[10, 20, 30, 50, 100, 200, 500, 1000]} onClick={(n) => setItemPerPage(n) } />
          <br className="sm:hidden mb-5"/>
          <PaginationView loading={loading} onPageClicked={(n) => setPage(n)} onNextClicked={() => { setPage(c => c + 1) }} onPrevClicked={() => {
            setPage(c => c - 1)
          }} {...pagination}/>
        </div>
      </div>
    </MainLayout>
  )
}
